import React from 'react';

import { BookmakerForm } from '@/components';

import { useCreateBookmakerPage } from './useCreateBookmakerPage';

const CreateBookmakerPage = () => {
  const props = useCreateBookmakerPage();
  return <BookmakerForm {...props} />;
};

export default CreateBookmakerPage;
