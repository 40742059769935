import { useGetLeaguesQuery } from '@/apis/leagues';
import { useGetSportsForFilters, usePagination, useTableFilters } from '@/hooks';

import { getFilteringFields } from './constants';

export const useMainLeaguesPage = () => {
  const sports = useGetSportsForFilters();
  const filteringFields = getFilteringFields(sports);
  const [filters, onFiltersChange] = useTableFilters(filteringFields);
  const paginationParams = usePagination();
  const { page, rowsPerPage: limit } = paginationParams;
  const { data: leagues, isLoading } = useGetLeaguesQuery({ page: page + 1, limit, ...filters });

  const count = (leagues?.meta.totalPages ?? 1) * limit;

  const noData = !leagues?.items.length;

  return {
    filteringFields,
    isLoading,
    noData,
    count,
    leagues,
    onFiltersChange,
    ...paginationParams,
  };
};
