import { createSlice } from '@reduxjs/toolkit';

import type { ISportsState } from './types';

const initialState: ISportsState = {
  isDeleteSportModalOpen: false,
  selectedSportForModal: null,
};

export const playersSlice = createSlice({
  name: 'players',
  initialState,
  reducers: {
    openDeleteSportModal(state: ISportsState, { payload: team }) {
      state.isDeleteSportModalOpen = true;
      state.selectedSportForModal = team;
    },
    closeDeleteSportModal(state: ISportsState) {
      state.isDeleteSportModalOpen = false;
      state.selectedSportForModal = null;
    },
  },
});

export const { openDeleteSportModal, closeDeleteSportModal } = playersSlice.actions;

export default playersSlice.reducer;
