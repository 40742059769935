import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/dist/query/react';

import { ApiRoutes } from '@/constants';
import { setAccessTokenToHeaders } from '@/helpers';
import { envUtil } from '@/utils';

import type { IRequestParams, IResponse, ISportFormParams, ISportResponse } from '@/types';

const { api } = envUtil.getEnv();

export const sportsApi = createApi({
  reducerPath: 'sportsApi',
  baseQuery: fetchBaseQuery({
    baseUrl: api,
    prepareHeaders: (headers) => setAccessTokenToHeaders(headers),
  }),
  tagTypes: ['Sports', 'Sport'],
  endpoints: (builder) => ({
    getSportsList: builder.query<IResponse<ISportResponse>, IRequestParams>({
      query: (params) => ({
        url: ApiRoutes.Sports,
        params,
      }),
      providesTags: (result) =>
        result
          ? [
              ...result.items.map(({ id }) => ({
                type: 'Sports' as const,
                id,
              })),
              { type: 'Sports', id: 'LIST' },
            ]
          : [{ type: 'Sports', id: 'LIST' }],
      keepUnusedDataFor: 1,
    }),
    getSportById: builder.query<ISportResponse, string>({
      query: (id) => ({
        url: `${ApiRoutes.Sports}/find-one-by-id/${id}`,
      }),
      providesTags: () => ['Sport'],
    }),
    editSport: builder.mutation<ISportResponse, ISportFormParams & { id: string }>({
      query: ({ id, ...body }) => ({
        url: `${ApiRoutes.Sports}/${id}`,
        body,
        method: 'PUT',
      }),
      invalidatesTags: ['Sport'],
    }),
    createSport: builder.mutation<ISportResponse, ISportFormParams>({
      query: (body) => ({
        url: ApiRoutes.Sports,
        body,
        method: 'POST',
      }),
      invalidatesTags: ['Sport'],
    }),
    deleteSport: builder.mutation<void, number>({
      query: (id) => ({
        url: `${ApiRoutes.Sports}/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: [{ type: 'Sports', id: 'LIST' }],
    }),
    editPredictionsStats: builder.mutation<any, any>({
      query: (params) => {
        return {
        url: `${ApiRoutes.Forecasts}/bet-stats-league-type`,
        params,
        method: 'GET',
      }
      }
    }),
    getLivePredictions: builder.query({
      query: () => {
        return {
          url: `${ApiRoutes.Forecasts}/live-predictions`,
          method: 'GET',
        }
      }
    }),
    getExpertsList: builder.query({
      query: () => {
        return {
          url: `${ApiRoutes.Experts}`,
          method: 'GET',
        }
      }
    }),


  }),
});


export const {
  useGetSportsListQuery,
  useGetSportByIdQuery,
  useEditSportMutation,
  useDeleteSportMutation,
  useCreateSportMutation,
  useEditPredictionsStatsMutation,
  useGetLivePredictionsQuery,
  useGetExpertsListQuery
} = sportsApi;
