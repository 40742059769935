import { object } from 'yup';

import { text } from '@/constants';

export const bookmakerBonusFormSchema = object({
  title: text,
  link: text,
  text,
  bookmakerId: text,
  type: text,
  createdDate: text,
  endDate: text,
}).required();
