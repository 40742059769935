import { createSlice } from '@reduxjs/toolkit';

import type { ILeaguesState } from './types';

const initialState: ILeaguesState = {
  isDeleteLeagueModalOpen: false,
  selectedLeagueForModal: null,
};

export const leaguesSlice = createSlice({
  name: 'leagues',
  initialState,
  reducers: {
    openDeleteLeagueModal(state: ILeaguesState, { payload: bookmaker }) {
      state.isDeleteLeagueModalOpen = true;
      state.selectedLeagueForModal = bookmaker;
    },
    closeDeleteLeagueModal(state: ILeaguesState) {
      state.isDeleteLeagueModalOpen = false;
      state.selectedLeagueForModal = null;
    },
  },
});

export const { openDeleteLeagueModal, closeDeleteLeagueModal } = leaguesSlice.actions;

export default leaguesSlice.reducer;
