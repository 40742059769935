import { useEffect } from 'react';

import { useGetArticleSectionsQuery } from '@/apis/article-section';
import { useLazyGetLanguagesQuery } from '@/apis/languages';
import { useGetSportsListQuery } from '@/apis/sports';

import type { FieldArrayRenderProps } from 'formik';

export const useArticleForm = (withTranslation?: boolean) => {
  const { data: sports } = useGetSportsListQuery({ page: 1, limit: 100 });
  const { data: sections } = useGetArticleSectionsQuery({ page: 1, limit: 100 });
  const [getLanguages, { data: languages }] = useLazyGetLanguagesQuery();

  useEffect(() => {
    if (withTranslation && !languages) {
      getLanguages({ limit: 10, page: 1 });
    }
  }, [withTranslation]);

  const addNewTranslation = (arrayHelpers: FieldArrayRenderProps) => () => {
    arrayHelpers.push({
      title: '',
      content: '',
      language: { name: '' },
    });
  };

  const removeTranslation = (arrayHelpers: FieldArrayRenderProps, idx: number) => () => {
    arrayHelpers.remove(idx);
  };

  return { languages, sports, sections, addNewTranslation, removeTranslation };
};
