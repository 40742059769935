import { useCallback, useMemo } from 'react';
import { useParams } from 'react-router-dom';

import {
  useEditArticleSectionMutation,
  useGetArticleSectionByIdQuery,
} from '@/apis/article-section';
import { useToastMessageRequest } from '@/hooks';

import type { IArticleSectionFormParams } from '@/types';

export const useEditArticlesSectionsPage = () => {
  const params = useParams();
  const id = params.id as string;
  const { data: articleSection } = useGetArticleSectionByIdQuery(id);
  const [editArticleSection, { isLoading, ...editArticleSectionParams }] =
    useEditArticleSectionMutation();

  useToastMessageRequest({
    ...editArticleSectionParams,
  });

  const initialValues = useMemo(
    () => ({
      name: articleSection?.name ?? '',
      order: articleSection?.order ?? 0,
      translations: articleSection?.articleSectionTranslations ?? [],
    }),
    [articleSection],
  );

  const onSubmit = useCallback(
    (params: IArticleSectionFormParams) => {
      editArticleSection({ id, ...params });
    },
    [id],
  );
  return { isLoading, initialValues, onSubmit };
};
