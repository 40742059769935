import { ChangeEvent, SyntheticEvent, useCallback, useEffect, useRef, useState } from 'react';
import {useNavigate} from "react-router-dom";

import { useCreateExpertUserMutation, useGetUsersQuery } from '@/apis/users';
import {Pathes} from "@/constants";
import { useToastMessageRequest } from '@/hooks';

import type { IExpertFormParams, IUserResponse } from '@/types';

export const useCreateExpertsPage = () => {
  const firstRender = useRef(true);

  const [open, setOpen] = useState(false);
  const [searchName, setSearchName] = useState('');

  const [createExpert, { isLoading, isSuccess, ...requestParams }] = useCreateExpertUserMutation();

  const [selectedUser, setSelectedUser] = useState<IUserResponse | null>(null);

  const { data: users, isLoading: isUsersLoading } = useGetUsersQuery({
    limit: 10,
    page: 1,
    role: 'expert',
    firstName: searchName,
  });

  useToastMessageRequest({ isSuccess, ...requestParams });

  const navigate = useNavigate();

  useEffect(() => {
    if (isSuccess) {
      navigate(Pathes.Users);
    }
  }, [isSuccess]);

  useEffect(() => {
    if (users && firstRender.current) {
      setSelectedUser(users?.items?.[0]);
      firstRender.current = false;
    }
  }, [users]);

  const toggleOpen = (status: boolean) => () => {
    setOpen(status);
  };

  const onUserNameChange = (e: ChangeEvent<HTMLInputElement>) => {
    setSearchName(e.target.value);
  };

  const onSelectUser = (event: SyntheticEvent<Element, Event>, value: IUserResponse | null) => {
    setSelectedUser(value);
  };

  const isOptionEqualToValue = (option: IUserResponse, value: IUserResponse) =>
    option.id === value.id;

  const onSubmit = useCallback(
    (params: IExpertFormParams) => {
      createExpert({ id: selectedUser?.id ?? '', ...params });
    },
    [selectedUser],
  );

  return {
    open,
    users,
    selectedUser,
    searchName,
    isUsersLoading,
    isLoading,
    onSubmit,
    toggleOpen,
    onUserNameChange,
    onSelectUser,
    isOptionEqualToValue,
  };
};
