import {Button, DialogActions, MenuItem, Typography} from "@mui/material";
import {Field, Form, Formik} from 'formik';
import React from 'react';

import {SelectInput} from "@/components";
import { styles } from "@/components/forms/BookmakerForm/styles";
import { SPORTS} from "@/components/forms/CronForm/constants";
import { CronFormField } from "@/components/forms/CronForm/CronFormField";
import { useCronForm } from "@/components/forms/CronForm/useCronForm";
import { ICronFilterField } from "@/types";



type CronFormProps = {
  id?: number,
  config?: ICronFilterField[],
  withSport?: boolean,
}

const CronForm = ({ id, config = [], withSport = true }: CronFormProps) => {
  const { isLoading, generatedInitialValues, handleSubmit } = useCronForm({ id, config, withSport })

  return config?.length === 0 || generatedInitialValues ? (
    <Formik
      enableReinitialize
      initialValues={generatedInitialValues}
      onSubmit={handleSubmit}
    >
      <Form>
        {withSport && (<Field
          name="sportId"
          autoComplete="sportId"
          fullWidth
          component={SelectInput}
          placeholder="Sport"
          defaultValue={SPORTS[0].id}
        >
          {SPORTS.map(({ name, id }) => (
            <MenuItem value={id} key={name}>
              <Typography>{name}</Typography>
            </MenuItem>
          ))}
        </Field>)}
        {config.map((configItem) => (
          <CronFormField key={configItem.name} configItem={configItem} />
        ))}
        <DialogActions>
          <Button
            variant="contained"
            type="submit"
            disabled={isLoading}
            sx={styles.btn}
          >
            Execute
          </Button>
        </DialogActions>
      </Form>
    </Formik>
  ) : (<></>);
};

export default CronForm;
