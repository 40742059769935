import { createSelector } from '@reduxjs/toolkit';

import type { IArticlesState } from './types';
import type { RootState } from '@/redux/store';

export const leaguesStateSelector = (state: RootState) => state.articles;

export const selectedArticleForModalSelector = createSelector(
  leaguesStateSelector,
  (state: IArticlesState) => state.selectedArticleForModal,
);

export const isDeleteArticleModalOpenSelector = createSelector(
  leaguesStateSelector,
  (state: IArticlesState) => state.isDeleteArticleModalOpen,
);
