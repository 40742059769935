import { useNavigate } from 'react-router-dom';

import { useGetArticleSectionsQuery } from '@/apis/article-section';
import { usePagination, useTableFilters } from '@/hooks';

import { filteringFields } from './constants';

export const useMainArticlesSectionsPage = () => {
  const navigate = useNavigate();
  const paginationParams = usePagination();
  const { page, rowsPerPage: limit } = paginationParams;
  const [filters, onFiltersChange] = useTableFilters(filteringFields);

  const { data: articleSections, isLoading } = useGetArticleSectionsQuery({
    limit,
    page: page + 1,
    ...filters,
  });

  const count = (articleSections?.meta.totalPages ?? 1) * limit;

  const noData = !articleSections?.items.length;

  const goToCreatePage = () => {
    navigate('create');
  };

  return {
    isLoading,
    noData,
    count,
    articleSections,
    goToCreatePage,
    onFiltersChange,
    ...paginationParams,
  };
};
