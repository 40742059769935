import { Add } from '@mui/icons-material';
import { Button, Typography } from '@mui/material';
import React from 'react';

import { BookmakerBonusTableItem, CustomTable, DeleteBookmakerBonusModal } from '@/components';

import { headCells } from './constants';
import { useMainBookmakerBonusesPage } from './useMainBookmakerBonusesPage';

const MainBookmakerBonusesPage = () => {
  const { bonuses, goToCreatePage, ...tableProps } = useMainBookmakerBonusesPage();
  return (
    <>
      <Button variant="contained">
        <Add />
        <Typography ml={2} fontWeight="bold" onClick={goToCreatePage}>
          Create Bonus
        </Typography>
      </Button>
      <CustomTable
        headCells={headCells}
        filteringFields={[]}
        deletingModal={DeleteBookmakerBonusModal}
        {...tableProps}
      >
        {bonuses?.items.map((bonus) => (
          <BookmakerBonusTableItem {...bonus} key={bonus.id} />
        ))}
      </CustomTable>
    </>
  );
};

export default MainBookmakerBonusesPage;
