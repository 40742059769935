import { useGetPlayersListQuery } from '@/apis/players';
import { useGetSportsForFilters, usePagination, useTableFilters } from '@/hooks';

import { getFilteringFields } from './constants';

export const useMainPlayersPage = () => {
  const paginationParams = usePagination();
  const { page, rowsPerPage: limit } = paginationParams;
  const sports = useGetSportsForFilters();
  const filteringFields = getFilteringFields(sports);
  const [filters, onFiltersChange] = useTableFilters(filteringFields);
  const { data: players, isLoading } = useGetPlayersListQuery({
    limit,
    page: page + 1,
    ...filters,
  });

  const count = (players?.meta.totalPages ?? 1) * limit;

  const noData = !players?.items.length;

  return {
    filteringFields,
    noData,
    isLoading,
    count,
    limit,
    players,
    onFiltersChange,
    ...paginationParams,
  };
};
