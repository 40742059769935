import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/dist/query/react';

import { ApiRoutes } from '@/constants';
import { setAccessTokenToHeaders } from '@/helpers';
import { envUtil } from '@/utils';

import type {
  IArticleSectionFormParams,
  IArticleSectionResponse,
  IRequestParams,
  IResponse,
} from '@/types';

const { api } = envUtil.getEnv();

export const articleSectionApi = createApi({
  reducerPath: 'articleSectionApi',
  baseQuery: fetchBaseQuery({
    baseUrl: api,
    prepareHeaders: (headers) => setAccessTokenToHeaders(headers),
  }),
  tagTypes: ['ArticlesSections', 'ArticleSection'],
  endpoints: (builder) => ({
    getArticleSections: builder.query<IResponse<IArticleSectionResponse>, IRequestParams>({
      query: (params) => ({
        url: ApiRoutes.ArticleSections,
        params,
      }),
      providesTags: (result) =>
        result
          ? [
              ...result.items.map(({ id }) => ({
                type: 'ArticlesSections' as const,
                id,
              })),
              { type: 'ArticlesSections', id: 'LIST' },
            ]
          : [{ type: 'ArticlesSections', id: 'LIST' }],
      keepUnusedDataFor: 1,
    }),
    getArticleSectionById: builder.query<IArticleSectionResponse, string>({
      query: (id) => ({
        url: `${ApiRoutes.ArticleSections}/${id}`,
      }),
      providesTags: () => ['ArticleSection'],
    }),
    createArticleSection: builder.mutation<IArticleSectionResponse, IArticleSectionFormParams>({
      query: (body) => ({
        url: ApiRoutes.ArticleSections,
        body,
        method: 'POST',
      }),
    }),
    editArticleSection: builder.mutation<
      IArticleSectionResponse,
      IArticleSectionFormParams & { id: string }
    >({
      query: ({ id, ...body }) => ({
        url: `${ApiRoutes.ArticleSections}/${id}`,
        body,
        method: 'PUT',
      }),
      invalidatesTags: ['ArticleSection'],
    }),
    deleteArticleSection: builder.mutation<void, number>({
      query: (id) => ({
        url: `${ApiRoutes.ArticleSections}/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: [{ type: 'ArticlesSections', id: 'LIST' }],
    }),
  }),
});

export const {
  useGetArticleSectionsQuery,
  useGetArticleSectionByIdQuery,
  useEditArticleSectionMutation,
  useCreateArticleSectionMutation,
  useDeleteArticleSectionMutation,
} = articleSectionApi;
