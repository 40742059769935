import {useCallback, useEffect} from 'react';
import {useNavigate} from "react-router-dom";

import { useCreateSportMutation } from '@/apis/sports';
import {Pathes} from "@/constants";
import { useToastMessageRequest } from '@/hooks';

import type { ISportFormParams } from '@/types';

export const useCreateSportPage = () => {
  const [createSport, { isLoading, isSuccess, ...requestParams }] = useCreateSportMutation();

  useToastMessageRequest({ isSuccess, ...requestParams });

  const navigate = useNavigate();

  useEffect(() => {
    if (isSuccess) {
      navigate(Pathes.Sports);
    }
  }, [isSuccess]);

  const onSubmit = useCallback((params: ISportFormParams) => {
    createSport(params);
  }, []);

  return { isLoading, onSubmit };
};
