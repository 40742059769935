import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/dist/query/react';

import { ApiRoutes } from '@/constants';
import { setAccessTokenToHeaders } from '@/helpers';
import { envUtil } from '@/utils';

import type { ISectionResponse, IChallengeResponse, IRequestParams, IResponse } from '@/types';

const { api } = envUtil.getEnv();

export const managersApi = createApi({
  reducerPath: 'managersApi',
  baseQuery: fetchBaseQuery({
    baseUrl: api,
    prepareHeaders: (headers) => setAccessTokenToHeaders(headers),
  }),
  endpoints: (builder) => ({
    getManagers: builder.query<IResponse<ISectionResponse>, IRequestParams>({
      query: (params) => ({
        url: ApiRoutes.Managers,
        params,
      }),
    }),
    getManagerById: builder.query<IChallengeResponse, string>({
      query: (id) => ({
        url: `${ApiRoutes.Managers}/${id}`,
      }),
    }),
  }),
});

export const { useGetManagersQuery, useGetManagerByIdQuery } = managersApi;
