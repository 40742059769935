import { Button, DialogActions, Grid, MenuItem, Typography } from '@mui/material';
import { Field, Form, Formik } from 'formik';
import React from 'react';

import { useGetBookmakersListQuery } from '@/apis/bookmakers';
import { SelectInput, TextInput } from '@/components';

import { defaultInitialValues } from './constants';
import { bookmakerReviewFormSchema } from './schemas';
import { styles } from './styles';

import type { IBookmakerReviewFormParams, IFormProps } from '@/types';

const BookmakerReviewForm: React.FC<IFormProps<IBookmakerReviewFormParams>> = ({
  initialValues = defaultInitialValues,
  onSubmit,
  isLoading,
}) => {
  const { data: bookmakers } = useGetBookmakersListQuery({ page: 1, limit: 10 });

  return (
    <Formik
      validationSchema={bookmakerReviewFormSchema}
      enableReinitialize
      initialValues={initialValues}
      onSubmit={onSubmit}
    >
      {({ dirty }) => (
        <Form>
          <Grid container spacing={2} sx={styles.container}>
            <Grid item xs>
              <Field
                type="text"
                name="text"
                autoComplete="text"
                fullWidth
                component={TextInput}
                placeholder="Text"
              />
            </Grid>
            <Grid item xs>
              <Field
                name="bookmakerId"
                autoComplete="bookmakerId"
                fullWidth
                component={SelectInput}
                placeholder="Bookmaker"
              >
                <MenuItem value="" disabled>
                  <Typography>Bookmaker</Typography>
                </MenuItem>
                {bookmakers?.items.map(({ name, id }) => (
                  <MenuItem value={id} key={name}>
                    <Typography>{name}</Typography>
                  </MenuItem>
                ))}
              </Field>
            </Grid>
          </Grid>
          <DialogActions>
            <Button
              variant="contained"
              type="submit"
              disabled={isLoading || !dirty}
              sx={styles.btn}
            >
              Save
            </Button>
          </DialogActions>
        </Form>
      )}
    </Formik>
  );
};

export default BookmakerReviewForm;
