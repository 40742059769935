import { Close, PlusOne } from '@mui/icons-material';
import { Button, TextField, Typography, Box, IconButton, Card, Grid } from '@mui/material';
import React from 'react';

import { styles } from './styles';
import { useApiIntegrationForm } from './useApiIntegrationForm';

import type { IApiIntegrationFormProps } from './types';

const ApiIntegrationForm: React.FC<IApiIntegrationFormProps> = (props) => {
  const { name, integrations, removeIntegration, addNewIntegration, onNameChange } =
    useApiIntegrationForm(props);

  return (
    <Box sx={styles.container}>
      <Typography variant="h5">Api integration</Typography>
      <Box sx={styles.form}>
        <TextField
          value={name}
          size="small"
          placeholder="Integration name"
          onChange={onNameChange}
          sx={styles.textField}
        />
        <Button variant="contained" onClick={addNewIntegration} size="small">
          <PlusOne />
        </Button>
      </Box>
      <Box sx={styles.integration}>
        <Grid container spacing={1}>
          {integrations?.map(({ name, id }) => (
            <Grid item md={6} xs={12} key={id}>
              <Card sx={styles.integration}>
                <Typography variant="h5">{name}</Typography>
                <IconButton onClick={removeIntegration(id)}>
                  <Close />
                </IconButton>
              </Card>
            </Grid>
          ))}
        </Grid>
      </Box>
    </Box>
  );
};

export default ApiIntegrationForm;
