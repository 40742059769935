import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { useDeleteBookmakerMutation } from '@/apis/bookmakers';
import { useToastMessageRequest } from '@/hooks';
import { bookmakersSelectors } from '@/redux/bookmakers';
import { closeDeleteBookmakerModal } from '@/redux/bookmakers/slice';

export const useDeleteBookmakerModal = () => {
  const dispatch = useDispatch();

  const isOpen = useSelector(bookmakersSelectors.isDeleteBookmakerModalOpenSelector);
  const bookmaker = useSelector(bookmakersSelectors.selectedBookmakerForModalSelector);

  const [deleteBookmaker, { isSuccess, ...requestParams }] = useDeleteBookmakerMutation();
  useToastMessageRequest({ isSuccess, ...requestParams });

  const closeModal = () => {
    dispatch(closeDeleteBookmakerModal());
  };

  const confirmDeleting = () => {
    deleteBookmaker(bookmaker?.id);
  };

  useEffect(() => {
    if (isSuccess) {
      closeModal();
    }
  }, [isSuccess]);

  return { isOpen, closeModal, confirmDeleting, bookmaker };
};
