import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { useDeleteArticleSectionMutation } from '@/apis/article-section';
import { useToastMessageRequest } from '@/hooks';
import { articlesSectionsStateSelectors } from '@/redux/articles-sections';
import { closeDeleteArticleSectionModal } from '@/redux/articles-sections/slice';

export const useDeleteArticleSectionModal = () => {
  const dispatch = useDispatch();
  const isOpen = useSelector(
    articlesSectionsStateSelectors.isDeleteArticleSectionModalOpenSelector,
  );
  const articleSection = useSelector(
    articlesSectionsStateSelectors.selectedArticleSectionForModalSelector,
  );
  const [deleteArticleSection, { isSuccess, ...requestParams }] = useDeleteArticleSectionMutation();

  useToastMessageRequest({ isSuccess, ...requestParams });

  const closeModal = () => {
    dispatch(closeDeleteArticleSectionModal());
  };

  const confirmDeleting = () => {
    deleteArticleSection(articleSection?.id ?? 0);
  };

  useEffect(() => {
    if (isSuccess) {
      closeModal();
    }
  }, [isSuccess]);

  return { isOpen, articleSection, closeModal, confirmDeleting };
};
