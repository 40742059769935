import { useState } from 'react';

import {
  useCreateIntegrationMutation,
  useDeleteIntegrationMutation,
  useGetAdminIntegrationByIdQuery,
} from '@/apis/integrations';
import { useToastMessageRequest } from '@/hooks';

import type { IApiIntegrationFormProps } from './types';
import type { ChangeEvent } from 'react';

export const useApiIntegrationForm = ({
  entityType,
  id: relationRecordId,
}: IApiIntegrationFormProps) => {
  const [name, setName] = useState('');
  const { data: integrations } = useGetAdminIntegrationByIdQuery(
    {
      relationRecordId,
      entityType,
    },
    { skip: !relationRecordId },
  );

  const [createIntegration, createIntegrationRequest] = useCreateIntegrationMutation();
  const [deleteIntegration, deleteIntegrationRequest] = useDeleteIntegrationMutation();

  useToastMessageRequest(createIntegrationRequest);
  useToastMessageRequest(deleteIntegrationRequest);

  const removeIntegration = (id: number) => () => {
    deleteIntegration({ entityType, id });
  };

  const addNewIntegration = () => {
    createIntegration({ entityType, relationRecordId, name });
    setName('');
  };

  const onNameChange = (e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    setName(e.target.value);
  };

  return { name, integrations, removeIntegration, addNewIntegration, onNameChange };
};
