import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/dist/query/react';

import { ApiRoutes } from '@/constants';
import { setAccessTokenToHeaders } from '@/helpers';
import { ISeasonResponse } from '@/types';
import { envUtil } from '@/utils';

import type { IRequestParams, IResponse } from '@/types';
const { api } = envUtil.getEnv();

export const seasonsApi = createApi({
  reducerPath: 'seasonsApi',
  baseQuery: fetchBaseQuery({
    baseUrl: api,
    prepareHeaders: (headers) => setAccessTokenToHeaders(headers),
  }),
  endpoints: (builder) => ({
    getSeasons: builder.query<IResponse<ISeasonResponse>, IRequestParams>({
      query: (params) => ({
        url: ApiRoutes.Seasons,
        params,
      }),
    }),
    getSeasonById: builder.query<ISeasonResponse, string>({
      query: (id) => ({
        url: `${ApiRoutes.Seasons}/${id}`,
      }),
    }),
  }),
});

export const { useGetSeasonsQuery, useGetSeasonByIdQuery } = seasonsApi;
