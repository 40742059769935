export const userRoles = ['user', 'expert', 'admin'];

export const defaultInitialValue = {
  password: '',
  firstName: '',
  lastName: '',
  email: '',
  login: '',
  role: 'user',
  rating: 0,
  disableNotification: false,
  confirmed: false,
  avatar: '',
  bannedTill: '',
  isUserBanned: false,
  isIpBanned: false,
};
