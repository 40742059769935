import { ChangeEvent, useState } from 'react';

import { useGetEventsQuery } from '@/apis/events';
import { useGetSportsForFilters, usePagination, useTableFilters } from '@/hooks';

import { getFilteringFields } from './constants';

export const useMainEventsPage = () => {
  const [isLeagueFsSyncOn, setIsLeagueFsSyncOn] = useState(false);
  const [isShowSyncFails, setIsShowSyncFails] = useState(false);

  const paginationParams = usePagination();
  const { page, rowsPerPage: limit } = paginationParams;
  const sports = useGetSportsForFilters();
  const filteringFields = getFilteringFields(sports);

  const [filters, onFiltersChange] = useTableFilters(filteringFields);

  const { data: events, isLoading } = useGetEventsQuery({
    limit,
    page: page + 1,
    isLeagueFsSyncOn,
    isShowSyncFails,
    ...filters,
  });

  const count = (events?.meta.totalPages ?? 1) * limit;
  const noData = !events?.items.length;

  const onToggleIsLeagueFsSyncOn = (e: ChangeEvent<HTMLInputElement>) => {
    setIsLeagueFsSyncOn(e.target.checked);
  };

  const onToggleIsShowSyncFails = (e: ChangeEvent<HTMLInputElement>) => {
    setIsShowSyncFails(e.target.checked);
  };

  return {
    isShowSyncFails,
    isLeagueFsSyncOn,
    filteringFields,
    isLoading,
    noData,
    count,
    events,
    onFiltersChange,
    onToggleIsLeagueFsSyncOn,
    onToggleIsShowSyncFails,
    ...paginationParams,
  };
};
