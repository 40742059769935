import { array, object } from 'yup';
import '@/constants/yupSettings';

import { number, text } from '@/constants';

import type { ITranslationsArticleResponse } from '@/types';

export const articleFormSchema = object({
  title: text,
  sportId: number,
  articleSectionId: number,
  content: text,
  translations: array()
    .of(
      object().shape({
        title: text,
        content: text,
        language: object().shape({ name: text }),
      }),
    )
    .unique<ITranslationsArticleResponse>(
      (s) => s.language.name,
      'language must be uniq in each section',
    ),
}).required();
