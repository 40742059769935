import { useGetVenuesQuery } from '@/apis/venues';
import { usePagination } from '@/hooks';

export const useMainSeasonsPage = () => {
  const paginationParams = usePagination();
  const { page, rowsPerPage: limit } = paginationParams;

  const { data: venues, isLoading } = useGetVenuesQuery({ page: page + 1, limit });
  const noData = !venues?.items.length;

  const count = (venues?.meta.totalPages ?? 1) * limit;

  return { isLoading, noData, venues, count, ...paginationParams };
};
