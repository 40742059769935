import { createSelector } from '@reduxjs/toolkit';

import type { ISportsState } from './types';
import type { RootState } from '@/redux/store';

export const sportsStateSelector = (state: RootState) => state.sports;

export const selectedSportForModalSelector = createSelector(
  sportsStateSelector,
  (state: ISportsState) => state.selectedSportForModal,
);

export const isDeleteSportModalOpenSelector = createSelector(
  sportsStateSelector,
  (state: ISportsState) => state.isDeleteSportModalOpen,
);
