import { useMemo } from 'react';

import {
  useCreateWebsiteSettingsMutation,
  useGetWebsiteSettingsQuery,
  useUpdateWebsiteSettingsMutation,
} from '@/apis/website-settings';
import { useToastMessageRequest } from '@/hooks';

import type { IWebsiteSettingsFormParams } from '@/types';

export const useSiteSettingsForm = () => {
  const { data } = useGetWebsiteSettingsQuery();
  const [updateSettings, { isLoading: isUpdateLoading, ...requestUpdateParams }] =
    useUpdateWebsiteSettingsMutation();
  const [createSettings, { isLoading: isCreateLoading, ...requestCreateParams }] =
    useCreateWebsiteSettingsMutation();

  useToastMessageRequest(requestUpdateParams);
  useToastMessageRequest(requestCreateParams);

  const initialValues = useMemo(
    () => ({
      adminEmail: data?.adminEmail ?? '',
      vkontakte: data?.vkontakte ?? '',
      facebook: data?.facebook ?? '',
      twitter: data?.twitter ?? '',
      metrics: data?.metrics ?? '',
      partner: data?.partner ?? '',
      copyright: data?.copyright ?? '',
      ['404']: data?.['404'] ?? '',
      bookmakersContent: data?.bookmakersContent || '',
      freeBetsContent: data?.freeBetsContent || '',
      predictionsContent: data?.predictionsContent || '',
      supportContent: data?.supportContent || '',
      privacyPolicyContent: data?.privacyPolicyContent || '',
    }),
    [data],
  );
  const onSubmit = (params: IWebsiteSettingsFormParams) => {
    data ? updateSettings(params) : createSettings(params);
  };
  return { initialValues, isUpdateLoading, isCreateLoading, onSubmit };
};
