import React from 'react';

import { DeleteConfirmationDialog } from '@/components';

import { useDeleteBookmakerReviewModal } from './useDeleteBookmakerReviewModal';

const DeleteBookmakerReviewModal = () => {
  const { isOpen, closeModal, confirmDeleting } = useDeleteBookmakerReviewModal();
  return (
    <DeleteConfirmationDialog
      open={isOpen}
      onClose={closeModal}
      onConfirm={confirmDeleting}
      text="`Are you sure you want to delete  this bookmaker bonus-review?`"
    />
  );
};

export default DeleteBookmakerReviewModal;
