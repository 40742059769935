import { createSelector } from '@reduxjs/toolkit';

import type { ITeamsState } from './types';
import type { RootState } from '@/redux/store';

export const teamsStateSelector = (state: RootState) => state.teams;

export const selectedLeagueForModalSelector = createSelector(
  teamsStateSelector,
  (state: ITeamsState) => state.selectedTeamForModal,
);

export const isDeleteLeagueModalOpenSelector = createSelector(
  teamsStateSelector,
  (state: ITeamsState) => state.isDeleteTeamModalOpen,
);
