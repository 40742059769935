import { createSlice } from '@reduxjs/toolkit';

import type { IPlayersState } from './types';

const initialState: IPlayersState = {
  isDeletePlayerModalOpen: false,
  selectedPlayerForModal: null,
};

export const playersSlice = createSlice({
  name: 'players',
  initialState,
  reducers: {
    openDeletePlayerModal(state: IPlayersState, { payload: team }) {
      state.isDeletePlayerModalOpen = true;
      state.selectedPlayerForModal = team;
    },
    closeDeletePlayerModal(state: IPlayersState) {
      state.isDeletePlayerModalOpen = false;
      state.selectedPlayerForModal = null;
    },
  },
});

export const { openDeletePlayerModal, closeDeletePlayerModal } = playersSlice.actions;

export default playersSlice.reducer;
