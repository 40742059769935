import { Button, Grid, Typography } from '@mui/material';
import { Formik, Form, Field } from 'formik';
import React from 'react';

import { EmailInput, PasswordInput } from '@/components';

import { schema } from './schema';
import styles from './styles';
import useEmailSignInForm from './useEmailSignInForm';

const EmailSignInForm = () => {
  const { onSubmit, isLoading } = useEmailSignInForm();

  return (
    <Formik
      initialValues={{ email: '', password: '' }}
      validationSchema={schema}
      onSubmit={onSubmit}
    >
      <Form>
        <Typography variant="h3">Sign In</Typography>
        <Grid container sx={styles.container}>
          <Field type="email" name="email" autoComplete="email" fullWidth component={EmailInput} />
          <Field
            name="password"
            autoComplete="current-password"
            fullWidth
            component={PasswordInput}
          />
          <Button
            variant="contained"
            color="primary"
            fullWidth
            type="submit"
            disabled={isLoading}
            sx={styles.signInBtn}
            size="large"
          >
            Sign In
          </Button>
        </Grid>
      </Form>
    </Formik>
  );
};

export default EmailSignInForm;
