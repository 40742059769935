import {
  useEditPredictionsStatsMutation, useGetExpertsListQuery,
  useGetLivePredictionsQuery
} from "@/apis/sports";

export const useTableDataPage = () => {
  const [editPredictionsStats, { isLoading, data}] = useEditPredictionsStatsMutation();

  const {data: liveData} = useGetLivePredictionsQuery(1);
  const {data: experts} = useGetExpertsListQuery(1);

  const onSubmit = (params: any) => {
      editPredictionsStats(params);
    }

    if (isLoading) {
      return { isLoading };
    }

  return { rows: data, onSubmit, isLoading, liveData, experts };
};
