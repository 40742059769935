import React from 'react';

import { CustomTable, DeletePlayerModal, PlayerTableItem } from '@/components';

import { headCells } from './constants';
import { useMainPlayersPage } from './useMainPlayersPage';

const MainPlayersPage = () => {
  const { players, ...tableProps } = useMainPlayersPage();
  return (
    <>
      <CustomTable headCells={headCells} deletingModal={DeletePlayerModal} {...tableProps}>
        {players?.items.map((team) => (
          <PlayerTableItem {...team} key={team.id} />
        ))}
      </CustomTable>
    </>
  );
};

export default MainPlayersPage;
