import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { useDeleteTeamMutation } from '@/apis/teams';
import { useToastMessageRequest } from '@/hooks';
import { teamsStateSelectors } from '@/redux/teams';
import { closeDeleteTeamModal } from '@/redux/teams/slice';

export const useDeleteTeamModal = () => {
  const dispatch = useDispatch();

  const isOpen = useSelector(teamsStateSelectors.isDeleteLeagueModalOpenSelector);
  const team = useSelector(teamsStateSelectors.selectedLeagueForModalSelector);

  const [deleteTeam, { isSuccess, ...requestParams }] = useDeleteTeamMutation();

  useToastMessageRequest({ isSuccess, ...requestParams });

  const closeModal = () => {
    dispatch(closeDeleteTeamModal());
  };

  const confirmDeleting = () => {
    deleteTeam(team?.id ?? 0);
  };

  useEffect(() => {
    if (isSuccess) {
      closeModal();
    }
  }, [isSuccess]);

  return { isOpen, closeModal, confirmDeleting };
};
