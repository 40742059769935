import { Container, Typography } from '@mui/material';
import React from 'react';

import { ApiIntegrationForm, PlayerForm } from '@/components';
import { IntegrationEntityType } from '@/types';

import { useEditPlayersPage } from './useEditPlayersPage';

const EditPlayersPage: React.FC = () => {
  const { player, initialValues, isLoading, onSubmit } = useEditPlayersPage();
  return (
    <Container>
      <Typography variant="h4" my={2}>
        Players form
      </Typography>
      <PlayerForm initialValues={initialValues} isLoading={isLoading} onSubmit={onSubmit} />
      <ApiIntegrationForm id={player?.id} entityType={IntegrationEntityType.Player} />
    </Container>
  );
};

export default EditPlayersPage;
