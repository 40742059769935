import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/dist/query/react';

import { ApiRoutes } from '@/constants';
import { setAccessTokenToHeaders } from '@/helpers';
import { envUtil } from '@/utils';

import type { IRequestParams, IResponse, ILanguageResponse } from '@/types';
const { api } = envUtil.getEnv();

export const languagesApi = createApi({
  reducerPath: 'languagesApi',
  baseQuery: fetchBaseQuery({
    baseUrl: api,
    prepareHeaders: (headers) => setAccessTokenToHeaders(headers),
  }),
  tagTypes: ['Languages'],
  endpoints: (builder) => ({
    getLanguages: builder.query<IResponse<ILanguageResponse>, IRequestParams>({
      query: (params) => ({
        url: ApiRoutes.Languages,
        params,
      }),
      providesTags: (result) =>
        result
          ? [
              ...result.items.map(({ id }) => ({
                type: 'Languages' as const,
                id,
              })),
              { type: 'Languages', id: 'LIST' },
            ]
          : [{ type: 'Languages', id: 'LIST' }],
      keepUnusedDataFor: 1,
    }),
  }),
});

export const { useGetLanguagesQuery, useLazyGetLanguagesQuery } = languagesApi;
