import { Container, Typography } from '@mui/material';
import React from 'react';

import { ApiIntegrationForm, TeamForm } from '@/components';
import { IntegrationEntityType } from '@/types';

import { useEditTeamPage } from './useEditTeamPage';

const EditTeamPage: React.FC = () => {
  const { team, initialValues, isLoading, onSubmit } = useEditTeamPage();
  return (
    <Container>
      <Typography variant="h4" my={2}>
        Team form
      </Typography>
      <TeamForm initialValues={initialValues} isLoading={isLoading} onSubmit={onSubmit} />
      <ApiIntegrationForm id={team?.id} entityType={IntegrationEntityType.Team} />
    </Container>
  );
};

export default EditTeamPage;
