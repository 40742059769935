import { Button, Grid, Box } from '@mui/material';
import { Field, Form, Formik } from 'formik';
import React from 'react';

import { ImageInput, RichTextEditorInput, TextInput } from '@/components';

import { defaultInitialValues } from './constants';
import { styles } from './styles';

import type { IFormProps, ITeamFormParams } from '@/types';

const TeamForm = ({
  initialValues = defaultInitialValues,
  onSubmit,
  isLoading,
}: IFormProps<ITeamFormParams>) => {
  return (
    <Formik enableReinitialize initialValues={initialValues} onSubmit={onSubmit}>
      {({ dirty }) => (
        <Form>
          <Grid container spacing={2} sx={styles.container}>
            <Grid item xs>
              <Field
                type="text"
                name="name"
                autoComplete="name"
                fullWidth
                component={TextInput}
                placeholder="Name"
              />
            </Grid>
            <Grid item xs>
              <Field
                type="number"
                name="rating"
                autoComplete="rating"
                fullWidth
                component={TextInput}
                placeholder="Rating"
                InputProps={{ inputProps: { min: 0 } }}
              />
            </Grid>
          </Grid>
          <Box>
            <Field
              type="text"
              name="fsExternalId"
              autoComplete="fsExternalId"
              fullWidth
              component={TextInput}
              placeholder="Fs External Id"
            />
          </Box>
          <Box mb={2}>
            <Field
              name="description"
              autoComplete="description"
              fullWidth
              component={RichTextEditorInput}
              placeholder="Description"
            />
          </Box>
          <Grid container spacing={2} sx={styles.container}>
            <Grid item md={6} xs>
              <Field
                name="logo"
                autoComplete="logo"
                fullWidth
                component={ImageInput}
                placeholder="Logo"
              />
            </Grid>
          </Grid>
          <Box sx={styles.btnWrapper}>
            <Button
              variant="contained"
              type="submit"
              disabled={!dirty || isLoading}
              sx={styles.btn}
            >
              Save
            </Button>
          </Box>
        </Form>
      )}
    </Formik>
  );
};

export default TeamForm;
