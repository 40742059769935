import { useCallback, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { useLoginUserMutation } from '@/apis/auth';
import { UserRoles, ResponseStatus } from '@/constants';
import { useAuthContext } from '@/context';
import { useToastMessageRequest } from '@/hooks';

import type { IAuthType } from '@/types';

const useEmailSignInForm = () => {
  const auth = useAuthContext();
  const navigate = useNavigate();
  const [loginUser, { data: loginData, isLoading, ...requestParams }] = useLoginUserMutation();

  const userRole = loginData?.user?.role;

  useToastMessageRequest(requestParams, {
    condition: !!(userRole && userRole !== UserRoles.Admin),
    message: {
      text: 'Only admin can sign in',
      status: ResponseStatus.Error,
    },
  });

  const onSubmit = useCallback(
    (credentionals: IAuthType) => {
      loginUser(credentionals);
    },
    [loginUser],
  );

  useEffect(() => {
    if (!loginData) return;
    if (loginData.user.role !== UserRoles.Admin) return;

    auth.setAccessToken(loginData);
    navigate('/');
  }, [loginData, navigate]);

  return { onSubmit, isLoading };
};

export default useEmailSignInForm;
