import React from 'react';
import { useParams } from 'react-router-dom';

import { useGetManagerByIdQuery } from '@/apis/managers';
import { ApiIntegrationForm } from '@/components';
import { IntegrationEntityType } from '@/types';

const EditManagersPage = () => {
  const params = useParams();
  const id = params.id as string;
  const { data: manager } = useGetManagerByIdQuery(id);
  return <ApiIntegrationForm id={manager?.id} entityType={IntegrationEntityType.Manager} />;
};

export default EditManagersPage;
