import { createSlice } from '@reduxjs/toolkit';

import type { IBookmakerBonusesState } from './types';

const initialState: IBookmakerBonusesState = {
  isDeleteBookmakerBonusModalOpen: false,
  selectedBookmakerBonusForModal: null,
};

export const bookmakerBonusesSlice = createSlice({
  name: 'bookmakerBonuses',
  initialState,
  reducers: {
    openDeleteBookmakerBonusModal(state: IBookmakerBonusesState, { payload: bookmaker }) {
      state.isDeleteBookmakerBonusModalOpen = true;
      state.selectedBookmakerBonusForModal = bookmaker;
    },
    closeDeleteBookmakerBonusModal(state: IBookmakerBonusesState) {
      state.isDeleteBookmakerBonusModalOpen = false;
      state.selectedBookmakerBonusForModal = null;
    },
  },
});

export const { openDeleteBookmakerBonusModal, closeDeleteBookmakerBonusModal } =
  bookmakerBonusesSlice.actions;

export default bookmakerBonusesSlice.reducer;
