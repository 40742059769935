import React from 'react';

import { DeleteConfirmationDialog } from '@/components';

import { useDeleteTeamModal } from './useDeleteTeamModal';

const DeleteTeamModal = () => {
  const { isOpen, closeModal, confirmDeleting } = useDeleteTeamModal();
  return (
    <DeleteConfirmationDialog
      open={isOpen}
      onClose={closeModal}
      onConfirm={confirmDeleting}
      text={`Are you sure you want to delete team?`}
    />
  );
};

export default DeleteTeamModal;
