import { Delete, Edit } from '@mui/icons-material';
import { Box, IconButton, TableCell, TableRow } from '@mui/material';
import React from 'react';

import { useTableItem } from '@/hooks';
import { openDeletePlayerModal } from '@/redux/players/slice';

import type { IPlayerResponse } from '@/types';

const PlayerTableItem: React.FC<IPlayerResponse> = (player) => {
  const { slug, id, externalId, fsExternalId, photo, name, positionName, age, team, sport } = player;
  const { onEdit, onDelete } = useTableItem(openDeletePlayerModal);
  return (
    <TableRow>
      <TableCell component="th" scope="row">
        {id}
      </TableCell>
      <TableCell component="th" scope="row">
        {externalId}
      </TableCell>
      <TableCell component="th" scope="row">
        {fsExternalId}
      </TableCell>
      <TableCell component="th" scope="row">
        <Box component="img" src={photo as string} width={30} />
      </TableCell>
      <TableCell component="th" scope="row">
        {name}
      </TableCell>
      <TableCell component="th" scope="row">
        {sport?.name ?? ''}
      </TableCell>
      <TableCell component="th" scope="row">
        {positionName}
      </TableCell>
      <TableCell component="th" scope="row">
        {age}
      </TableCell>
      <TableCell component="th" scope="row">
        {team?.name ?? ''}
      </TableCell>
      <TableCell width={100}>
        <IconButton onClick={onEdit(slug)}>
          <Edit />
        </IconButton>
        <IconButton onClick={onDelete(player)}>
          <Delete />
        </IconButton>
      </TableCell>
    </TableRow>
  );
};

export default PlayerTableItem;
