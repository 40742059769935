import { Button, Box } from '@mui/material';
import { Field, Form, Formik } from 'formik';
import React from 'react';

import { ImageInput, TextInput } from '@/components';
import { IPlayerFormParams } from '@/types/forms/player';

import { defaultInitialValues, acceptImageType } from './constants';
import { styles } from './styles';

import type { IFormProps } from '@/types';

const PlayerForm = ({
  initialValues = defaultInitialValues,
  onSubmit,
  isLoading,
}: IFormProps<IPlayerFormParams>) => {
  return (
    <Formik enableReinitialize initialValues={initialValues} onSubmit={onSubmit}>
      {({ dirty }) => (
        <Form>
          <Box>
            <Field
              type="text"
              name="fsExternalId"
              autoComplete="fsExternalId"
              fullWidth
              component={TextInput}
              placeholder="Fs External Id"
            />
          </Box>
          <Box sx={styles.photo}>
            <Field
              accept={acceptImageType}
              type="file"
              name="photo"
              component={ImageInput}
              placeholder="Photo"
            />
          </Box>
          <Box sx={styles.btnWrapper}>
            <Button
              variant="contained"
              type="submit"
              disabled={!dirty || isLoading}
              sx={styles.btn}
            >
              Save
            </Button>
          </Box>
        </Form>
      )}
    </Formik>
  );
};

export default PlayerForm;
