import {useCallback, useEffect} from 'react';
import {useNavigate} from "react-router-dom";

import { useCreateUserMutation } from '@/apis/users';
import {Pathes} from "@/constants";
import { useToastMessageRequest } from '@/hooks';
import { toSnakeCase } from '@/utils';

import type { IUserFormParams } from '@/types';

export const useCreateUserPage = () => {
  const [createUser, { isLoading, isSuccess, ...requestParams }] = useCreateUserMutation();

  useToastMessageRequest({ isSuccess, ...requestParams });

  const navigate = useNavigate();

  useEffect(() => {
    if (isSuccess) {
      navigate(Pathes.Users);
    }
  }, [isSuccess]);

  const onSubmit = useCallback(
    ({ isUserBanned, bannedTill, login, ...params }: IUserFormParams) => {
      createUser({
        bannedTill: isUserBanned ? bannedTill : null,
        login: toSnakeCase(login),
        ...params,
      });
    },
    [],
  );

  return {
    isLoading,
    onSubmit,
  };
};
