import React from 'react';

import { ArticleSectionForm } from '@/components';

import { useCreateArticleSectionPage } from './useCreateArticleSectionPage';

const CreateArticleSectionPage = () => {
  const props = useCreateArticleSectionPage();
  return <ArticleSectionForm {...props} />;
};

export default CreateArticleSectionPage;
