import { useNavigate } from 'react-router-dom';

import { useGetBookmakerReviewsListQuery } from '@/apis/bookmaeker-reviews';
import { usePagination, useTableFilters } from '@/hooks';

export const useMainBookmakerReviewsPage = () => {
  const navigate = useNavigate();
  const paginationParams = usePagination();
  const { page, rowsPerPage: limit } = paginationParams;
  const [filters, onFiltersChange] = useTableFilters([]);

  const { data: reviews, isLoading } = useGetBookmakerReviewsListQuery({
    limit,
    page: page + 1,
    ...filters,
  });
  const count = (reviews?.meta.totalPages ?? 1) * limit;

  const noData = !reviews?.items.length;

  const goToCreatePage = () => {
    navigate('create');
  };

  return {
    isLoading,
    noData,
    count,
    reviews,
    onFiltersChange,
    goToCreatePage,
    ...paginationParams,
  };
};
