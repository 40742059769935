import React from 'react';
import { useParams } from 'react-router-dom';

import { useGetSeasonByIdQuery } from '@/apis/seasons';
import { ApiIntegrationForm } from '@/components';
import { IntegrationEntityType } from '@/types';

const EditSeasonPage = () => {
  const params = useParams();
  const id = params.id as string;
  const { data: season } = useGetSeasonByIdQuery(id);
  return <ApiIntegrationForm id={season?.id} entityType={IntegrationEntityType.Season} />;
};

export default EditSeasonPage;
