import { useMemo } from 'react';

import { useGetSportsListQuery } from '@/apis/sports';

export const useUserExpertForm = () => {
  const { data: sports } = useGetSportsListQuery({
    limit: 20,
    page: 1,
  });
  const mapSportById = useMemo(
    () =>
      sports?.items.reduce(
        (accum, current) => ({
          ...accum,
          [current.id]: current.name,
        }),
        {},
      ) ?? {},
    [sports],
  );

  return { sports, mapSportById };
};
