import React from 'react';

import { NewsForm } from '@/components';

import { useCreateNewsPage } from './useCreateNewsPage';

const CreateNewsPage = () => {
  const props = useCreateNewsPage();
  return <NewsForm {...props} />;
};

export default CreateNewsPage;
