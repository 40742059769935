import { FilterTypes } from '@/types';

import type { FiltersType } from '@/types';

export const headCells = [
  { title: 'Id', width: 200 },
  { title: 'Name', width: 200 },
  { title: 'Order', width: 200 },
  { title: '', width: 50 },
];

export const filteringFields: FiltersType = [
  { name: 'id', type: FilterTypes.Text },
  { name: 'name', type: FilterTypes.Text },
  { name: 'order', type: FilterTypes.Text },
  null,
];
