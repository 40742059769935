import { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';

import { useGetArticlesQuery } from '@/apis/article';
import { useGetArticleSectionsQuery } from '@/apis/article-section';
import { useGetSportsForFilters, usePagination, useTableFilters } from '@/hooks';
import { FilterValues } from '@/types';

import { getFilteringFields } from './constants';

export const useMainArticlesPage = () => {
  const navigate = useNavigate();
  const paginationParams = usePagination();
  const { page, rowsPerPage: limit } = paginationParams;
  const sports = useGetSportsForFilters();
  const { data: articleSections } = useGetArticleSectionsQuery({ page: 1, limit: 50 });
  const articleFilters = useMemo(
    () => articleSections?.items.map(({ id, name }) => ({ value: id, label: name })),
    [articleSections],
  );

  const filteringFields = getFilteringFields(sports, articleFilters as FilterValues[]);
  const [filters, onFiltersChange] = useTableFilters(filteringFields);

  const { data: articles, isLoading } = useGetArticlesQuery({
    limit,
    page: page + 1,
    ...filters,
  });

  const count = (articles?.meta.totalPages ?? 1) * limit;

  const noData = !articles?.items.length;

  const goToCreatePage = () => {
    navigate('create');
  };

  return {
    filteringFields,
    isLoading,
    noData,
    count,
    articles,
    goToCreatePage,
    onFiltersChange,
    ...paginationParams,
  };
};
