import { createSlice } from '@reduxjs/toolkit';

import type { INewsState } from './types';

const initialState: INewsState = {
  isDeleteNewsModalOpen: false,
  selectedNewsForModal: null,
};

export const articlesSlice = createSlice({
  name: 'articles',
  initialState,
  reducers: {
    openDeleteNewsModal(state: INewsState, { payload: bookmaker }) {
      state.isDeleteNewsModalOpen = true;
      state.selectedNewsForModal = bookmaker;
    },
    closeDeleteNewsModal(state: INewsState) {
      state.isDeleteNewsModalOpen = false;
      state.selectedNewsForModal = null;
    },
  },
});

export const { openDeleteNewsModal, closeDeleteNewsModal } = articlesSlice.actions;

export default articlesSlice.reducer;
