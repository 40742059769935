export const headCells = [
  { title: 'Id' },
  { title: 'Bookmaker' },
  { title: 'Text' },
  { title: '' },
];
export const filteringFields = [
  {
    active: true,
    key: 'id',
  },
  {
    active: true,
    key: 'name',
  },
  {
    active: true,
    key: 'icon',
  },

  {
    active: false,
    key: 'buttons',
  },
];
