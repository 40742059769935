import { createSlice } from '@reduxjs/toolkit';

import type { IArticlesState } from './types';

const initialState: IArticlesState = {
  isDeleteArticleModalOpen: false,
  selectedArticleForModal: null,
};

export const articlesSlice = createSlice({
  name: 'articles',
  initialState,
  reducers: {
    openDeleteArticleModal(state: IArticlesState, { payload: bookmaker }) {
      state.isDeleteArticleModalOpen = true;
      state.selectedArticleForModal = bookmaker;
    },
    closeDeleteArticleModal(state: IArticlesState) {
      state.isDeleteArticleModalOpen = false;
      state.selectedArticleForModal = null;
    },
  },
});

export const { openDeleteArticleModal, closeDeleteArticleModal } = articlesSlice.actions;

export default articlesSlice.reducer;
