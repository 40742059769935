import React from 'react';

import { BookmakerBonusForm } from '@/components';

import { useCreateBookmakerBonusPage } from './useCreateBookmakerBonusPage';

const CreateBookmakerBonusPage = () => {
  const props = useCreateBookmakerBonusPage();
  return <BookmakerBonusForm {...props} />;
};

export default CreateBookmakerBonusPage;
