import React from 'react';
import { useParams } from 'react-router-dom';

import { useGetSectionByIdQuery } from '@/apis/sections';
import { ApiIntegrationForm } from '@/components';
import { IntegrationEntityType } from '@/types';

const EditSectionsPage = () => {
  const params = useParams();
  const id = params.id as string;
  const { data: section } = useGetSectionByIdQuery(id);
  return <ApiIntegrationForm id={section?.id} entityType={IntegrationEntityType.Section} />;
};

export default EditSectionsPage;
