import { convertToHTML } from 'draft-convert';
import {
  ContentState,
  convertFromHTML,
  EditorState,
  RichUtils,
} from 'draft-js';
import { useCallback, useEffect, useRef, useState } from 'react';

import { useUploadImageMutation } from '@/apis/images';
import { getEntityToHTML } from '@/helpers';
import { IImagesResponse } from '@/types';
import { getYoutubeEmbeddedLink } from '@/utils';

import { IRichTextEditorInputProps } from './types';

export const useRichTextEditorInput = ({
  field,
  form: { touched, errors, setFieldValue },
}: IRichTextEditorInputProps) => {
  const [uploadImage] = useUploadImageMutation();
  const hasError = Boolean(touched[field.name] && errors[field.name]);
  const initialization = useRef(false);
  const content = ContentState.createFromText(field?.value ?? '');
  const [editorState, setEditorState] = useState(() =>
    EditorState.createWithContent(content)
  );

  const convertContentToHTML = () => {
    const currentContentAsHTML = convertToHTML({
      entityToHTML: getEntityToHTML as () => string,
    })(editorState.getCurrentContent());

    setFieldValue(field.name, currentContentAsHTML);
  };

  const handleEditorChange = (state: EditorState) => {
    setEditorState(state);
    convertContentToHTML();
  };

  const handleKeyCommand = (command: string) => {
    const newState = RichUtils.handleKeyCommand(editorState, command);

    if (newState) {
      setEditorState(newState);
      return 'handled';
    }

    return 'not-handled';
  };

  const setHTMLValueToState = (value = '') => {
    const blocksFromHTML = convertFromHTML(value);
    const contentState = ContentState.createFromBlockArray(
      blocksFromHTML.contentBlocks,
      blocksFromHTML.entityMap
    );

    setEditorState(EditorState.createWithContent(contentState));
  };

  useEffect(() => {
    if (field?.value && !initialization.current) {
      setHTMLValueToState(field.value);
      initialization.current = true;
    }
  }, [field]);

  const uploadCallback = useCallback(
    (file: File) =>
      new Promise((resolve) => {
        const reader = new window.FileReader();
        reader.onloadend = async () => {
          const response = (await uploadImage({ image: file })) as {
            data: IImagesResponse;
          };
          resolve(response.data);
        };
        reader.readAsDataURL(file);
      }),
    []
  );

  const toolbar = {
    image: {
      uploadCallback: uploadCallback,
      previewImage: true,
      alt: { present: true, mandatory: false },
      inputAccept: 'image/jpeg,image/jpg,image/png,image/gif,image/tiff',
      position: 'center',
    },
    embedded: {
      embedCallback: (link: string) => getYoutubeEmbeddedLink(link),
    },
  };

  return {
    toolbar,
    editorState,
    hasError,
    handleEditorChange,
    handleKeyCommand,
  };
};
