import React from 'react';

import { DeleteConfirmationDialog } from '@/components';

import { useDeletePlayerModal } from './useDeletePlayerModal';

const DeletePlayerModal = () => {
  const { isOpen, closeModal, confirmDeleting } = useDeletePlayerModal();
  return (
    <DeleteConfirmationDialog
      open={isOpen}
      onClose={closeModal}
      onConfirm={confirmDeleting}
      text={`Are you sure you want to delete player?`}
    />
  );
};

export default DeletePlayerModal;
