import { useGetCronsQuery } from '@/apis/crons';
import { usePagination } from '@/hooks';

export const useMainCronsPage = () => {
    const paginationParams = usePagination();

    const { page, rowsPerPage: limit } = paginationParams;

    const { data: crons, isLoading } = useGetCronsQuery({ page: page + 1, limit });

    const count = (crons?.meta.totalPages ?? 1) * limit;

    const noData = !crons?.items.length;

    return {
        crons,
        isLoading,
        noData,
        count,
        ...paginationParams,
    }
}
