import { createSlice } from '@reduxjs/toolkit';

import type { IBookmakersState } from './types';

const initialState: IBookmakersState = {
  isDeleteBookmakerModalOpen: false,
  selectedBookmakerForModal: null,
};

export const bookmakersSlice = createSlice({
  name: 'bookmakers',
  initialState,
  reducers: {
    openDeleteBookmakerModal(state: IBookmakersState, { payload: bookmaker }) {
      state.isDeleteBookmakerModalOpen = true;
      state.selectedBookmakerForModal = bookmaker;
    },
    closeDeleteBookmakerModal(state: IBookmakersState) {
      state.isDeleteBookmakerModalOpen = false;
      state.selectedBookmakerForModal = null;
    },
  },
});

export const { openDeleteBookmakerModal, closeDeleteBookmakerModal } = bookmakersSlice.actions;

export default bookmakersSlice.reducer;
