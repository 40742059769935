import { Container, Typography } from '@mui/material';
import React from 'react';

import { DefaultLeagueForm, SiteSettingsForm } from '@/components';

const SiteSettingsPage = () => {
  return (
    <Container>
      <Typography variant="h5" mb={2}>
        Default League
      </Typography>
      <DefaultLeagueForm />
      <Typography variant="h5" mb={2}>
        Website settings form
      </Typography>
      <SiteSettingsForm />
    </Container>
  );
};

export default SiteSettingsPage;
