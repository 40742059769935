import { Button, DialogActions } from '@mui/material';
import { Field, Form, Formik } from 'formik';
import React from 'react';

import { TextInput } from '@/components';

import { defaultInitialValues } from './constants';
import { styles } from './styles';

import type { IFormProps, IEventFormParams } from '@/types';

const EventForm: React.FC<IFormProps<IEventFormParams>> = ({
  initialValues = defaultInitialValues,
  onSubmit,
  isLoading,
}) => {
  return (
    <Formik enableReinitialize initialValues={initialValues} onSubmit={onSubmit}>
      {({ dirty }) => (
        <Form>
          <Field
            type="number"
            name="rating"
            autoComplete="rating"
            fullWidth
            component={TextInput}
            placeholder="Rating"
            InputProps={{ inputProps: { min: 0 } }}
          />
          <Field
            type="text"
            name="fsExternalId"
            autoComplete="fsExternalId"
            fullWidth
            component={TextInput}
            placeholder="Fs External Id"
          />
          <DialogActions>
            <Button
              variant="contained"
              type="submit"
              disabled={isLoading || !dirty}
              sx={styles.btn}
            >
              Save
            </Button>
          </DialogActions>
        </Form>
      )}
    </Formik>
  );
};

export default EventForm;
