import React from 'react';

import { DeleteConfirmationDialog } from '@/components';

import { useDeleteArticleSectionModal } from './useDeleteArticleSectionModal';

const DeleteArticleSectionModal: React.FC = () => {
  const { isOpen, articleSection, closeModal, confirmDeleting } = useDeleteArticleSectionModal();
  return (
    <DeleteConfirmationDialog
      open={isOpen}
      onClose={closeModal}
      onConfirm={confirmDeleting}
      text={`Are you sure you want to delete ${articleSection?.name ?? ''} section?`}
    />
  );
};

export default DeleteArticleSectionModal;
