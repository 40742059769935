import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { useDeleteArticleMutation } from '@/apis/article';
import { useToastMessageRequest } from '@/hooks';
import { articlesSelectors } from '@/redux/articles';
import { closeDeleteArticleModal } from '@/redux/articles/slice';

export const useDeleteArticleModal = () => {
  const dispatch = useDispatch();
  const isOpen = useSelector(articlesSelectors.isDeleteArticleModalOpenSelector);
  const article = useSelector(articlesSelectors.selectedArticleForModalSelector);
  const [deleteArticle, { isSuccess, ...requestParams }] = useDeleteArticleMutation();

  useToastMessageRequest({ isSuccess, ...requestParams });

  const closeModal = () => {
    dispatch(closeDeleteArticleModal());
  };

  const confirmDeleting = () => {
    deleteArticle(article?.id ?? 0);
  };

  useEffect(() => {
    if (isSuccess) {
      closeModal();
    }
  }, [isSuccess]);

  return { isOpen, article, closeModal, confirmDeleting };
};
