import { Autocomplete, CircularProgress, TextField, Typography } from '@mui/material';
import React from 'react';

import { UserExpertForm } from '@/components';

import { useCreateExpertsPage } from './useCreateExpertsPage';

const CreateExpertsPage = () => {
  const {
    open,
    users,
    selectedUser,
    searchName,
    isUsersLoading,
    isLoading,
    onSubmit,
    toggleOpen,
    onUserNameChange,
    onSelectUser,
    isOptionEqualToValue,
  } = useCreateExpertsPage();

  return (
    <>
      <Typography variant="h6" mb={1}>
        Users
      </Typography>
      <Autocomplete
        value={selectedUser}
        open={open}
        size="small"
        onChange={onSelectUser}
        onOpen={toggleOpen(true)}
        onClose={toggleOpen(false)}
        isOptionEqualToValue={isOptionEqualToValue}
        getOptionLabel={(option) => `${option.firstName} ${option.lastName}`}
        options={users?.items ?? []}
        loading={isUsersLoading}
        sx={{ width: 300, mb: 1 }}
        renderInput={(params) => (
          <TextField
            value={searchName}
            placeholder="User"
            label=""
            onChange={onUserNameChange}
            {...params}
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <>
                  {isUsersLoading ? <CircularProgress color="inherit" size={20} /> : null}
                  {params.InputProps.endAdornment}
                </>
              ),
            }}
          />
        )}
      />
      <UserExpertForm isLoading={isLoading} onSubmit={onSubmit} />
    </>
  );
};

export default CreateExpertsPage;
