import {useCallback, useEffect, useMemo} from 'react';
import {useNavigate, useParams} from 'react-router-dom';

import { useEditBookmakerMutation, useGetBookmakerByIdQuery } from '@/apis/bookmakers';
import { Pathes } from "@/constants";
import { useToastMessageRequest } from '@/hooks';

import type { IBookmakerFormParams } from '@/types';

export const useEditBookmakerPage = () => {
  const params = useParams();
  const id = params.id as string;
  const { data: bookmaker } = useGetBookmakerByIdQuery(id);
  const [editBookmaker, { isLoading, isSuccess, ...editBookmakerParams }] = useEditBookmakerMutation();

    const navigate = useNavigate();

  useToastMessageRequest({isSuccess, ...editBookmakerParams});

  const initialValues = useMemo(
    () => ({
      name: bookmaker?.name ?? '',
      link: bookmaker?.link ?? '',
      currency: bookmaker?.currency ?? '',
      bonusLink: bookmaker?.bonusLink ?? '',
      usersTrust: bookmaker?.usersTrust ?? 0,
      rating: bookmaker?.rating ?? 0,
      paymentMethods: bookmaker?.paymentMethods ?? 0,
      withdrawFunds: bookmaker?.withdrawFunds ?? 0,
      odds: bookmaker?.odds ?? 0,
      bettingLimits: bookmaker?.bettingLimits ?? 0,
      bettingCalculationSpeed: bookmaker?.bettingCalculationSpeed ?? 0,
      bonusesIndicator: bookmaker?.bonusesIndicator ?? 0,
      support: bookmaker?.support ?? 0,
      companyReliability: bookmaker?.companyReliability ?? 0,
      bonus: bookmaker?.bonus ?? 0,
      exclusive: bookmaker?.exclusive ?? false,
      status: bookmaker?.status ?? false,
      legal: bookmaker?.legal ?? false,
      withoutIdentification: bookmaker?.withoutIdentification ?? false,
      major: bookmaker?.major ?? false,
      other: bookmaker?.other ?? false,
      icon: bookmaker?.icon ?? '',
      parserName: bookmaker?.parserName ?? '',
      textGetBonus: bookmaker?.textGetBonus ?? 0,
      feedback_count: bookmaker?.feedbackCount ?? 0,
      pluses: bookmaker?.pluses ?? [],
      minuses: bookmaker?.minuses ?? [],
      services: bookmaker?.services ?? [],
      registrationBlockContent: bookmaker?.registrationBlockContent ?? '',
      lineBlockContent: bookmaker?.lineBlockContent ?? '',
      mobileBlockContent: bookmaker?.mobileBlockContent ?? '',
      supportBlockContent: bookmaker?.supportBlockContent ?? '',
      mainTips: bookmaker?.mainTips ?? [],
      cashbackBlockContent: bookmaker?.cashbackBlockContent ?? '',
      paymentMethodsImg: bookmaker?.paymentMethodsImg ?? [],
      paymentIcons: [],
    }),
    [bookmaker],
  );

  const onSubmit = useCallback(
    ({
       pluses,
       minuses,
       services,
       paymentIcons,
       mainTips,
       paymentMethodsImg,
       ...params
    }: IBookmakerFormParams) => {
      const filteredPluses = pluses.filter(item => !!item.length);
      const filteredMinuses = minuses.filter(item => !!item.length);
      const filteredServices = services.filter(item => !!item.length);
      const filteredPaymentMethods = paymentIcons?.filter(item => item instanceof Blob);

      editBookmaker({
        id: bookmaker?.id,
        pluses: JSON.stringify(filteredPluses),
        minuses: JSON.stringify(filteredMinuses),
        services: JSON.stringify(filteredServices),
        mainTips: JSON.stringify(mainTips),
        paymentMethodsImg: JSON.stringify(paymentMethodsImg),
        paymentIcons: filteredPaymentMethods,
        ...params
      });
    },
    [id, bookmaker],
  );

  useEffect(() => {
      if (isSuccess) {
          navigate(Pathes.Bookmakers);
      }
  }, [isSuccess])

  return { isLoading, initialValues, onSubmit };
};
