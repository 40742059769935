import { useCallback, useMemo } from 'react';
import { useParams } from 'react-router-dom';

import { useEditPlayerMutation, useGetPlayerByIdQuery } from '@/apis/players';
import { useToastMessageRequest } from '@/hooks';
import { IPlayerFormParams } from '@/types/forms/player';

export const useEditPlayersPage = () => {
  const params = useParams();
  const { data: player } = useGetPlayerByIdQuery(params.id as string);

  const [editPlayer, { isLoading, ...editParams }] = useEditPlayerMutation();

  useToastMessageRequest(editParams);

  const initialValues = useMemo(
    () => ({
      photo: player?.photo ?? '',
      fsExternalId: player?.fsExternalId ?? '',
    }),
    [player],
  );

  const onSubmit = useCallback(
    (params: IPlayerFormParams) => {
      editPlayer({ id: player?.id ?? 0, ...params });
    },
    [player],
  );
  return { player, initialValues, isLoading, onSubmit };
};
