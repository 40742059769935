import { useGetChallengesQuery } from '@/apis/challenges';
import { usePagination, useTableFilters } from '@/hooks';

import { filteringFields } from './constants';

export const useMainChallengePage = () => {
  const paginationParams = usePagination();
  const { page, rowsPerPage: limit } = paginationParams;
  const [filters, onFiltersChange] = useTableFilters(filteringFields);
  const { data: challenges, isLoading } = useGetChallengesQuery({
    page: page + 1,
    limit,
    ...filters,
  });

  const count = (challenges?.meta.totalPages ?? 1) * limit;

  const noData = !challenges?.items.length;

  return {
    filters,
    filteringFields,
    isLoading,
    noData,
    count,
    challenges,
    onFiltersChange,
    ...paginationParams,
  };
};
