import { createSelector } from '@reduxjs/toolkit';

import type { INewsState } from './types';
import type { RootState } from '@/redux/store';

export const newsStateSelector = (state: RootState) => state.news;

export const selectedNewsForModalSelector = createSelector(
  newsStateSelector,
  (state: INewsState) => state.selectedNewsForModal,
);

export const isDeleteNewsModalOpenSelector = createSelector(
  newsStateSelector,
  (state: INewsState) => state.isDeleteNewsModalOpen,
);
