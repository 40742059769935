import { Add, Delete } from '@mui/icons-material';
import { Box, Button, DialogActions, Divider, Grid, MenuItem, Typography } from '@mui/material';
import { Field, FieldArray, Form, Formik, getIn } from 'formik';
import React from 'react';

import { CheckboxInput, RichTextEditorInput, SelectInput, TextInput } from '@/components';

import { defaultInitialValues } from './constants';
import { articleFormSchema } from './schemas';
import { styles } from './styles';
import { useArticleForm } from './useArticleForm';

import type { IFormProps, IArticleFormParams } from '@/types';

const ArticleForm: React.FC<IFormProps<IArticleFormParams> & { withTranslation?: boolean }> = ({
  initialValues = defaultInitialValues,
  onSubmit,
  isLoading,
  withTranslation = false,
}) => {
  const { languages, sports, sections, addNewTranslation, removeTranslation } =
    useArticleForm(withTranslation);

  return (
    <Formik
      enableReinitialize
      validationSchema={articleFormSchema}
      initialValues={initialValues}
      onSubmit={onSubmit}
    >
      {({ dirty, values, errors }) => (
        <Form>
          <Grid container spacing={2} sx={styles.container}>
            <Grid item xs>
              <Field
                type="text"
                name="title"
                autoComplete="title"
                fullWidth
                component={TextInput}
                placeholder="Title"
              />
            </Grid>
            <Grid item xs>
              <Field
                name="sportId"
                autoComplete="sportId"
                fullWidth
                component={SelectInput}
                placeholder="Sport"
              >
                <MenuItem value="" disabled>
                  <Typography>Sport</Typography>
                </MenuItem>
                {sports?.items.map(({ name, id }) => (
                  <MenuItem value={id} key={name}>
                    <Typography>{name}</Typography>
                  </MenuItem>
                ))}
              </Field>
            </Grid>
          </Grid>
          <Grid container spacing={2} sx={styles.container}>
            <Grid item xs>
              <Field
                name="articleSectionId"
                autoComplete="articleSectionId"
                fullWidth
                component={SelectInput}
                placeholder="Sections"
              >
                <MenuItem value="" disabled>
                  <Typography>Sections</Typography>
                </MenuItem>
                {sections?.items.map(({ name, id }) => (
                  <MenuItem value={id} key={name}>
                    <Typography>{name}</Typography>
                  </MenuItem>
                ))}
              </Field>
            </Grid>
            <Grid item xs>
              <Field
                type="number"
                name="order"
                autoComplete="order"
                fullWidth
                component={TextInput}
                placeholder="Order"
                InputProps={{ inputProps: { min: 0 } }}
              />
            </Grid>
          </Grid>
          <Grid container spacing={2} sx={styles.container}>
            <Grid item xs>
              <Field
                type="text"
                name="seoDescription"
                autoComplete="seoDescription"
                fullWidth
                component={TextInput}
                placeholder="Seo Description"
              />
            </Grid>
          </Grid>
          <Field
            type="text"
            name="content"
            autoComplete="content"
            fullWidth
            component={RichTextEditorInput}
            placeholder="Content"
            multiline
            rows={3}
          />
          <Box my={1}>
            <Field
              name="isPublished"
              component={CheckboxInput}
              label={<Typography variant="h6">Published</Typography>}
            />
          </Box>
          {withTranslation && (
            <>
              <Typography variant="h4" mb={2}>
                Translation
              </Typography>
              <FieldArray
                name="translations"
                render={(arrayHelpers) => (
                  <>
                    {values.translations &&
                      values?.translations.length > 0 &&
                      values.translations.map((translation, index) => {
                        const fieldName = `translations[${index}]`;
                        const error = getIn(errors, fieldName);
                        return (
                          <>
                            <Grid container spacing={4} key={index}>
                              <Grid item xs>
                                <Field
                                  type="text"
                                  name={`translations.${index}.title`}
                                  autoComplete={`translations.${index}.title`}
                                  fullWidth
                                  component={TextInput}
                                  placeholder="Title"
                                  error={Boolean(error?.name)}
                                />
                                <Field
                                  type="text"
                                  name={`translations.${index}.content`}
                                  autoComplete={`translations.${index}.content`}
                                  fullWidth
                                  component={RichTextEditorInput}
                                  placeholder="Content"
                                  multiline
                                  rows={3}
                                />
                                <Field
                                  name={`translations.${index}.language.name`}
                                  autoComplete={`translations.${index}.language.name`}
                                  fullWidth
                                  component={SelectInput}
                                  placeholder="Language"
                                  error={error || error?.language?.name}
                                >
                                  <MenuItem value="" disabled>
                                    <Typography>Language</Typography>
                                  </MenuItem>
                                  {languages?.items.map(({ name }) => (
                                    <MenuItem value={name} key={name}>
                                      <Typography>{name}</Typography>
                                    </MenuItem>
                                  ))}
                                </Field>
                              </Grid>

                              <Grid item>
                                <Button
                                  variant="contained"
                                  color="error"
                                  sx={styles.deleteBtn}
                                  onClick={removeTranslation(arrayHelpers, index)}
                                >
                                  <Delete />
                                </Button>
                              </Grid>
                            </Grid>

                            <Divider sx={{ my: 4 }} orientation="horizontal" />
                          </>
                        );
                      })}
                    <Button variant="contained" onClick={addNewTranslation(arrayHelpers)}>
                      <Add />
                    </Button>
                  </>
                )}
              />
            </>
          )}
          <DialogActions>
            <Button
              variant="contained"
              type="submit"
              disabled={isLoading || !dirty}
              sx={styles.btn}
            >
              Save
            </Button>
          </DialogActions>
        </Form>
      )}
    </Formik>
  );
};

export default ArticleForm;
