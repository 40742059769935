import {useCallback, useEffect} from 'react';
import {useNavigate} from "react-router-dom";

import { useCreateNewsMutation } from '@/apis/news';
import {Pathes} from "@/constants";
import { useToastMessageRequest } from '@/hooks';

import type { INewsFormParams } from '@/types';

export const useCreateNewsPage = () => {
  const [createNews, { isLoading, isSuccess, ...requestParams }] = useCreateNewsMutation();

  useToastMessageRequest({ isSuccess, ...requestParams });

  const navigate = useNavigate();

  useEffect(() => {
    if (isSuccess) {
      navigate(Pathes.News);
    }
  }, [isSuccess]);

  const onSubmit = useCallback(({ league, ...params }: INewsFormParams) => {
    createNews({ leagueId: league.id ?? 0, ...params });
  }, []);

  return { isLoading, onSubmit };
};
