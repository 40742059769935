import { createSelector } from '@reduxjs/toolkit';

import type { IArticleSectionsState } from './types';
import type { RootState } from '@/redux/store';

export const articlesSectionsStateSelector = (state: RootState) => state.articlesSections;

export const selectedArticleSectionForModalSelector = createSelector(
  articlesSectionsStateSelector,
  (state: IArticleSectionsState) => state.selectedArticleSectionForModal,
);

export const isDeleteArticleSectionModalOpenSelector = createSelector(
  articlesSectionsStateSelector,
  (state: IArticleSectionsState) => state.isDeleteArticleSectionModalOpen,
);
