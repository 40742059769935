export const defaultInitialValues = {
  logo: '',
  order: 0,
  rank: 1,
  isFavorite: false,
  isActive: false,
};

export const acceptImageType = {
  'image/*': ['.png'],
};
