import {useCallback, useEffect} from 'react';
import {useNavigate} from "react-router-dom";

import { useCreateArticleSectionMutation } from '@/apis/article-section';
import {Pathes} from "@/constants";
import { useToastMessageRequest } from '@/hooks';

import type { IArticleSectionFormParams } from '@/types';

export const useCreateArticleSectionPage = () => {
  const [createArticleSection, { isLoading, isSuccess, ...requestParams }] = useCreateArticleSectionMutation();

  useToastMessageRequest({ isSuccess, ...requestParams });

  const navigate = useNavigate();

  useEffect(() => {
    if (isSuccess) {
      navigate(Pathes.ArticlesSections);
    }
  }, [isSuccess]);

  const onSubmit = useCallback((params: IArticleSectionFormParams) => {
    createArticleSection(params);
  }, []);

  return { isLoading, onSubmit };
};
