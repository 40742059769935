import React from 'react';

import { ApiIntegrationForm, SportForm } from '@/components';
import { IntegrationEntityType } from '@/types';

import { useEditSportPage } from './useEditSportPage';

const EditSportPage = () => {
  const { sport, ...props } = useEditSportPage();
  return (
    <>
      <SportForm {...props} />
      <ApiIntegrationForm id={sport?.id} entityType={IntegrationEntityType.Sport} />
    </>
  );
};

export default EditSportPage;
