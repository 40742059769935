import React from 'react';

import { BookmakerReviewForm } from '@/components';

import { useCreateBookmakerReviewPage } from './useCreateBookmakerReviewPage';

const CreateBookmakerReviewsPage = () => {
  const props = useCreateBookmakerReviewPage();
  return <BookmakerReviewForm {...props} />;
};

export default CreateBookmakerReviewsPage;
