import { FormControl, TextField, Typography } from '@mui/material';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import React from 'react';

import { toIsoString } from '@/utils';

import type { IDateTimeInputProps } from './types';

function DateTimeInput<FormValues extends Record<string, unknown> = Record<string, unknown>>({
  placeholder,
  field: { value, onBlur, name },
  form: { touched, errors, setFieldValue },
  textFieldProps,
  ...props
}: IDateTimeInputProps<FormValues>) {
  const hasError = Boolean(touched[name] && errors[name]);

  const handleChange = (date: Date | null) => {
    const val = toIsoString(date);
    onBlur({ target: { name, val } });
    setFieldValue(name, val);
  };

  return (
    <FormControl fullWidth>
      <Typography variant="h6" mb={1}>
        {placeholder}
      </Typography>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <DateTimePicker
          value={value}
          onChange={handleChange}
          renderInput={(params) => (
            <TextField
              size="small"
              {...textFieldProps}
              {...params}
              inputProps={{ ...textFieldProps?.inputProps, ...params?.inputProps }}
              InputProps={{ ...textFieldProps?.InputProps, ...params?.InputProps }}
              onBlur={onBlur}
              error={hasError || params.error}
              helperText={(hasError ? errors[name] : params.helperText || ' ') as string}
            />
          )}
          {...props}
        />
      </LocalizationProvider>
    </FormControl>
  );
}

export default DateTimeInput;
