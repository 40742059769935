import { useGetTeamsListQuery } from '@/apis/teams';
import { useGetSportsForFilters, usePagination, useTableFilters } from '@/hooks';

import { getFilteringFields } from './constants';

export const useMainTeamsPage = () => {
  const sports = useGetSportsForFilters();
  const filteringFields = getFilteringFields(sports);
  const [filters, onFiltersChange] = useTableFilters(filteringFields);
  const paginationParams = usePagination();
  const { page, rowsPerPage: limit } = paginationParams;

  const { data: teams, isLoading } = useGetTeamsListQuery({
    limit,
    page: page + 1,
    ...filters,
  });

  const count = (teams?.meta.totalPages ?? 1) * limit;

  const noData = !teams?.items.length;

  return {
    isLoading,
    count,
    filteringFields,
    noData,
    teams,
    onFiltersChange,
    ...paginationParams,
  };
};
