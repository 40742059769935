import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { useDeleteLeagueMutation } from '@/apis/leagues';
import { useToastMessageRequest } from '@/hooks';
import { leaguesSelectors } from '@/redux/leagues';
import { closeDeleteLeagueModal } from '@/redux/leagues/slice';

export const useDeleteLeagueModal = () => {
  const dispatch = useDispatch();
  const isOpen = useSelector(leaguesSelectors.isDeleteLeagueModalOpenSelector);
  const league = useSelector(leaguesSelectors.selectedLeagueForModalSelector);
  const [deleteUser, { isSuccess, ...requestParams }] = useDeleteLeagueMutation();

  useToastMessageRequest({ isSuccess, ...requestParams });

  const closeModal = () => {
    dispatch(closeDeleteLeagueModal());
  };

  const confirmDeleting = () => {
    deleteUser(league?.id ?? 0);
  };

  useEffect(() => {
    if (isSuccess) {
      closeModal();
    }
  }, [isSuccess]);

  return { isOpen, league, closeModal, confirmDeleting };
};
