import { useEffect, useState } from 'react';

const allowedImageExtensions = [
  'png',
  'svg',
  'jpg',
  'jpeg',
  'bmp',
  'webp',
  'gif',
  'apng',
  'tiff',
  'tif',
  'img',
];

const useFilePreview = (src?: string, file?: File) => {
  const [fileSrc, setFileSrc] = useState<string | null>(null);

  useEffect(() => {
    if (file) {
      const url = URL.createObjectURL(file);
      setFileSrc(url);
      return () => {
        URL.revokeObjectURL(url);
        setFileSrc(null);
      };
    }
  }, [file]);

  return { imgSrc: fileSrc || src };
};

export default useFilePreview;
