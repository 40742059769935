import React from 'react';
import {DataGrid} from "@mui/x-data-grid";
import {columnsLeagues, columnsTotal} from "@/views/predictions/EditLeaguePage/constants";
import {CircularProgress} from "@mui/material";
const PredictionTable: React.FC<any> = ({rows, isLoading, liveData}) => {
  if (isLoading || !liveData) return (<CircularProgress/>);
  const liveColumns = [
    {field: 'id', headerName: 'ID', width: 50},
    {field: 'eventUrl', headerName: 'eventUrl', width: 400},
    {field: 'time', headerName: 'time', width: 50},
    {field: 'actualTime', headerName: 'actualTime', width: 50},
    {field: 'type', headerName: 'type', width: 100},
    {field: 'bet', headerName: 'bet', width: 150},
    {field: 'winning', headerName: 'winning', width: 100},
    {field: 'goals', headerName: 'goals', width: 150},
    {field: 'homeAgc', headerName: 'homeAgc', width: 80},
    {field: 'homeAgs', headerName: 'homeAgs', width: 80},
    {field: 'awayAgc', headerName: 'awayAgc', width: 80},
    {field: 'awayAgs', headerName: 'awayAgs', width: 80},
    {field: 'createdAt', headerName: 'createdAt', width: 170},
  ]

  const liveColumnsStats = [
    {field: 'id', headerName: 'type', width: 100},
    {field: 'totalBets', headerName: 'totalBets', width: 100},
    {field: 'loseCount', headerName: 'loseCount', width: 100},
    {field: 'winCount', headerName: 'winCount', width: 100},
    {field: 'spendForBets', headerName: 'spendForBets', width: 100},
    {field: 'profit', headerName: 'profit', width: 100},
    {field: 'roi', headerName: 'roi', width: 100},
  ]

  const columns = rows?.types.map((type: string) => ({ field: type, headerName: type, width: 100 })) || [];
  const dynamicColumnsTotal = [...columnsTotal, ...columns]
  const dynamicColumnsLeagues = [...columnsLeagues, ...columns]
  return (
    <div>
      {!columns.length? <div></div> :
        <>
          <h1>Total</h1>
          <DataGrid rows={rows?.totalRows || []} columns={dynamicColumnsTotal} />
          <h1>Leagues</h1>
          <DataGrid rows={rows?.leaguesRows || []} columns={dynamicColumnsLeagues} />
          </>
      }
      <h1>Live Predictions Stats</h1>
      <DataGrid rows={liveData.stats || []} columns={liveColumnsStats} />
      <h1>Live</h1>
      <DataGrid rows={liveData.rows || []} columns={liveColumns} />
    </div>
  );
};

export default PredictionTable;
