import { createSelector } from '@reduxjs/toolkit';

import type { IBookmakersState } from './types';
import type { RootState } from '@/redux/store';

export const bookmakersStateSelector = (state: RootState) => state.bookmakers;

export const selectedBookmakerForModalSelector = createSelector(
  bookmakersStateSelector,
  (state: IBookmakersState) => state.selectedBookmakerForModal,
);

export const isDeleteBookmakerModalOpenSelector = createSelector(
  bookmakersStateSelector,
  (state: IBookmakersState) => state.isDeleteBookmakerModalOpen,
);
