import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/dist/query/react';

import { ApiRoutes } from '@/constants';
import { setAccessTokenToHeaders } from '@/helpers';
import { envUtil } from '@/utils';

import type { IRequestParams, IResponse, IChallengeResponse } from '@/types';
const { api } = envUtil.getEnv();

export const challengesApi = createApi({
  reducerPath: 'challengesApi',
  baseQuery: fetchBaseQuery({
    baseUrl: api,
    prepareHeaders: (headers) => setAccessTokenToHeaders(headers),
  }),
  tagTypes: ['Challenges', 'Challenge'],
  endpoints: (builder) => ({
    getChallenges: builder.query<
      IResponse<IChallengeResponse>,
      IRequestParams & {
        name?: string;
        nameFs?: string;
        id?: string;
      }
    >({
      query: (params) => ({
        url: ApiRoutes.Challenges,
        params,
      }),
      providesTags: (result) =>
        result
          ? [
              ...result.items.map(({ id }) => ({
                type: 'Challenges' as const,
                id,
              })),
              { type: 'Challenges', id: 'LIST' },
            ]
          : [{ type: 'Challenges', id: 'LIST' }],
      keepUnusedDataFor: 1,
    }),
    getChallengeById: builder.query<IChallengeResponse, string>({
      query: (id) => ({
        url: `${ApiRoutes.Challenges}/${id}`,
      }),
      providesTags: () => ['Challenge'],
    }),
  }),
});

export const { useGetChallengesQuery, useGetChallengeByIdQuery } = challengesApi;
