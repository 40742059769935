import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/dist/query/react';

import { ApiRoutes } from '@/constants';
import { objToFormData, setAccessTokenToHeaders } from '@/helpers';
import { envUtil } from '@/utils';

import type { ILeagueFormParams, ILeagueResponse, IRequestParams, IResponse } from '@/types';

const { api } = envUtil.getEnv();

export const leaguesApi = createApi({
  reducerPath: 'leaguesApi',
  baseQuery: fetchBaseQuery({
    baseUrl: api,
    prepareHeaders: (headers) => setAccessTokenToHeaders(headers),
  }),
  tagTypes: ['Leagues', 'League'],
  endpoints: (builder) => ({
    getLeagues: builder.query<IResponse<ILeagueResponse>, IRequestParams & { name?: string }>({
      query: (params) => ({
        url: ApiRoutes.Leagues,
        params,
      }),
      providesTags: (result) =>
        result
          ? [
              ...result.items.map(({ id }) => ({
                type: 'Leagues' as const,
                id,
              })),
              { type: 'Leagues', id: 'LIST' },
            ]
          : [{ type: 'Leagues', id: 'LIST' }],
      keepUnusedDataFor: 1,
    }),
    editLeague: builder.mutation<ILeagueResponse, ILeagueFormParams & { id: number }>({
      query: ({ id, ...body }) => ({
        url: `${ApiRoutes.Leagues}/${id}`,
        body: objToFormData<ILeagueFormParams>(body),
        method: 'PUT',
      }),
      invalidatesTags: ['League'],
    }),
    getDefaultLeague: builder.query<ILeagueResponse, void>({
      query: () => ({
        url: `${ApiRoutes.Leagues}/default-league`,
      }),
    }),
    getLeagueBySlug: builder.query<ILeagueResponse, string>({
      query: (slug) => ({
        url: `${ApiRoutes.Leagues}/${slug}`,
      }),
      providesTags: () => ['League'],
    }),
    deleteLeague: builder.mutation<void, number>({
      query: (id) => ({
        url: `${ApiRoutes.Leagues}/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: [{ type: 'Leagues', id: 'LIST' }],
    }),
  }),
});

export const {
  useLazyGetLeaguesQuery,
  useGetDefaultLeagueQuery,
  useGetLeaguesQuery,
  useDeleteLeagueMutation,
  useEditLeagueMutation,
  useGetLeagueBySlugQuery,
} = leaguesApi;
