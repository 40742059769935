import { useCallback, useMemo } from 'react';
import { useParams } from 'react-router-dom';

import {
  useCreateExpertUserMutation,
  useEditExpertUserMutation,
  useEditUserMutation,
  useGetUserByIdQuery,
} from '@/apis/users';
import { UserRoles } from '@/constants';
import { useToastMessageRequest } from '@/hooks';
import { toSnakeCase } from '@/utils';

import type { IExpertFormParams, IUserFormParams } from '@/types';

export const useEditUserPage = () => {
  const params = useParams();
  const id = params.id as string;
  const { data: user } = useGetUserByIdQuery(id);
  const [editUser, { isLoading: isEditUserLoading, ...editUserRequestParams }] =
    useEditUserMutation();
  const [editExpert, { isLoading: isEditExpertLoading, ...editExpertRequestParams }] =
    useEditExpertUserMutation();
  const [createExpert, { isLoading: isCreateExpertLoading, ...createExpertRequestParams }] =
    useCreateExpertUserMutation();

  const expertInfo = user?.expertInfo;
  const showExpertForm = user?.role === UserRoles.Expert;

  useToastMessageRequest(editUserRequestParams);
  useToastMessageRequest(editExpertRequestParams);
  useToastMessageRequest(createExpertRequestParams);

  const initialUserValues = useMemo(
    () => ({
      firstName: user?.firstName ?? '',
      lastName: user?.lastName ?? '',
      login: user?.login ?? '',
      email: user?.email ?? '',
      password: user?.password ?? '',
      role: user?.role ?? '',
      rating: user?.rating ?? 0,
      disableNotification: user?.disableNotification ?? false,
      confirmed: user?.confirmed ?? false,
      avatar: user?.avatar ?? '',
      bannedTill: user?.bannedTill ?? '',
      isUserBanned: !!user?.bannedTill,
      isIpBanned: user?.isIpBanned ?? false,
    }),
    [user],
  );

  const initialExpertValues = useMemo(
    () => ({
      statusTitle: expertInfo?.statusTitle ?? '',
      about: expertInfo?.about ?? '',
      social: expertInfo?.social ?? {
        twitter: '',
        instagram: '',
        facebook: '',
      },
      competence: expertInfo?.competence.map(({ sport }) => sport.id) ?? [],
    }),
    [expertInfo],
  );

  const onUserSubmit = useCallback(
    ({ isUserBanned, bannedTill, login, ...params }: IUserFormParams) => {
      editUser({
        id,
        bannedTill: isUserBanned ? bannedTill : null,
        login: toSnakeCase(login),
        ...params,
      });
    },
    [id],
  );

  const onExpertSubmit = useCallback(
    (params: IExpertFormParams) => {
      expertInfo ? editExpert({ id, ...params }) : createExpert({ id, ...params });
    },
    [id, expertInfo],
  );

  return {
    isCreateExpertLoading,
    isEditExpertLoading,
    showExpertForm,
    initialUserValues,
    initialExpertValues,
    isEditUserLoading,
    onUserSubmit,
    onExpertSubmit,
  };
};
