import { Visibility } from '@mui/icons-material';
import { IconButton, TableCell, TableRow } from '@mui/material';
import React from 'react';

import { useTableItem } from '@/hooks';
import {ICronResponse} from "@/types";

const CronTableItem: React.FC<ICronResponse> = (cron) => {
    const { id, title } = cron;

    const { onView } = useTableItem();

    return (
        <TableRow>
            <TableCell component="th" scope="row" width={350}>
                {id}
            </TableCell>
            <TableCell component="th" scope="row" width={350}>
                {title}
            </TableCell>
            <TableCell width={20}>
                <IconButton title="View cron" onClick={onView(id)}>
                    <Visibility />
                </IconButton>
            </TableCell>
        </TableRow>
    );
};

export default CronTableItem;
