export const styles = {
  container: {
    width: '100%',
    mt: 1,
  },
  form: {
    mt: 1,
    width: '100%',
    display: 'flex',
  },
  textField: {
    flexGrow: 1,
    mr: 2,
  },
  integration: {
    mt: 1,
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    pl: 2,
  },
  integrations: {
    width: '100%',
    mt: 2,
  },
} as const;
