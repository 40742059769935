import { Field } from "formik";
import React from 'react';

import {DateTimeInput, TextInput} from "@/components";
import {FILTER_TYPES} from "@/components/forms/CronForm/constants";
import { ICronFilterField } from "@/types";

type CronFormFieldProps = {
  configItem: ICronFilterField;
}

export const CronFormField = ({ configItem }: CronFormFieldProps) => {
  switch (configItem.type.split(':')[0]) {
    case FILTER_TYPES.INPUT:
      return (
        <Field
          type={configItem.type.split(':')[1]}
          name={configItem.name}
          autoComplete={configItem.name}
          defaultValue=""
          fullWidth
          component={TextInput}
          placeholder={configItem.title}
        />
    );
    case FILTER_TYPES.DATE:
      return (
        <Field
          name={configItem.name}
          placeholder={configItem.title}
          component={DateTimeInput}
        />
    );
    default: return (
      <Field
        type={configItem.type.split(':')[1]}
        name={configItem.name}
        autoComplete={configItem.name}
        fullWidth
        defaultValue=""
        component={TextInput}
        placeholder={configItem.title}
      />
    );
  }
};

