import { FilterTypes } from '@/types';

import type { FiltersType } from '@/types';

export const headCells = [
  { title: 'Id', width: 100 },
  { title: 'First Name', width: 180 },
  { title: 'Last Name', width: 180 },
  { title: 'Avatar', width: 50 },
  { title: 'Email', width: 240 },
  { title: 'Role', width: 130 },
  { title: 'Status', width: 130 },
  { title: 'Created', width: 130 },
  { title: '', width: 100 },
];

export const filteringFields: FiltersType = [
  { name: 'id', type: FilterTypes.Text },
  { name: 'firstName', type: FilterTypes.Text },
  { name: 'lastName', type: FilterTypes.Text },
  null,
  { name: 'email', type: FilterTypes.Text },
  {
    name: 'role',
    type: FilterTypes.Select,
    values: [
      { label: 'Admin', value: 'admin' },
      { label: 'User', value: 'user' },
      { label: 'Expert', value: 'expert' },
    ],
  },
  {
    name: 'status',
    type: FilterTypes.Select,
    values: [
      { label: 'Active', value: 'Active' },
      { label: 'Inactive', value: 'Inactive' },
      { label: 'Banned', value: 'Banned' },
    ],
  },
  null,
  null,
];
