import React from 'react';

import { ArticleForm } from '@/components';

import { useEditArticlePage } from './useEditArticlePage';

const EditArticlePage = () => {
  const props = useEditArticlePage();
  return <ArticleForm withTranslation {...props} />;
};

export default EditArticlePage;
