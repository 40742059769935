import React from 'react';

import { DeleteConfirmationDialog } from '@/components';

import { useDeleteSportModal } from './useDeleteSportModal';

const DeleteSportModal: React.FC = () => {
  const { isOpen, sport, closeModal, confirmDeleting } = useDeleteSportModal();
  return (
    <DeleteConfirmationDialog
      open={isOpen}
      onClose={closeModal}
      onConfirm={confirmDeleting}
      text={`Are you sure you want to delete ${sport?.name ?? ''} user?`}
    />
  );
};

export default DeleteSportModal;
