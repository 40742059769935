import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { useDeleteBookmakerBonusMutation } from '@/apis/bookmaker-bonuses';
import { useToastMessageRequest } from '@/hooks';
import { bookmakerBonusesSelectors } from '@/redux/bookmaker-bonuses';
import { closeDeleteBookmakerBonusModal } from '@/redux/bookmaker-bonuses/slice';

export const useDeleteBookmakerBonusModal = () => {
  const dispatch = useDispatch();

  const isOpen = useSelector(bookmakerBonusesSelectors.isDeleteBookmakerBonusModalOpenSelector);
  const bonus = useSelector(bookmakerBonusesSelectors.selectedBookmakerBonusForModalSelector);

  const [deleteBonus, { isSuccess, ...requestParams }] = useDeleteBookmakerBonusMutation();

  useToastMessageRequest({ isSuccess, ...requestParams });

  const closeModal = () => {
    dispatch(closeDeleteBookmakerBonusModal());
  };

  const confirmDeleting = () => {
    deleteBonus(bonus?.id ?? '');
  };

  useEffect(() => {
    if (isSuccess) {
      closeModal();
    }
  }, [isSuccess]);

  return { isOpen, closeModal, confirmDeleting, bonus };
};
