import { FilterTypes, FilterValues } from '@/types';
export const headCells = [
  { title: 'Id', width: 200 },
  { title: 'Title', width: 200 },
  { title: 'Sport', width: 200 },
  { title: 'Author', width: 200 },
  { title: '', width: 100 },
];

export const columnsTotal = [
  { field: 'param', headerName: 'Param', width: 150 },
];

export const columnsLeagues = [
  { field: 'id', headerName: 'id', width: 10 },
  { field: 'country', headerName: 'Country', width: 150 },
  { field: 'leagueName', headerName: 'League', width: 150 },
  { field: 'param', headerName: 'Param', width: 150 },
];

export const getFilteringFields = (sports: FilterValues[]) => [
  { name: 'id', type: FilterTypes.Text },
  { name: 'title', type: FilterTypes.Text },
  { name: 'sport', type: FilterTypes.Select, values: sports },
  null,
];