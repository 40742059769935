import React from 'react';
import { useParams } from 'react-router-dom';

import { useGetChallengeByIdQuery } from '@/apis/challenges';
import { ApiIntegrationForm, ContentForm } from '@/components';
import { IntegrationEntityType } from '@/types';

const EditChallengePage = () => {
  const params = useParams();
  const id = params.id as string;
  const { data: challenge } = useGetChallengeByIdQuery(id);
  console.log(challenge);
  return <>
    <ApiIntegrationForm id={challenge?.id} entityType={IntegrationEntityType.Challenge} />
    <ContentForm id={challenge?.id} type="challenge" />
  </>;
};

export default EditChallengePage;
