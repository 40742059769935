import { useCallback, useMemo } from 'react';
import { useParams } from 'react-router-dom';

import { useEditSportMutation, useGetSportByIdQuery } from '@/apis/sports';
import { useToastMessageRequest } from '@/hooks';

import type { ISportFormParams } from '@/types';

export const useEditSportPage = () => {
  const params = useParams();
  const id = params.id as string;
  const { data: sport } = useGetSportByIdQuery(id);
  const [editSport, { isLoading, ...editSportParams }] = useEditSportMutation();

  useToastMessageRequest(editSportParams);

  const initialValues = useMemo(
    () => ({
      name: sport?.name ?? '',
      order: sport?.order ?? 0,
      isActive: sport?.isActive ?? false,
      seoText: sport?.seoText ?? '',
      slug: sport?.slug ?? '',
      source: sport?.source ?? '',
      externalId: sport?.externalId ?? '',
      seoForecastPage: sport?.seoForecastPage ?? '',
    }),
    [sport],
  );

  const onSubmit = useCallback(
    (params: ISportFormParams) => {
      editSport({ id, ...params });
    },
    [id],
  );

  return { sport, isLoading, initialValues, onSubmit };
};
