import { useNavigate } from 'react-router-dom';

import { useGetNewsQuery } from '@/apis/news';
import { useGetSportsForFilters, usePagination, useTableFilters } from '@/hooks';

import { getFilteringFields } from './constants';

export const useMainNewsPage = () => {
  const navigate = useNavigate();
  const paginationParams = usePagination();
  const { page, rowsPerPage: limit } = paginationParams;
  const sports = useGetSportsForFilters();
  const filteringFields = getFilteringFields(sports);

  const [filters, onFiltersChange] = useTableFilters(filteringFields);

  const { data: news, isLoading } = useGetNewsQuery({
    limit,
    page: page + 1,
    ...filters,
  });

  const count = (news?.meta.totalPages ?? 1) * limit;

  const noData = !news?.items.length;

  const goToCreatePage = () => {
    navigate('create');
  };

  return {
    filteringFields,
    isLoading,
    noData,
    count,
    news,
    goToCreatePage,
    onFiltersChange,
    ...paginationParams,
  };
};
