import { useState, useMemo, useEffect } from "react";

import {useCreateContentMutation, useEditContentMutation, useGetContentListQuery} from "@/apis/content";
import { useGetLanguagesQuery } from "@/apis/languages";
import { TOURNAMENT_TYPES } from "@/components/forms/ContentForm/constants";
import { useToastMessageRequest } from "@/hooks";
import {IContentFormParams, IContentFormValues, IContentParams} from "@/types";

export const useContentForm = ({ id, type }: IContentFormParams) => {

  const [currentLanguageId, setCurrentLanguageId] = useState<number>(1);

  console.log(type, id, TOURNAMENT_TYPES.CHALLENGE)

  const { data } = useGetContentListQuery({
    leagueId: type === TOURNAMENT_TYPES.LEAGUE ? id : undefined,
    challengeId: type === TOURNAMENT_TYPES.CHALLENGE ? id : undefined,
    languageId: currentLanguageId,
    page: 1,
    limit: 100,
  }, {
    skip: !id
  });

  const { data: languages } = useGetLanguagesQuery({ limit: 10, page: 1 });

  const [createContent, {isLoading: createContentLoading, ...createRequestParams}] = useCreateContentMutation();
  const [editContent, {isLoading: editContentLoading, ...editContentParams}] = useEditContentMutation();

  useToastMessageRequest(createRequestParams);

  useToastMessageRequest(editContentParams);

  const initialValues = useMemo(() => {
    if((data?.items.length || 0) > 0) {
      return {
        content: data?.items[0].content,
        languageId: data?.items[0]?.language?.id,
      }
    }
    return {
      content: '',
      languageId: currentLanguageId,
    }
  }, [data?.items, currentLanguageId]);

  useEffect(() => {
    if((data?.items.length || 0) > 0) {
      setCurrentLanguageId(data?.items[0]?.language?.id ?? 1)
    }
  }, [data?.items]);

  const handleSubmit = (values: IContentFormValues): void => {
    const commonData = {
      content: values.content,
      languageId: values.languageId,
      leagueId: type === TOURNAMENT_TYPES.LEAGUE ? id : undefined,
      challengeId: type === TOURNAMENT_TYPES.CHALLENGE ? id : undefined,
    } as IContentParams;
      if(data?.items[0]?.id) {
        editContent({
          id: data?.items[0]?.id,
          ...commonData,
        })
      } else {
        createContent({
          ...commonData,
        })
      }
  }

  return {
    createContentLoading,
    editContentLoading,
    languages,
    initialValues,
    setCurrentLanguageId,
    handleSubmit
  }
}
