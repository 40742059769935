import { BonusType } from '@/types';

export const defaultInitialValues = {
  title: '',
  link: '',
  text: '',
  bookmakerId: '',
  createdDate: '',
  endDate: '',
  imageUrl: '',
  seoDescription: '',
  type: BonusType.FREEBET,
};
