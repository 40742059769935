import { Edit } from '@mui/icons-material';
import { IconButton, TableCell, TableRow } from '@mui/material';
import React from 'react';
import { useNavigate } from 'react-router-dom';

import type { ISectionResponse } from '@/types';

const SectionTableItem: React.FC<ISectionResponse> = (section) => {
  const { id, name } = section;
  const navigate = useNavigate();

  const onEdit = (id: number) => () => {
    navigate(`edit/${id}`);
  };

  return (
    <TableRow>
      <TableCell component="th" scope="row">
        {id}
      </TableCell>
      <TableCell component="th" scope="row">
        {name}
      </TableCell>
      <TableCell component="th" scope="row">
        <IconButton onClick={onEdit(id)}>
          <Edit />
        </IconButton>
      </TableCell>
    </TableRow>
  );
};

export default SectionTableItem;
