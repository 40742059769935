import { useCallback, useMemo } from 'react';
import { useParams } from 'react-router-dom';

import {
  useEditBookmakerBonusMutation,
  useGetBookmakerBonusByIdQuery,
} from '@/apis/bookmaker-bonuses';
import { useToastMessageRequest } from '@/hooks';
import { BonusType } from '@/types';

import type { IBookmakerBonusFormParams } from '@/types';

export const useEditBookmakerBonusPage = () => {
  const params = useParams();
  const id = params.id as string;
  const { data: bonus } = useGetBookmakerBonusByIdQuery(id);
  const [editBonus, { isLoading, ...editBonusParams }] = useEditBookmakerBonusMutation();

  useToastMessageRequest(editBonusParams);

  const initialValues = useMemo(
    () => ({
      title: bonus?.title ?? '',
      imageUrl: bonus?.imageUrl ?? '',
      text: bonus?.text ?? '',
      createdDate: bonus?.createdDate ?? '',
      endDate: bonus?.endDate ?? '',
      bookmakerId: bonus?.bookmakerId ?? '1',
      link: bonus?.link ?? '',
      seoDescription: bonus?.seoDescription ?? '',
      type: bonus?.type ?? BonusType.FREEBET,
    }),
    [bonus],
  );

  const onSubmit = useCallback(
    (params: IBookmakerBonusFormParams) => {
      editBonus({ id: bonus?.id ?? '', ...params });
    },
    [bonus],
  );
  return { isLoading, initialValues, onSubmit };
};
