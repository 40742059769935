import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/dist/query/react';

import { ApiRoutes } from '@/constants';
import { setAccessTokenToHeaders } from '@/helpers';
import { envUtil } from '@/utils';

import type { IEventFormParams, IEventsResponse, IRequestParams, IResponse } from '@/types';

const { api } = envUtil.getEnv();

export const eventsApi = createApi({
  reducerPath: 'eventsApi',
  baseQuery: fetchBaseQuery({
    baseUrl: api,
    prepareHeaders: (headers) => setAccessTokenToHeaders(headers),
  }),
  tagTypes: ['Events', 'Event'],
  endpoints: (builder) => ({
    getEvents: builder.query<
      IResponse<IEventsResponse>,
      IRequestParams & { isLeagueFsSyncOn: boolean; isShowSyncFails: boolean }
    >({
      query: (params) => ({
        url: ApiRoutes.AdminEvents,
        params,
      }),
      providesTags: (result) =>
        result
          ? [
              ...result.items.map(({ id }) => ({
                type: 'Events' as const,
                id,
              })),
              { type: 'Events', id: 'LIST' },
            ]
          : [{ type: 'Events', id: 'LIST' }],
      keepUnusedDataFor: 1,
    }),
    getEventById: builder.query<IEventsResponse, string>({
      query: (id) => ({
        url: `${ApiRoutes.AdminEvents}/${id}`,
      }),
      providesTags: () => ['Event'],
    }),
    editEvent: builder.mutation<IEventsResponse, IEventFormParams & { id: number }>({
      query: ({ id, ...body }) => ({
        url: `${ApiRoutes.AdminEvents}/${id}`,
        body,
        method: 'PUT',
      }),
      invalidatesTags: ['Event'],
    }),
  }),
});

export const { useGetEventsQuery, useGetEventByIdQuery, useEditEventMutation } = eventsApi;
