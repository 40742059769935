export enum ApiRoutes {
  Auth = 'auth',
  Bookmakers = 'bookmakers',
  News = 'news',
  Challenges = 'challenges',
  AdminEvents = 'admin/events',
  Events = 'events',
  Managers = 'managers',
  Venues = 'venues',
  Sections = 'sections',
  Seasons = 'seasons',
  Articles = 'articles',
  ArticleSections = 'article-sections',
  Sports = 'sports',
  BookmakerBonuses = 'bookmaker-bonuses',
  BookmakerReviews = 'bookmaker-reviews',
  ApiIntegrations = 'admin/integrations',
  Languages = 'languages',
  Leagues = 'leagues',
  WebsiteSettings = 'website-settings',
  Players = 'players',
  Teams = 'teams',
  Users = 'admin/users',
  Images = 'images',
  Logs = 'logs',
  Crons = 'crons',
  Content = 'content',
  Forecasts = 'forecasts',
  Experts = 'experts',
}
