import { Edit } from '@mui/icons-material';
import { IconButton, TableCell, TableRow } from '@mui/material';
import React from 'react';

import { useTableItem } from '@/hooks';

import type { IChallengeResponse } from '@/types';

const ChallengeTableItem: React.FC<IChallengeResponse> = (challange) => {
  const { id, name } = challange;
  const { onEdit } = useTableItem();
  return (
    <TableRow>
      <TableCell component="th" scope="row">
        {id}
      </TableCell>
      <TableCell component="th" scope="row">
        {name}
      </TableCell>
      <TableCell component="th" scope="row">
        {name}
      </TableCell>
      <TableCell component="th" scope="row">
        <IconButton onClick={onEdit(id)}>
          <Edit />
        </IconButton>
      </TableCell>
    </TableRow>
  );
};

export default ChallengeTableItem;
