import { EventStatusEnum } from '@/constants';
import { FilterTypes } from '@/types';

import type { FilterValues } from '@/types';

const matchStatuses = [
  {
    label: 'Finished',
    value: EventStatusEnum.FINISHED,
  },
  {
    label: 'Postponed',
    value: EventStatusEnum.POST_PONED,
  },
  {
    label: 'In progress',
    value: EventStatusEnum.IN_PROGRESS,
  },
  {
    label: 'Not started',
    value: EventStatusEnum.NOT_STARTED,
  },
  {
    label: 'Interrupted',
    value: EventStatusEnum.INTERRUPTED,
  },
  {
    label: 'Canceled',
    value: EventStatusEnum.CANCELED,
  },
  {
    label: 'Delayed',
    value: EventStatusEnum.DELAYED,
  },
  {
    label: 'Suspendet',
    value: EventStatusEnum.SUSPENDET,
  },
  {
    label: 'Will continue',
    value: EventStatusEnum.WILL_CONTINUE,
  },
  {
    label: 'FT',
    value: EventStatusEnum.FT,
  },
  {
    label: 'FRO',
    value: EventStatusEnum.FRO,
  },
  {
    label: 'NONE',
    value: EventStatusEnum.NONE,
  },
];

const booleanFilters = [
  {
    label: 'True',
    value: true,
  },
  {
    label: 'False',
    value: false,
  },
];

export const headCells = [
  { title: 'ID', width: 150 },
  { title: 'External Id', width: 100 },
  { title: 'Fs External Id', width: 150 },
  { title: 'Event Name', width: 250 },
  { title: 'Status', width: 150 },
  { title: 'Home Team', width: 150 },
  { title: 'Home External Id', width: 150 },
  { title: 'Home Score', width: 100 },
  { title: '', width: 70 },
  { title: '', width: 70 },
  { title: 'Away Score', width: 100 },
  { title: 'Away External Id', width: 150 },
  { title: 'Away Team', width: 150 },
  { title: 'Sport', width: 150 },
  { title: 'League', width: 200 },
  { title: 'Date', width: 150 },
  { title: '', width: 50 },
];

export const getFilteringFields = (sports: FilterValues[]) => [
  { name: 'eventId', type: FilterTypes.Text },
  null,
  { name: 'fsExternalId', type: FilterTypes.Text },
  { name: 'eventName', type: FilterTypes.Text },
  {
    name: 'eventStatus',
    type: FilterTypes.Select,
    values: matchStatuses,
    defaultValue: '',
  },
  null,
  { name: 'hTfsExternalId', type: FilterTypes.Text },
  null,
  null,
  null,
  null,
  { name: 'aTfsExternalId', type: FilterTypes.Text },
  null,
  { name: 'sportName', type: FilterTypes.Select, values: sports, defaultValue: 'Football' },
  null,
  { name: 'date', type: FilterTypes.Date },
  null,
];
