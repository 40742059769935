import React from 'react';

import { ChallengeTableItem, CustomTable } from '@/components';

import { headCells } from './constants';
import { useMainChallengePage } from './useMainChallengePage';

const MainChallengePage = () => {
  const { challenges, ...tableProps } = useMainChallengePage();
  return (
    <>
      <CustomTable headCells={headCells} {...tableProps}>
        {challenges?.items.map((challenge) => (
          <ChallengeTableItem {...challenge} key={challenge.id} />
        ))}
      </CustomTable>
    </>
  );
};

export default MainChallengePage;
