import { useCallback, useEffect, useState } from 'react';
import { debounce } from 'throttle-debounce';

import { isObjectEmpty } from '@/utils';

import type { FiltersChangeFunc, FiltersType } from '@/types';

type ObjectType = { [key: string]: string };

export const useTableFilters = (
  initialValues: FiltersType = [],
): [ObjectType, FiltersChangeFunc] => {
  const [filters, setFilters] = useState({});

  useEffect(() => {
    if (!isObjectEmpty(filters)) return;
    const obj: ObjectType = {};
    initialValues.forEach((value) => {
      if (value) {
        const { name, defaultValue } = value;
        obj[name] = defaultValue ?? '';
      }
    });
    setFilters(obj);
  }, [initialValues]);

  const onChange: FiltersChangeFunc = useCallback((key, value) => {
    debounce(300, () => {
      setFilters((prev) => ({ ...prev, [key]: value }));
    })();
  }, []);
  return [filters, onChange];
};

export default useTableFilters;
