import { useCallback, useMemo } from 'react';
import { useParams } from 'react-router-dom';

import { useEditEventMutation, useGetEventByIdQuery } from '@/apis/events';
import { useToastMessageRequest } from '@/hooks';

import type { IEventFormParams } from '@/types';

export const useEditEventPage = () => {
  const params = useParams();
  const id = params.id as string;
  const { data: event } = useGetEventByIdQuery(id);
  const [editEvent, { isLoading, ...editArticleParams }] = useEditEventMutation();

  useToastMessageRequest(editArticleParams);

  const initialValues = useMemo(
    () => ({
      rating: event?.rating ?? 0,
      fsExternalId: event?.fsExternalId ?? null,
    }),
    [event],
  );

  const onSubmit = useCallback(
    (params: IEventFormParams) => {
      editEvent({ id: event?.id ?? 0, ...params });
    },
    [id, event],
  );
  return { event, isLoading, initialValues, onSubmit };
};
