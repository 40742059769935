import React from 'react';

import { CustomTable, DeleteTeamModal, TeamTableItem } from '@/components';

import { headCells } from './constants';
import { useMainTeamsPage } from './useMainTeamsPage';

const MainTeamsPage = () => {
  const { teams, ...tableProps } = useMainTeamsPage();

  return (
    <>
      <CustomTable headCells={headCells} deletingModal={DeleteTeamModal} {...tableProps}>
        {teams?.items.map((team) => (
          <TeamTableItem {...team} key={team.id} />
        ))}
      </CustomTable>
    </>
  );
};

export default MainTeamsPage;
