export enum Pathes {
  Main = '/',
  SignIn = '/sign-in',
  Users = '/users',
  Managers = '/managers',
  Venues = '/venues',
  Seasons = '/seasons',
  Experts = '/experts',
  Sections = '/sections',
  Challenges = '/challenges',
  Settings = '/settings',
  Bookmakers = '/bookmakers',
  Leagues = '/leagues',
  BookmakerBonuses = '/bookmaker-bonuses',
  BookmakerReviews = '/bookmaker-reviews',
  Teams = '/teams',
  Players = '/players',
  Sports = '/sports',
  ArticlesSections = '/articles-sections',
  Articles = '/articles',
  News = '/news',
  Events = '/events',
  Logs = '/logs',
  Crons = '/crons',
  Predictions = '/predictions',
}
