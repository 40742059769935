export const defaultInitialValues = {
  title: '',
  content: '',
  isPublished: false,
  sportId: 0,
  league: {
    name: '',
    id: 0,
  },
};
