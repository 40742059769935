import { Add } from '@mui/icons-material';
import { Button, Typography } from '@mui/material';
import React from 'react';

import { CustomTable, DeleteNewsModal, NewsTableItem } from '@/components';

import { headCells } from './constants';
import { useMainNewsPage } from './useMainNewsPage';

const MainNewsPage = () => {
  const { news, goToCreatePage, ...tableProps } = useMainNewsPage();
  return (
    <>
      <Button variant="contained">
        <Add />
        <Typography ml={2} fontWeight="bold" onClick={goToCreatePage}>
          Create News
        </Typography>
      </Button>
      <CustomTable headCells={headCells} deletingModal={DeleteNewsModal} {...tableProps}>
        {news?.items.map((news) => (
          <NewsTableItem {...news} key={news.id} />
        ))}
      </CustomTable>
    </>
  );
};

export default MainNewsPage;
