import { RichTextEditorEntityTypes } from '@/constants';

export interface EntityTypeObj {
  data: {
    width: string;
    height: string;
    src: string;
    alt: string;
    url: string;
    href: string;
  };
  type: RichTextEditorEntityTypes;
}

export const getEntityToHTML = (entity: EntityTypeObj, originalText: string) => {
  const { width, height } = entity.data;

  if (entity.type === RichTextEditorEntityTypes.Image) {
    const { src, alt } = entity.data;
    return `<img src="${src ?? '/'}" height="${height ?? 'auto'}" width="${
      width === 'auto' ? '100%' : width
    }" alt="${alt ?? '*'}"/>`;
  } else if (entity.type === RichTextEditorEntityTypes.EmbeddedLink) {
    const url = entity?.data?.src ? entity.data.src : entity.data.href;
    return `<iframe src="${url}" width="${width ?? 'auto'}" height="${height ?? 'auto'}"></iframe>`;
  } else if (entity.type === RichTextEditorEntityTypes.Link) {
    return `<a href="${entity?.data?.url}" width="${width ?? 'auto'}" height="${
      height ?? 'auto'
    }">${originalText}</a>`;
  }
  return originalText;
};
