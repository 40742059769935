import { useNavigate } from 'react-router-dom';

import { useGetBookmakersListQuery } from '@/apis/bookmakers';
import { usePagination, useTableFilters } from '@/hooks';

import { filteringFields } from './constants';

export const useMainBookmakersPage = () => {
  const navigate = useNavigate();
  const paginationParams = usePagination();
  const { page, rowsPerPage: limit } = paginationParams;
  const [filters, onFiltersChange] = useTableFilters(filteringFields);
  const { data: bookmakers, isLoading } = useGetBookmakersListQuery({
    limit,
    page: page + 1,
    ...filters,
  });
  const count = (bookmakers?.meta.totalPages ?? 1) * limit;

  const noData = !bookmakers?.items.length;

  const goToCreateBookmakerPage = () => {
    navigate('create');
  };

  return {
    isLoading,
    noData,
    count,
    bookmakers,
    onFiltersChange,
    goToCreateBookmakerPage,
    ...paginationParams,
  };
};
