import { Container, Grid, Typography } from '@mui/material';
import React from 'react';

import {CronForm} from "@/components";
import { useViewCronPage } from "@/views/crons/ViewCronPage/useViewCronPage";

import { styles } from './styles';

const GET_SPORTS = 'GET_SPORTS';

export const ViewCronPage = () => {

  const { cron } = useViewCronPage();

  return (
    <Container>
      <Typography variant="h4" my={2}>
        Cron #{cron?.id} {cron?.title}
      </Typography>
      <Grid container spacing={2} sx={styles.container}>
        <Grid item xs>
          <CronForm id={cron?.id} config={cron?.filters} withSport={cron?.name !== GET_SPORTS} />
        </Grid>
      </Grid>
    </Container>
  )
}
