import React from 'react';

import { CustomTable, DeleteLeagueModal, LeagueTableItem } from '@/components';

import { headCells } from './constants';
import { useMainLeaguesPage } from './useMainLeaguesPage';

const MainLeaguesPage: React.FC = () => {
  const { leagues, ...tableProps } = useMainLeaguesPage();
  return (
    <>
      <CustomTable headCells={headCells} deletingModal={DeleteLeagueModal} {...tableProps}>
        {leagues?.items.map((league) => (
          <LeagueTableItem {...league} key={league.id} />
        ))}
      </CustomTable>
    </>
  );
};

export default MainLeaguesPage;
