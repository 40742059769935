import {useCallback, useEffect} from 'react';
import {useNavigate} from "react-router-dom";

import { useCreateBookmakerReviewMutation } from '@/apis/bookmaeker-reviews';
import {Pathes} from "@/constants";
import { useToastMessageRequest } from '@/hooks';

import type { IBookmakerReviewFormParams } from '@/types';

export const useCreateBookmakerReviewPage = () => {
  const [createReview, { isLoading, isSuccess, ...requestParams }] = useCreateBookmakerReviewMutation();

  useToastMessageRequest({ isSuccess, ...requestParams });

  const navigate = useNavigate();

  useEffect(() => {
    if (isSuccess) {
      navigate(Pathes.BookmakerReviews);
    }
  }, [isSuccess]);

  const onSubmit = useCallback((params: IBookmakerReviewFormParams) => {
    createReview(params);
  }, []);

  return { isLoading, onSubmit };
};
