import { Delete, Edit } from '@mui/icons-material';
import {Box, IconButton, TableCell, TableRow, Typography} from '@mui/material';
import React from 'react';

import { useTableItem } from '@/hooks';
import { openDeleteTeamModal } from '@/redux/teams/slice';

import type { ITeamResponse } from '@/types';

const TeamTableItem: React.FC<ITeamResponse> = (team) => {
  const { id, externalId, fsExternalId, name, rating, sport, leagues, country, logo, gender } = team;

  const { onEdit, onDelete } = useTableItem(openDeleteTeamModal);

  return (
    <TableRow>
      <TableCell>{id}</TableCell>
      <TableCell>{externalId}</TableCell>
      <TableCell>{fsExternalId}</TableCell>
      <TableCell>{name}</TableCell>
      <TableCell>{gender}</TableCell>
      <TableCell>{rating}</TableCell>
      <TableCell>{sport?.name ?? ''}</TableCell>
      <TableCell>
        {leagues.map((item) => <Typography key={item} sx={{
          fontSize: '14px',
          fontWeight: '600',
          margin: '2px 0'
        }}>{item}</Typography>)}
      </TableCell>
      <TableCell>{country}</TableCell>
      <TableCell>
        <Box component="img" src={logo as string} width={30} height={30} />
      </TableCell>
      <TableCell width={100}>
        <IconButton onClick={onEdit(id)}>
          <Edit />
        </IconButton>
        <IconButton onClick={onDelete(team)}>
          <Delete />
        </IconButton>
      </TableCell>
    </TableRow>
  );
};

export default TeamTableItem;
