import React from 'react';

import { DeleteConfirmationDialog } from '@/components';

import { useDeleteBookmakerBonusModal } from './useDeleteBookmakerBonusModal';

const DeleteBookmakerBonusModal = () => {
  const { bonus, isOpen, closeModal, confirmDeleting } = useDeleteBookmakerBonusModal();
  return (
    <DeleteConfirmationDialog
      open={isOpen}
      onClose={closeModal}
      onConfirm={confirmDeleting}
      text={`Are you sure you want to delete ${bonus?.title ?? ''} bookmaker bonus?`}
    />
  );
};

export default DeleteBookmakerBonusModal;
