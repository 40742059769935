import { FormControl, FormHelperText, Paper, Typography } from '@mui/material';
import React from 'react';
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

import { IRichTextEditorInputProps } from './types';
import { useRichTextEditorInput } from './useRichTextEditorInput';

const RichTextEditorInput = (props: IRichTextEditorInputProps) => {
  const { toolbar, editorState, hasError, handleEditorChange, handleKeyCommand } = useRichTextEditorInput(props);

  return (
    <FormControl error={hasError}>
      <Typography variant="h6" mb={1}>
        {props?.placeholder}
      </Typography>
      <Paper
        sx={{
          p: 2,
        }}
      >
        <Editor
          handlePastedText={() => false}
          handleKeyCommand={handleKeyCommand}
          toolbar={toolbar}
          placeholder={props?.placeholder}
          editorState={editorState}
          onEditorStateChange={handleEditorChange}
          toolbarStyle={{
            position: 'sticky',
            maxWidth: '100%',
            top: '64px',
            zIndex: 10,
          }}
        />
      </Paper>
      <FormHelperText>{hasError && props.form.errors[props.field.name]}</FormHelperText>
    </FormControl>
  );
};

export default RichTextEditorInput;
