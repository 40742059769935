import React from 'react';

import { CustomTable, SectionTableItem } from '@/components';

import { headCells } from './constants';
import { useMainSectionsPage } from './useMainSectionsPage';

const MainSectionsPage = () => {
  const { sections, ...tableProps } = useMainSectionsPage();
  return (
    <>
      <CustomTable headCells={headCells} {...tableProps} hideFilters>
        {sections?.items.map((section) => (
          <SectionTableItem {...section} key={section.id} />
        ))}
      </CustomTable>
    </>
  );
};

export default MainSectionsPage;
