import React from 'react';

import { ArticleSectionForm } from '@/components';

import { useEditArticlesSectionsPage } from './useEditArticlesSectionsPage';

const EditArticlesSectionsPage = () => {
  const props = useEditArticlesSectionsPage();
  return <ArticleSectionForm withTranslation {...props} />;
};

export default EditArticlesSectionsPage;
