import {
  useEditLeagueMutation,
  useGetDefaultLeagueQuery,
  useGetLeaguesQuery,
} from '@/apis/leagues';
import { useToastMessageRequest } from '@/hooks';

export const useDefaultLeagueForm = () => {
  const { data: leagues } = useGetLeaguesQuery({ page: 1, limit: 10 });
  const { data: defaultLeague } = useGetDefaultLeagueQuery();
  const [editLeague, requestParams] = useEditLeagueMutation();

  useToastMessageRequest(requestParams);

  const onSubmit = ({ id }: { id: number }) => {
    editLeague({
      id,
      isDefault: true,
    });
  };

  return { leagues, defaultLeague, onSubmit };
};
