import React from 'react';

import { DeleteConfirmationDialog } from '@/components';

import { useDeleteBookmakerModal } from './useDeleteBookmakerModal';

const DeleteBookmakerModal = () => {
  const { bookmaker, isOpen, closeModal, confirmDeleting } = useDeleteBookmakerModal();
  return (
    <DeleteConfirmationDialog
      open={isOpen}
      onClose={closeModal}
      onConfirm={confirmDeleting}
      text={`Are you sure you want to delete ${bookmaker?.name ?? ''} bookmaker?`}
    />
  );
};

export default DeleteBookmakerModal;
