import React from 'react';

import { DeleteConfirmationDialog } from '@/components';

import { useDeleteLeagueModal } from './useDeleteLeagueModal';

const DeleteBookmakerModal: React.FC = () => {
  const { isOpen, league, closeModal, confirmDeleting } = useDeleteLeagueModal();
  return (
    <DeleteConfirmationDialog
      open={isOpen}
      onClose={closeModal}
      onConfirm={confirmDeleting}
      text={`Are you sure you want to delete ${league?.name ?? ''} league?`}
    />
  );
};

export default DeleteBookmakerModal;
