import { useGetSectionsQuery } from '@/apis/sections';
import { usePagination } from '@/hooks';

export const useMainSectionsPage = () => {
  const paginationParams = usePagination();
  const { page, rowsPerPage: limit } = paginationParams;
  const { data: sections, isLoading } = useGetSectionsQuery({ page: page + 1, limit });

  const count = (sections?.meta.totalPages ?? 1) * limit;

  const noData = !sections?.items.length;

  return { count, noData, isLoading, sections, ...paginationParams };
};
