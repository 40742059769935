import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/dist/query/react';

import { ApiRoutes } from '@/constants';
import { setAccessTokenToHeaders } from '@/helpers';
import { IRequestParams, IResponse, IContentResponse, IContentParams } from '@/types';
import { envUtil } from '@/utils';

const { api } = envUtil.getEnv();

export const contentApi = createApi({
  reducerPath: 'contentApi',
  baseQuery: fetchBaseQuery({
    baseUrl: api,
    prepareHeaders: (headers) => setAccessTokenToHeaders(headers),
  }),
  tagTypes: ['ContentList', 'ContentItem'],
  endpoints: (builder) => ({
    getContentList: builder.query<
      IResponse<IContentResponse>,
      IRequestParams & {
      leagueId?: number;
      challengeId?: number;
      languageId?: number;
    }
      >({
      query: (params) => ({
        url: ApiRoutes.Content,
        params,
      }),
      providesTags: (result) =>
        result
          ? [
            ...result.items.map(({ id }) => ({
              type: 'ContentList' as const,
              id,
            })),
            { type: 'ContentList', id: 'LIST' },
          ]
          : [{ type: 'ContentList', id: 'LIST' }],
      keepUnusedDataFor: 1,
    }),
    createContent: builder.mutation<IContentResponse, IContentParams>({
      query: (body) => ({
        url: ApiRoutes.Content,
        body,
        method: 'POST',
      }),
    }),
    editContent: builder.mutation<IContentResponse, IContentParams & { id: number }>({
      query: ({ id, ...body }) => ({
        url: `${ApiRoutes.Content}/${id}`,
        body,
        method: 'PUT',
      }),
    }),
  }),
});

export const { useGetContentListQuery, useCreateContentMutation, useEditContentMutation } = contentApi;
