import { useGetManagersQuery } from '@/apis/managers';
import { usePagination } from '@/hooks';

export const useMainManagersPage = () => {
  const paginationParams = usePagination();
  const { page, rowsPerPage: limit } = paginationParams;
  const { data: managers, isLoading } = useGetManagersQuery({ page: page + 1, limit });

  const count = (managers?.meta.totalPages ?? 1) * limit;

  const noData = !managers?.items.length;

  return {
    isLoading,
    noData,
    count,
    managers,
    ...paginationParams,
  };
};
