import React from 'react';

import { BookmakerForm } from '@/components';

import { useEditBookmakerPage } from './useEditBookmakerPage';

const EditBookmakerPage = () => {
  const props = useEditBookmakerPage();
  return <BookmakerForm {...props} />;
};

export default EditBookmakerPage;
