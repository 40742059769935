import { Delete, Edit } from '@mui/icons-material';
import { IconButton, TableCell, TableRow } from '@mui/material';
import React from 'react';

import { useTableItem } from '@/hooks';
import { openDeleteNewsModal } from '@/redux/news/slice';
import { truncateString } from '@/utils';

import type { INewsResponse } from '@/types';

const NewsTableItem: React.FC<INewsResponse> = (news) => {
  const { id, title, sport, author } = news;
  const { onEdit, onDelete } = useTableItem(openDeleteNewsModal);
  return (
    <TableRow>
      <TableCell component="th" scope="row">
        {id}
      </TableCell>
      <TableCell component="th" scope="row">
        {truncateString(title)}
      </TableCell>
      <TableCell component="th" scope="row">
        {sport?.name ?? ''}
      </TableCell>
      <TableCell component="th" scope="row">
        {author?.firstName ?? ''}
      </TableCell>
      <TableCell component="th" scope="row">
        <IconButton onClick={onEdit(id)}>
          <Edit />
        </IconButton>
        <IconButton onClick={onDelete(news)}>
          <Delete />
        </IconButton>
      </TableCell>
    </TableRow>
  );
};

export default NewsTableItem;
