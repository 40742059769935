import { Delete, Edit } from '@mui/icons-material';
import { IconButton, TableCell, TableRow } from '@mui/material';
import React from 'react';

import { useTableItem } from '@/hooks';
import { openDeleteArticleModal } from '@/redux/articles/slice';
import { truncateString } from '@/utils';

import type { IArticleResponse } from '@/types';

const ArticleTableItem: React.FC<IArticleResponse> = (article) => {
  const { id, slug, title, articleSection, sport } = article;
  const { onEdit, onDelete } = useTableItem(openDeleteArticleModal);
  return (
    <TableRow>
      <TableCell component="th" scope="row">
        {id}
      </TableCell>
      <TableCell component="th" scope="row">
        {truncateString(title)}
      </TableCell>
      <TableCell component="th" scope="row">
        {articleSection?.name ?? ''}
      </TableCell>
      <TableCell component="th" scope="row">
        {sport?.name ?? ''}
      </TableCell>
      <TableCell component="th" scope="row">
        <IconButton onClick={onEdit(slug)}>
          <Edit />
        </IconButton>
        <IconButton onClick={onDelete(article)}>
          <Delete />
        </IconButton>
      </TableCell>
    </TableRow>
  );
};

export default ArticleTableItem;
