import {
  Article,
  EmojiEvents,
  EmojiFlags,
  Event,
  Feed,
  NewReleases,
  Person,
  Scoreboard,
  Settings,
  Sports,
  SportsFootball,
  SportsTennis,
  WorkspacePremium,
  Description,
  Groups,
  SportsHandball,
  Repeat,
} from '@mui/icons-material';

import { Pathes } from './pathes';

export const navigationList = [
  {
    name: 'Users',
    path: Pathes.Users,
    icon: Person,
  },
  {
    name: 'Site settings',
    path: Pathes.Settings,
    icon: Settings,
  },
  {
    name: 'Challenges',
    path: Pathes.Challenges,
    icon: EmojiFlags,
  },
  {
    name: 'Seasons',
    path: Pathes.Seasons,
    icon: EmojiEvents,
  },
  {
    name: 'Venues',
    path: Pathes.Venues,
    icon: EmojiEvents,
  },
  {
    name: 'Managers',
    path: Pathes.Managers,
    icon: EmojiEvents,
  },
  {
    name: 'Sections',
    path: Pathes.Sections,
    icon: EmojiEvents,
  },
  {
    name: 'Bookmakers',
    path: Pathes.Bookmakers,
    icon: SportsTennis,
    children: [
      {
        name: 'Bookmakers',
        path: Pathes.Bookmakers,
        icon: SportsTennis,
      },
      {
        name: 'Bonuses',
        path: Pathes.BookmakerBonuses,
        icon: WorkspacePremium,
      },
      {
        name: 'Reviews',
        path: Pathes.BookmakerReviews,
        icon: Feed,
      },
    ],
  },
  {
    name: 'Leagues',
    path: Pathes.Leagues,
    icon: Scoreboard,
  },
  {
    name: 'Sports',
    path: Pathes.Sports,
    icon: Sports,
    children: [
      {
        name: 'Teams',
        path: Pathes.Teams,
        icon: Groups,
      },
      {
        name: 'Players',
        path: Pathes.Players,
        icon: SportsHandball,
      },
      {
        name: 'Sports',
        path: Pathes.Sports,
        icon: SportsFootball,
      },
      {
        name: 'Events',
        path: Pathes.Events,
        icon: Event,
      },
    ],
  },
  {
    name: 'Articles Sections',
    path: Pathes.ArticlesSections,
    icon: Article,
  },
  {
    name: 'Articles',
    path: Pathes.Articles,
    icon: Article,
  },
  {
    name: 'News',
    path: Pathes.News,
    icon: NewReleases,
  },
  {
    name: 'Logs',
    path: Pathes.Logs,
    icon: Description,
  },
  {
    name: 'Crons',
    path: Pathes.Crons,
    icon: Repeat,
  },
  {
    name: 'Predictions statistics',
    path: Pathes.Predictions,
    icon: Article,
  },
];
