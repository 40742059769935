import React from 'react';

import { CustomTable, VenueTableItem } from '@/components';

import { headCells } from './constants';
import { useMainSeasonsPage } from './useMainSeasonsPage';

const MainVenuesPage = () => {
  const { venues, ...tableProps } = useMainSeasonsPage();
  return (
    <>
      <CustomTable {...{ headCells, ...tableProps }} hideFilters>
        {venues?.items.map((venue) => (
          <VenueTableItem {...venue} key={venue.id} />
        ))}
      </CustomTable>
    </>
  );
};

export default MainVenuesPage;
