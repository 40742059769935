import { Button, DialogActions, Box, Typography, Grid } from '@mui/material';
import { Field, Form, Formik } from 'formik';
import React from 'react';

import { CheckboxInput, ImageInput, TextInput } from '@/components';

import { defaultInitialValues, acceptImageType } from './constants';
import { styles } from './styles';

import type { IFormProps, ILeagueFormParams } from '@/types';

const LeagueForm: React.FC<IFormProps<ILeagueFormParams>> = ({
  initialValues = defaultInitialValues,
  onSubmit,
  isLoading,
}) => {
  return (
    <Formik enableReinitialize initialValues={initialValues} onSubmit={onSubmit}>
      {({ dirty }) => (
        <Form>
          <Grid container spacing={2} sx={styles.container}>
            <Grid item xs>
              <Field
                type="number"
                name="order"
                autoComplete="order"
                fullWidth
                component={TextInput}
                placeholder="Order"
                InputProps={{ inputProps: { min: 0 } }}
              />
            </Grid>
            <Grid item xs>
              <Field
                type="number"
                name="rank"
                autoComplete="rank"
                fullWidth
                component={TextInput}
                placeholder="Rank"
                InputProps={{ inputProps: { min: 1 } }}
              />
            </Grid>
          </Grid>
          <Box sx={styles.wrapper}>
            <Field
              name="isFavorite"
              component={CheckboxInput}
              label={<Typography variant="h6">Favourite</Typography>}
            />
            <Field
              name="isActive"
              component={CheckboxInput}
              label={<Typography variant="h6">Active</Typography>}
            />
            <Field
              name="isLeagueFsSyncOn"
              component={CheckboxInput}
              label={<Typography variant="h6">isLeagueFsSyncOn</Typography>}
            />
          </Box>
          <Box sx={styles.wrapper}>
            <Field
              accept={acceptImageType}
              type="file"
              name="logo"
              component={ImageInput}
              placeholder="Logo"
            />
          </Box>
          <DialogActions>
            <Button
              variant="contained"
              type="submit"
              disabled={isLoading || !dirty}
              sx={styles.btn}
            >
              Save
            </Button>
          </DialogActions>
        </Form>
      )}
    </Formik>
  );
};

export default LeagueForm;
