import { MenuItem, Typography } from '@mui/material';
import { Field, Form, Formik } from 'formik';
import React from 'react';

import { SelectInput } from '@/components';

import { useDefaultLeagueForm } from './useDefaultLeagueForm';

import type { SelectChangeEvent } from '@mui/material';

const DefaultLeagueForm = () => {
  const { leagues, defaultLeague, onSubmit } = useDefaultLeagueForm();

  return (
    <Formik enableReinitialize initialValues={{ id: defaultLeague?.id ?? 0 }} onSubmit={onSubmit}>
      {({ handleChange, submitForm }) => (
        <Form>
          <Field
            name="id"
            autoComplete="id"
            fullWidth
            component={SelectInput}
            placeholder="League"
            onChange={(e: SelectChangeEvent<{ id: number }>) => {
              handleChange(e);
              submitForm();
            }}
          >
            <MenuItem value="" disabled>
              <Typography>League</Typography>
            </MenuItem>
            {leagues?.items.map(({ name, id }) => {
              return (
                <MenuItem value={id} key={name}>
                  <Typography>{name}</Typography>
                </MenuItem>
              );
            })}
          </Field>
        </Form>
      )}
    </Formik>
  );
};

export default DefaultLeagueForm;
