import { object } from 'yup';
import '@/constants/yupSettings';

import { number, text } from '@/constants';


export const contentFormSchema = object({
  content: text,
  languageId: number,
}).required();
