import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/dist/query/react';

import { ApiRoutes } from '@/constants';
import { setAccessTokenToHeaders } from '@/helpers';
import { envUtil } from '@/utils';

import type { IArticleFormParams, IArticleResponse, IRequestParams, IResponse } from '@/types';

const { api } = envUtil.getEnv();

export const articleApi = createApi({
  reducerPath: 'articleApi',
  baseQuery: fetchBaseQuery({
    baseUrl: api,
    prepareHeaders: (headers) => setAccessTokenToHeaders(headers),
  }),
  tagTypes: ['Articles', 'Article'],
  endpoints: (builder) => ({
    getArticles: builder.query<IResponse<IArticleResponse>, IRequestParams>({
      query: (params) => ({
        url: ApiRoutes.Articles,
        params,
      }),
      providesTags: (result) =>
        result
          ? [
              ...result.items.map(({ id }) => ({
                type: 'Articles' as const,
                id,
              })),
              { type: 'Articles', id: 'LIST' },
            ]
          : [{ type: 'Articles', id: 'LIST' }],
      keepUnusedDataFor: 1,
    }),
    getArticleById: builder.query<IArticleResponse, string>({
      query: (id) => ({
        url: `${ApiRoutes.Articles}/${id}`,
      }),
      providesTags: () => ['Article'],
    }),
    createArticle: builder.mutation<IArticleResponse, IArticleFormParams>({
      query: (body) => ({
        url: ApiRoutes.Articles,
        body,
        method: 'POST',
      }),
    }),
    editArticle: builder.mutation<IArticleResponse, IArticleFormParams & { id: number }>({
      query: ({ id, ...body }) => ({
        url: `${ApiRoutes.Articles}/${id}`,
        body,
        method: 'PUT',
      }),
      invalidatesTags: ['Article'],
    }),
    deleteArticle: builder.mutation<void, number>({
      query: (id) => ({
        url: `${ApiRoutes.Articles}/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: [{ type: 'Articles', id: 'LIST' }],
    }),
  }),
});

export const {
  useGetArticlesQuery,
  useCreateArticleMutation,
  useEditArticleMutation,
  useGetArticleByIdQuery,
  useDeleteArticleMutation,
} = articleApi;
