import { useParams } from 'react-router-dom';

import {useGetCronByIdQuery} from "@/apis/crons";

export const useViewCronPage = () => {
  const { id } = useParams();

  const { data: cron, isLoading } = useGetCronByIdQuery(Number(id));

  return { cron, isLoading }
}
