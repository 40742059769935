import { Container, Typography } from '@mui/material';
import React from 'react';

import { UserExpertForm, UserForm } from '@/components';

import { useEditUserPage } from './useEditUserPage';

const EditUserPage: React.FC = () => {
  const {
    isCreateExpertLoading,
    isEditExpertLoading,
    showExpertForm,
    initialUserValues,
    initialExpertValues,
    isEditUserLoading,
    onUserSubmit,
    onExpertSubmit,
  } = useEditUserPage();
  return (
    <Container>
      <Typography variant="h4" my={2}>
        User form
      </Typography>
      <UserForm
        initialValues={initialUserValues}
        isLoading={isEditUserLoading}
        onSubmit={onUserSubmit}
      />
      {showExpertForm && (
        <>
          <Typography variant="h4" my={2}>
            Expert form
          </Typography>
          <UserExpertForm
            isLoading={isEditExpertLoading || isCreateExpertLoading}
            initialValues={initialExpertValues}
            onSubmit={onExpertSubmit}
          />
        </>
      )}
    </Container>
  );
};

export default EditUserPage;
