import React from 'react';
import { useParams } from 'react-router-dom';

import { useGetVenueByIdQuery } from '@/apis/venues';
import { ApiIntegrationForm } from '@/components';
import { IntegrationEntityType } from '@/types';

const EditVenuePage = () => {
  const params = useParams();
  const id = params.id as string;
  const { data: venue } = useGetVenueByIdQuery(id);
  return <ApiIntegrationForm id={venue?.id} entityType={IntegrationEntityType.Venue} />;
};

export default EditVenuePage;
