import { Add } from '@mui/icons-material';
import { Button, Typography } from '@mui/material';
import React from 'react';

import { CustomTable, DeleteSportModal, SportTableItem } from '@/components';

import { headCells, filteringFields } from './constants';
import { useMainSportsPage } from './useMainSportsPage';

const MainSportsPage = () => {
  const { sports, goToCreatePage, ...tableProps } = useMainSportsPage();
  return (
    <>
      <Button variant="contained">
        <Add />
        <Typography ml={2} fontWeight="bold" onClick={goToCreatePage}>
          Create Sport
        </Typography>
      </Button>
      <CustomTable
        headCells={headCells}
        filteringFields={filteringFields}
        deletingModal={DeleteSportModal}
        {...tableProps}
      >
        {sports?.items.map((sport) => (
          <SportTableItem {...sport} key={sport.id} />
        ))}
      </CustomTable>

      <DeleteSportModal />
    </>
  );
};

export default MainSportsPage;
