import { object, string } from 'yup';

import { number, text, email } from '@/constants';

export const userFormSchema = object({
  firstName: text,
  login: text,
  role: text,
  rating: number.min(0),
  email,
  bannedTill: string().when('isUserBanned', {
    is: true,
    then: text,
  }),
}).required();
