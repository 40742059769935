import { Button, Grid, MenuItem, Typography, Box } from '@mui/material';
import { Field, Form, Formik } from 'formik';
import React from 'react';

import {
  CheckboxInput,
  DateTimeInput,
  EmailInput,
  ImageInput,
  PasswordInput,
  SelectInput,
  TextInput,
} from '@/components';
import { capitalize, getNextMonthDate, getNextWeekDate, getTomorrowDate } from '@/utils';

import { userRoles, defaultInitialValue } from './constants';
import { userFormSchema } from './schemas';
import { styles } from './styles';
import { useUserForm } from './useUserForm';

import type { IFormProps, IUserFormParams } from '@/types';

const UserForm: React.FC<IFormProps<IUserFormParams>> = ({
  isLoading,
  onSubmit,
  initialValues = defaultInitialValue,
}) => {
  const { banUserByDate } = useUserForm();
  return (
    <Formik
      enableReinitialize
      validationSchema={userFormSchema}
      initialValues={initialValues}
      onSubmit={onSubmit}
    >
      {({ values: { isUserBanned }, dirty, setFieldValue }) => (
        <Form>
          <Grid container spacing={2} sx={styles.container}>
            <Grid item xs>
              <Field
                type="text"
                name="firstName"
                autoComplete="firstName"
                fullWidth
                component={TextInput}
                placeholder="First name"
              />
            </Grid>
            <Grid item xs>
              <Field
                type="text"
                name="lastName"
                autoComplete="lastName"
                fullWidth
                component={TextInput}
                placeholder="Last name"
              />
            </Grid>
          </Grid>
          <Grid container spacing={2} sx={styles.container}>
            <Grid item xs>
              <Field
                name="email"
                autoComplete="email"
                fullWidth
                component={EmailInput}
                placeholder="Email"
              />
            </Grid>
            <Grid item xs>
              <Field
                name="password"
                autoComplete="current-password"
                fullWidth
                component={PasswordInput}
                placeholder="Password"
              />
            </Grid>
          </Grid>
          <Grid container spacing={2} sx={styles.container}>
            <Grid item xs>
              <Field
                type="text"
                name="login"
                autoComplete="login"
                fullWidth
                component={TextInput}
                placeholder="Nickname"
              />
            </Grid>
            <Grid item xs>
              <Field name="role" placeholder="Role" component={SelectInput} size="small">
                {userRoles.map((role) => (
                  <MenuItem value={role} key={role}>
                    <Typography>{capitalize(role)}</Typography>
                  </MenuItem>
                ))}
              </Field>
            </Grid>
          </Grid>
          <Grid container spacing={2} sx={styles.container}>
            <Grid item md={6} xs>
              <Field type="file" name="avatar" component={ImageInput} placeholder="Avatar" />
            </Grid>
            <Grid item md={6} xs>
              <Field
                type="number"
                name="rating"
                autoComplete="rating"
                fullWidth
                component={TextInput}
                placeholder="Rating"
                InputProps={{ inputProps: { min: 0 } }}
              />
              <Field
                name="disableNotification"
                component={CheckboxInput}
                label={<Typography variant="h6">Disable notification</Typography>}
              />
              <Field
                name="confirmed"
                component={CheckboxInput}
                label={<Typography variant="h6">Confirmed</Typography>}
              />
            </Grid>
          </Grid>
          <Grid container>
            <Grid item md={6} xs>
              <Field
                name="isUserBanned"
                component={CheckboxInput}
                label={<Typography variant="h6">Banned by date</Typography>}
              />
              <Field
                name="isIpBanned"
                component={CheckboxInput}
                label={<Typography variant="h6">Is IP banned</Typography>}
              />
            </Grid>
          </Grid>
          {isUserBanned && (
            <>
              <Grid container spacing={2} sx={styles.container}>
                <Grid item xs>
                  <Field name="bannedTill" placeholder="Banned till" component={DateTimeInput} />
                </Grid>
                <Grid item xs>
                  <Box mt={4} sx={styles.dateButtons}>
                    <Button
                      sx={styles.dateBtn}
                      variant="contained"
                      onClick={banUserByDate(getTomorrowDate, setFieldValue)}
                    >
                      Ban for 1 day
                    </Button>
                    <Button
                      sx={styles.dateBtn}
                      variant="contained"
                      onClick={banUserByDate(getNextWeekDate, setFieldValue)}
                    >
                      Ban for 1 week
                    </Button>
                    <Button
                      sx={styles.dateBtn}
                      variant="contained"
                      onClick={banUserByDate(getNextMonthDate, setFieldValue)}
                    >
                      Ban for 1 month
                    </Button>
                  </Box>
                </Grid>
              </Grid>
            </>
          )}
          <Box sx={styles.btnWrapper}>
            <Button
              variant="contained"
              type="submit"
              disabled={isLoading || !dirty}
              sx={styles.btn}
            >
              Save
            </Button>
          </Box>
        </Form>
      )}
    </Formik>
  );
};

export default UserForm;
