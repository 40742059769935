import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { useDeletePlayerMutation } from '@/apis/players';
import { useToastMessageRequest } from '@/hooks';
import { playersStateSelectors } from '@/redux/players';
import { closeDeletePlayerModal } from '@/redux/players/slice';

export const useDeletePlayerModal = () => {
  const dispatch = useDispatch();

  const isOpen = useSelector(playersStateSelectors.isDeletePlayerModalOpenSelector);
  const player = useSelector(playersStateSelectors.selectedPlayerForModalSelector);

  const [deletePlayer, { isSuccess, ...requestParams }] = useDeletePlayerMutation();

  useToastMessageRequest({ isSuccess, ...requestParams });

  const closeModal = () => {
    dispatch(closeDeletePlayerModal());
  };

  const confirmDeleting = () => {
    deletePlayer(player?.id ?? 0);
  };

  useEffect(() => {
    if (isSuccess) {
      closeModal();
    }
  }, [isSuccess]);

  return { isOpen, closeModal, confirmDeleting };
};
