import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

import { ApiRoutes } from '@/constants';
import { objToFormData, setAccessTokenToHeaders } from '@/helpers';
import { envUtil } from '@/utils';

import type {
  IUserFormParams,
  IRequestParams,
  IResponse,
  IUserExpertResponse,
  IUserResponse,
  IExpertFormParams,
  IUserRequestParams,
} from '@/types';

const { api } = envUtil.getEnv();

export const usersApi = createApi({
  reducerPath: 'usersApi',
  baseQuery: fetchBaseQuery({
    baseUrl: api,
    prepareHeaders: (headers) => setAccessTokenToHeaders(headers),
  }),
  tagTypes: ['Users', 'User'],
  endpoints: (builder) => ({
    getUsers: builder.query<IResponse<IUserResponse>, IRequestParams & IUserRequestParams>({
      query: (params) => ({
        url: ApiRoutes.Users,
        params,
      }),
      providesTags: (result) =>
        result
          ? [
              ...result.items.map(({ id }) => ({
                type: 'Users' as const,
                id,
              })),
              { type: 'Users', id: 'LIST' },
            ]
          : [{ type: 'Users', id: 'LIST' }],
      keepUnusedDataFor: 1,
    }),
    getUserById: builder.query<IUserResponse, string>({
      query: (id) => ({
        url: `${ApiRoutes.Users}/${id}`,
      }),
      providesTags: () => ['User'],
    }),
    editUser: builder.mutation<IUserResponse, IUserFormParams & { id: string }>({
      query: ({ id, ...body }) => ({
        url: `${ApiRoutes.Users}/${id}`,
        body: objToFormData<IUserFormParams>(body),
        method: 'PUT',
      }),
      invalidatesTags: ['User'],
    }),
    deleteUser: builder.mutation<void, string>({
      query: (id) => ({
        url: `${ApiRoutes.Users}/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: [{ type: 'Users', id: 'LIST' }],
    }),
    createExpertUser: builder.mutation<IUserExpertResponse, IExpertFormParams & { id: string }>({
      query: ({ id, ...body }) => ({
        url: `${ApiRoutes.Users}/${id}/expert`,
        body,
        method: 'POST',
      }),
      invalidatesTags: ['User'],
    }),
    editExpertUser: builder.mutation<IUserExpertResponse, IExpertFormParams & { id: string }>({
      query: ({ id, ...body }) => ({
        url: `${ApiRoutes.Users}/${id}/expert`,
        body,
        method: 'PUT',
      }),
      invalidatesTags: ['User'],
    }),
    createUser: builder.mutation<IUserResponse, IUserFormParams>({
      query: (body) => ({
        url: ApiRoutes.Users,
        body: objToFormData<IUserFormParams>(body),
        method: 'POST',
      }),
    }),
  }),
});

export const {
  useGetUsersQuery,
  useDeleteUserMutation,
  useGetUserByIdQuery,
  useEditUserMutation,
  useEditExpertUserMutation,
  useCreateExpertUserMutation,
  useCreateUserMutation,
} = usersApi;
