import { Delete, Edit } from '@mui/icons-material';
import { IconButton, TableCell, TableRow } from '@mui/material';
import React from 'react';

import { useTableItem } from '@/hooks';
import { openDeleteSportModal } from '@/redux/sports/slice';

import type { ISportResponse } from '@/types';

const SportTableItem: React.FC<ISportResponse> = (sport) => {
  const { id, name, order } = sport;
  const { onEdit, onDelete } = useTableItem(openDeleteSportModal);
  return (
    <TableRow>
      <TableCell component="th" scope="row">
        {id}
      </TableCell>
      <TableCell component="th" scope="row">
        {name}
      </TableCell>
      <TableCell component="th" scope="row">
        {order ?? 0}
      </TableCell>
      <TableCell component="th" scope="row">
        <IconButton onClick={onEdit(id)}>
          <Edit />
        </IconButton>
        <IconButton onClick={onDelete(sport)}>
          <Delete />
        </IconButton>
      </TableCell>
    </TableRow>
  );
};

export default SportTableItem;
