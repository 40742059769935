import React from 'react';

import { BookmakerBonusForm } from '@/components';

import { useEditBookmakerBonusPage } from './useEditBookmakerBonusPage';

const EditBookmakerBonusPage = () => {
  const props = useEditBookmakerBonusPage();
  return <BookmakerBonusForm {...props} />;
};

export default EditBookmakerBonusPage;
