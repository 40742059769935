import React from 'react';

import { ApiIntegrationForm, EventForm } from '@/components';
import { IntegrationEntityType } from '@/types';

import { useEditEventPage } from './useEditEventPage';

const EditEventPage = () => {
  const { event, ...props } = useEditEventPage();
  return (
    <>
      <EventForm {...props} />
      <ApiIntegrationForm id={event?.id} entityType={IntegrationEntityType.Event} />
    </>
  );
};

export default EditEventPage;
