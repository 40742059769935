import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

import { ApiRoutes } from '@/constants';
import { objToFormData, setAccessTokenToHeaders } from '@/helpers';
import { IBookmakerPayload } from '@/types';
import { envUtil } from '@/utils';

import type { IBookmakerResponse, IRequestParams, IResponse } from '@/types';

const { api } = envUtil.getEnv();

export const bookmakersApi = createApi({
  reducerPath: 'bookmakersAPI',
  baseQuery: fetchBaseQuery({
    baseUrl: api,
    prepareHeaders: (headers) => setAccessTokenToHeaders(headers),
  }),
  tagTypes: ['Bookmakers', 'Bookmaker'],
  endpoints: (builder) => ({
    getBookmakersList: builder.query<IResponse<IBookmakerResponse>, IRequestParams>({
      query: (params) => ({
        url: ApiRoutes.Bookmakers,
        params,
      }),
      providesTags: (result) =>
        result
          ? [
              ...result.items.map(({ id }) => ({
                type: 'Bookmakers' as const,
                id,
              })),
              { type: 'Bookmakers', id: 'LIST' },
            ]
          : [{ type: 'Bookmakers', id: 'LIST' }],
      keepUnusedDataFor: 1,
    }),
    getBookmakerById: builder.query<IBookmakerResponse, string>({
      query: (id) => ({
        url: `${ApiRoutes.Bookmakers}/find-by-id/${id}`,
      }),
      providesTags: () => ['Bookmaker'],
    }),
    editBookmaker: builder.mutation<IBookmakerResponse, IBookmakerPayload & { id: number | undefined }>({
      query: ({ id, ...body }) => ({
        url: `${ApiRoutes.Bookmakers}/${id}`,
        body: objToFormData<IBookmakerPayload>(body),
        method: 'PUT',
      }),
      invalidatesTags: ['Bookmaker'],
    }),
    createBookmaker: builder.mutation<IBookmakerResponse, IBookmakerPayload>({
      query: (body) => ({
        url: ApiRoutes.Bookmakers,
        body: objToFormData<IBookmakerPayload>(body),
        method: 'POST',
      }),
    }),
    updateIcon: builder.mutation<
      IBookmakerResponse,
      {
        id: string;
        icon: File;
      }
    >({
      query: ({ id, icon }) => ({
        url: `${ApiRoutes.Bookmakers}/${id}/icon`,
        body: icon,
        method: 'PATCH',
      }),
    }),
    deleteBookmaker: builder.mutation<void, number | undefined>({
      query: (id) => ({
        url: `${ApiRoutes.Bookmakers}/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: [{ type: 'Bookmakers', id: 'LIST' }],
    }),
  }),
});

export const {
  useGetBookmakersListQuery,
  useDeleteBookmakerMutation,
  useGetBookmakerByIdQuery,
  useEditBookmakerMutation,
  useUpdateIconMutation,
  useCreateBookmakerMutation,
} = bookmakersApi;
