import { toIsoString } from '@/utils';

type SetFieldValueType = (field: string, value: string) => void;

export const useUserForm = () => {
  const banUserByDate = (dateFunc: () => Date, setFieldValue: SetFieldValueType) => () => {
    const date = dateFunc();
    setFieldValue('bannedTill', toIsoString(date));
  };
  return { banUserByDate };
};
