import {
  Box,
  CircularProgress,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
} from '@mui/material';
import React, { memo } from 'react';

import { TableFilters } from '@/components';

import { styles } from './styles';

import type { IPaginationParams, FiltersChangeFunc, FiltersType } from '@/types';
import type { SxProps } from '@mui/material';

interface ICustomTable extends IPaginationParams {
  hideFilters?: boolean;
  deletingModal?: React.FC;
  children: React.ReactNode;
  filteringFields?: FiltersType;
  onFiltersChange?: FiltersChangeFunc;
  sx?: SxProps;
  headCells: { title: string; width?: number | string }[];
  noData: boolean;
  isLoading: boolean;
}

const CustomTable = memo(
  ({
    noData,
    deletingModal: DeletingModal,
    isLoading,
    children,
    headCells,
    filteringFields,
    hideFilters,
    onFiltersChange,
    sx = {},
    ...paginationParams
  }: ICustomTable) => {
    const showFilters = !hideFilters && filteringFields && onFiltersChange;
    return (
      <>
        <Box sx={styles.tableWrapper}>
          <TableContainer component={Paper} sx={{ ...styles.tableContainer, ...sx }}>
            <Table sx={styles.table}>
              <TableHead>
                <TableRow>
                  {headCells.map(({ title, ...params }) => (
                    <TableCell {...params} key={title}>
                      {title}
                    </TableCell>
                  ))}
                </TableRow>
                {showFilters && (
                  <TableRow>
                    <TableFilters filters={filteringFields} onChange={onFiltersChange} />
                  </TableRow>
                )}
              </TableHead>
              <TableBody>
                {noData || isLoading ? (
                  <TableRow>
                    <TableCell colSpan={8}>
                      <Box sx={styles.emptyData}>
                        {!isLoading && noData && (
                          <Typography variant="h5">There no any data</Typography>
                        )}
                        {isLoading && <CircularProgress />}
                      </Box>
                    </TableCell>
                  </TableRow>
                ) : (
                  <>{children}</>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
        <TablePagination component={Paper} {...paginationParams} />
        {DeletingModal && <DeletingModal />}
      </>
    );
  },
);

CustomTable.displayName = 'CustomTable';

export default CustomTable;
