import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/dist/query/react';

import { ApiRoutes } from '@/constants';
import { setAccessTokenToHeaders } from '@/helpers';
import { envUtil } from '@/utils';

import type { ISectionResponse, IChallengeResponse, IRequestParams, IResponse } from '@/types';

const { api } = envUtil.getEnv();

export const sectionsApi = createApi({
  reducerPath: 'sectionsApi',
  baseQuery: fetchBaseQuery({
    baseUrl: api,
    prepareHeaders: (headers) => setAccessTokenToHeaders(headers),
  }),
  endpoints: (builder) => ({
    getSections: builder.query<IResponse<ISectionResponse>, IRequestParams>({
      query: (params) => ({
        url: ApiRoutes.Sections,
        params,
      }),
    }),
    getSectionById: builder.query<IChallengeResponse, string>({
      query: (id) => ({
        url: `${ApiRoutes.Sections}/${id}`,
      }),
    }),
  }),
});

export const { useGetSectionsQuery, useGetSectionByIdQuery } = sectionsApi;
