import {useCallback, useEffect} from 'react';
import {useNavigate} from "react-router-dom";

import { useCreateBookmakerBonusMutation } from '@/apis/bookmaker-bonuses';
import {Pathes} from "@/constants";
import { useToastMessageRequest } from '@/hooks';

import type { IBookmakerBonusFormParams } from '@/types';

export const useCreateBookmakerBonusPage = () => {
  const [createBonus, { isLoading, isSuccess, ...requestParams }] = useCreateBookmakerBonusMutation();

  useToastMessageRequest({ isSuccess, ...requestParams });

  const navigate = useNavigate();

  useEffect(() => {
    if (isSuccess) {
      navigate(Pathes.BookmakerBonuses);
    }
  }, [isSuccess]);

  const onSubmit = useCallback((params: IBookmakerBonusFormParams) => {
    createBonus(params);
  }, []);

  return { isLoading, onSubmit };
};
