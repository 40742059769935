import { FilterTypes, FilterValues } from '@/types';

export const headCells = [
  { title: 'Id', width: 100 },
  { title: 'External Id', width: 100 },
  { title: 'Fs External Id', width: 110 },
  { title: 'Image', width: 100 },
  { title: 'Name', width: 200 },
  { title: 'Sport', width: 200 },
  { title: 'Position', width: 200 },
  { title: 'Age', width: 150 },
  { title: 'Team', width: 150 },
  { title: '', width: 100 },
];

export const getFilteringFields = (sports: FilterValues[]) => [
  { name: 'id', type: FilterTypes.Text },
  { name: 'externalId', type: FilterTypes.Text },
  { name: 'fsExternalId', type: FilterTypes.Text },
  null,
  { name: 'name', type: FilterTypes.Text },
  { name: 'sportName', type: FilterTypes.Select, values: sports },
  { name: 'positionName', type: FilterTypes.Text },
  { name: 'age', type: FilterTypes.Text },
  { name: 'teamName', type: FilterTypes.Text },
  null,
];
