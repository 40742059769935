import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { useDeleteNewsMutation } from '@/apis/news';
import { useToastMessageRequest } from '@/hooks';
import { newsSelectors } from '@/redux/news';
import { closeDeleteNewsModal } from '@/redux/news/slice';

export const useDeleteNewsModal = () => {
  const dispatch = useDispatch();
  const isOpen = useSelector(newsSelectors.isDeleteNewsModalOpenSelector);
  const news = useSelector(newsSelectors.selectedNewsForModalSelector);
  const [deleteNews, { isSuccess, ...requestParams }] = useDeleteNewsMutation();

  useToastMessageRequest({ isSuccess, ...requestParams });

  const closeModal = () => {
    dispatch(closeDeleteNewsModal());
  };

  const confirmDeleting = () => {
    deleteNews(news?.id ?? 0);
  };

  useEffect(() => {
    if (isSuccess) {
      closeModal();
    }
  }, [isSuccess]);

  return { isOpen, news, closeModal, confirmDeleting };
};
