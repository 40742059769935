import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/dist/query/react';

import { ApiRoutes } from '@/constants';
import { setAccessTokenToHeaders } from '@/helpers';
import { envUtil } from '@/utils';

import type { IVenueResponse, IRequestParams, IResponse } from '@/types';

const { api } = envUtil.getEnv();

export const venuesApi = createApi({
  reducerPath: 'venuesApi',
  baseQuery: fetchBaseQuery({
    baseUrl: api,
    prepareHeaders: (headers) => setAccessTokenToHeaders(headers),
  }),
  endpoints: (builder) => ({
    getVenues: builder.query<IResponse<IVenueResponse>, IRequestParams>({
      query: (params) => ({
        url: ApiRoutes.Venues,
        params,
      }),
    }),
    getVenueById: builder.query<IVenueResponse, string>({
      query: (id) => ({
        url: `${ApiRoutes.Venues}/${id}`,
      }),
    }),
  }),
});

export const { useGetVenuesQuery, useGetVenueByIdQuery } = venuesApi;
