export enum IntegrationEntityType {
  Sport = 'sport',
  Team = 'team',
  League = 'league',
  Event = 'event',
  Player = 'player',
  Challenge = 'challenge',
  Season = 'season',
  Section = 'section',
  Venue = 'venue',
  Manager = 'manager',
}

export interface IntegrationEntityParams {
  entityType: IntegrationEntityType;
  relationRecordId?: number;
}
