import React from 'react';

import { NewsForm } from '@/components';

import { useEditNewsPage } from './useEditNewsPage';

const EditNewsPage = () => {
  const props = useEditNewsPage();
  return <NewsForm {...props} />;
};

export default EditNewsPage;
