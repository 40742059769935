import { useCallback, useMemo } from 'react';
import { useParams } from 'react-router-dom';

import { useEditLeagueMutation, useGetLeagueBySlugQuery } from '@/apis/leagues';
import { useToastMessageRequest } from '@/hooks';

import type { ILeagueFormParams } from '@/types';

export const useEditLeaguePage = () => {
  const params = useParams();
  const { data: league } = useGetLeagueBySlugQuery(params.slug as string);
  const [editLeague, { isLoading, ...editParams }] = useEditLeagueMutation();

  useToastMessageRequest(editParams);

  const initialValues = useMemo(
    () => ({
      logo: league?.logo ?? '',
      order: league?.order ?? 0,
      rank: league?.rank ?? 0,
      isFavorite: league?.isFavorite ?? false,
      isActive: league?.isActive ?? false,
      isLeagueFsSyncOn: league?.isLeagueFsSyncOn ?? false,
    }),
    [league],
  );

  const onSubmit = useCallback(
    (params: ILeagueFormParams) => {
      editLeague({ id: league?.id ?? 0, ...params });
    },
    [league],
  );
  return { league, isLoading, initialValues, onSubmit };
};
