import React from 'react';

import { CustomTable, ManagerTableItem } from '@/components';

import { headCells } from './constants';
import { useMainManagersPage } from './useMainManagersPage';

const MainManagersPage = () => {
  const { managers, ...tableProps } = useMainManagersPage();
  return (
    <>
      <CustomTable headCells={headCells} hideFilters {...tableProps}>
        {managers?.items.map((manager) => (
          <ManagerTableItem {...manager} key={manager.id} />
        ))}
      </CustomTable>
    </>
  );
};

export default MainManagersPage;
