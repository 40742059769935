import { useCallback, useMemo } from 'react';
import { useParams } from 'react-router-dom';

import { useEditNewsMutation, useGetNewsByIdQuery } from '@/apis/news';
import { useToastMessageRequest } from '@/hooks';

import type { INewsFormParams } from '@/types';

export const useEditNewsPage = () => {
  const params = useParams();
  const id = params.id as string;
  const { data: news } = useGetNewsByIdQuery(id);
  const [editNews, { isLoading, ...editArticleParams }] = useEditNewsMutation();


  useToastMessageRequest(editArticleParams);

  const initialValues = useMemo(
    () => ({
      title: news?.title ?? '',
      content: news?.content ?? '',
      isPublished: news?.isPublished ?? false,
      sportId: news?.sport?.id ?? 0,
      league: news?.league ?? {
        name: '',
        id: 0,
      },
    }),
    [news],
  );

  const onSubmit = useCallback(
    ({ league, ...params }: INewsFormParams) => {
      editNews({ id, leagueId: league.id ?? 0, ...params });
    },
    [id],
  );
  return { isLoading, initialValues, onSubmit };
};
