import { createSlice } from '@reduxjs/toolkit';

import type { ITeamsState } from './types';

const initialState: ITeamsState = {
  isDeleteTeamModalOpen: false,
  selectedTeamForModal: null,
};

export const teamsSlice = createSlice({
  name: 'teams',
  initialState,
  reducers: {
    openDeleteTeamModal(state: ITeamsState, { payload: team }) {
      state.isDeleteTeamModalOpen = true;
      state.selectedTeamForModal = team;
    },
    closeDeleteTeamModal(state: ITeamsState) {
      state.isDeleteTeamModalOpen = false;
      state.selectedTeamForModal = null;
    },
  },
});

export const { openDeleteTeamModal, closeDeleteTeamModal } = teamsSlice.actions;

export default teamsSlice.reducer;
