import { Facebook, Twitter } from '@mui/icons-material';
import { Button, DialogActions, Grid, Box, InputAdornment } from '@mui/material';
import { Field, Form, Formik } from 'formik';
import React from 'react';

import { EmailInput, RichTextEditorInput, TextInput } from '@/components';

import { siteSettingsSchema } from './schema';
import { styles } from './styles';
import { useSiteSettingsForm } from './useSiteSettingsForm';

const SiteSettingsForm = () => {
  const { initialValues, isUpdateLoading, isCreateLoading, onSubmit } = useSiteSettingsForm();
  return (
    <Formik
      enableReinitialize
      validationSchema={siteSettingsSchema}
      initialValues={initialValues}
      onSubmit={onSubmit}
    >
      {({ dirty }) => (
        <Form>
          <Box sx={styles.container}>
            <Grid container spacing={styles.containerSpacing}>
              <Grid item xs={12} md={12} lg>
                <Field
                  type="text"
                  name="adminEmail"
                  autoComplete="adminEmail"
                  fullWidth
                  component={EmailInput}
                  placeholder="Admin Email"
                />
              </Grid>
              <Grid item xs={12} md={12} lg>
                <Field
                  type="text"
                  name="vkontakte"
                  autoComplete="vkontakte"
                  fullWidth
                  component={TextInput}
                  placeholder="Vk"
                />
              </Grid>
            </Grid>
            <Grid container spacing={styles.containerSpacing}>
              <Grid item xs={12} md={12} lg>
                <Field
                  type="text"
                  name="facebook"
                  autoComplete="facebook"
                  fullWidth
                  component={TextInput}
                  placeholder="Facebook"
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end" sx={styles.adornment}>
                        <Facebook />
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
              <Grid item xs={12} md={12} lg>
                <Field
                  type="text"
                  name="twitter"
                  autoComplete="twitter"
                  fullWidth
                  component={TextInput}
                  placeholder="Twitter"
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end" sx={styles.adornment}>
                        <Twitter />
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
            </Grid>
            <Grid container spacing={styles.containerSpacing}>
              <Grid item xs={12} md={12} lg>
                <Field
                  type="text"
                  name="metrics"
                  autoComplete="metrics"
                  fullWidth
                  component={TextInput}
                  placeholder="Metrics and Analytics code"
                  multiline
                  rows={3}
                />
              </Grid>
              <Grid item xs={12} md={12} lg>
                <Field
                  type="text"
                  name="partner"
                  autoComplete="partner"
                  fullWidth
                  component={TextInput}
                  placeholder="Partner link"
                  multiline
                  rows={3}
                />
              </Grid>
            </Grid>
            <Box mt={2}>
              <Field
                type="text"
                name="copyright"
                autoComplete="copyright"
                fullWidth
                component={RichTextEditorInput}
                placeholder="Copyright"
                multiline
                rows={3}
              />
            </Box>
            <Box mt={2}>
              <Field
                type="text"
                name="404"
                autoComplete="404"
                fullWidth
                component={RichTextEditorInput}
                placeholder="Error code"
              />
            </Box>
            <Box mt={2}>
              <Field
                type="text"
                name="bookmakersContent"
                autoComplete="Description"
                fullWidth
                component={RichTextEditorInput}
                placeholder="Bookmakers page description"
              />
            </Box>
            <Box mt={2}>
              <Field
                type="text"
                name="freeBetsContent"
                autoComplete="Description"
                fullWidth
                component={RichTextEditorInput}
                placeholder="Free bets page description"
              />
            </Box>
            <Box mt={2}>
              <Field
                type="text"
                name="predictionsContent"
                autoComplete="Description"
                fullWidth
                component={RichTextEditorInput}
                placeholder="Predictions page description"
              />
            </Box>
            <Box mt={2}>
              <Field
                type="text"
                name="supportContent"
                autoComplete="Description"
                fullWidth
                component={RichTextEditorInput}
                placeholder="Support page content"
              />
            </Box>
            <Box mt={2}>
              <Field
                type="text"
                name="privacyPolicyContent"
                autoComplete="Description"
                fullWidth
                component={RichTextEditorInput}
                placeholder="Privacy policy page content"
              />
            </Box>
            <DialogActions>
              <Button
                variant="contained"
                type="submit"
                sx={styles.btn}
                disabled={!dirty || isUpdateLoading || isCreateLoading}
              >
                Save
              </Button>
            </DialogActions>
          </Box>
        </Form>
      )}
    </Formik>
  );
};

export default SiteSettingsForm;
