import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { useDeleteBookmakerReviewMutation } from '@/apis/bookmaeker-reviews';
import { useToastMessageRequest } from '@/hooks';
import { bookmakerReviewsSelectors } from '@/redux/bookmaker-reviews';
import { closeDeleteBookmakerReviewModal } from '@/redux/bookmaker-reviews/slice';

export const useDeleteBookmakerReviewModal = () => {
  const dispatch = useDispatch();

  const isOpen = useSelector(bookmakerReviewsSelectors.isDeleteBookmakerReviewModalOpenSelector);
  const review = useSelector(bookmakerReviewsSelectors.selectedBookmakerReviewForModalSelector);

  const [deleteReview, { isSuccess, ...requestParams }] = useDeleteBookmakerReviewMutation();

  useToastMessageRequest({ isSuccess, ...requestParams });

  const closeModal = () => {
    dispatch(closeDeleteBookmakerReviewModal());
  };

  const confirmDeleting = () => {
    deleteReview(review?.id ?? '');
  };

  useEffect(() => {
    if (isSuccess) {
      closeModal();
    }
  }, [isSuccess]);

  return { isOpen, closeModal, confirmDeleting };
};
