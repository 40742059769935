import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/dist/query/react';

import { ApiRoutes } from '@/constants';
import { setAccessTokenToHeaders } from '@/helpers';
import { envUtil } from '@/utils';

import type { IntegrationResponse, IntegrationEntityParams, IntegrationEntityType } from '@/types';

const { api } = envUtil.getEnv();

export const integrationsApi = createApi({
  reducerPath: 'integrationsApi',
  baseQuery: fetchBaseQuery({
    baseUrl: api,
    prepareHeaders: (headers) => setAccessTokenToHeaders(headers),
  }),
  tagTypes: ['Integrations'],
  endpoints: (builder) => ({
    createIntegration: builder.mutation<void, { name: string } & IntegrationEntityParams>({
      query: ({ entityType, ...body }) => ({
        url: ApiRoutes.ApiIntegrations,
        params: { entityType },
        body,
        method: 'POST',
      }),
      invalidatesTags: [{ type: 'Integrations', id: 'LIST' }],
    }),
    deleteIntegration: builder.mutation<void, { id: number; entityType: IntegrationEntityType }>({
      query: ({ id, ...params }) => ({
        url: `${ApiRoutes.ApiIntegrations}/${id}`,
        method: 'DELETE',
        params,
      }),
      invalidatesTags: [{ type: 'Integrations', id: 'LIST' }],
    }),
    getAdminIntegrationById: builder.query<IntegrationResponse[], IntegrationEntityParams>({
      query: ({ relationRecordId, ...params }) => ({
        url: `${ApiRoutes.ApiIntegrations}/${relationRecordId}`,
        params,
      }),
      providesTags: (result) =>
        result
          ? [
              ...result.map(({ id }) => ({
                type: 'Integrations' as const,
                id,
              })),
              { type: 'Integrations', id: 'LIST' },
            ]
          : [{ type: 'Integrations', id: 'LIST' }],
      keepUnusedDataFor: 1,
    }),
  }),
});

export const {
  useCreateIntegrationMutation,
  useDeleteIntegrationMutation,
  useGetAdminIntegrationByIdQuery,
} = integrationsApi;
