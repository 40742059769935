import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

import { ApiRoutes } from '@/constants';
import { objToFormData, setAccessTokenToHeaders } from '@/helpers';
import { envUtil } from '@/utils';

import type {
  IRequestParams,
  IResponse,
  IUserExpertResponse,
  IExpertFormParams,
  IUserRequestParams,
  IPlayerResponse,
  IPlayerFormParams,
} from '@/types';

const { api } = envUtil.getEnv();

export const playersApi = createApi({
  reducerPath: 'playersApi',
  baseQuery: fetchBaseQuery({
    baseUrl: api,
    prepareHeaders: (headers) => setAccessTokenToHeaders(headers),
  }),
  tagTypes: ['Players', 'Player'],
  endpoints: (builder) => ({
    getPlayersList: builder.query<IResponse<IPlayerResponse>, IRequestParams & IUserRequestParams>({
      query: (params) => ({
        url: ApiRoutes.Players,
        params,
      }),
      providesTags: (result) =>
        result
          ? [
              ...result.items.map(({ id }) => ({
                type: 'Players' as const,
                id,
              })),
              { type: 'Players', id: 'LIST' },
            ]
          : [{ type: 'Players', id: 'LIST' }],
      keepUnusedDataFor: 1,
    }),
    getPlayerById: builder.query<IPlayerResponse, string>({
      query: (id) => ({
        url: `${ApiRoutes.Players}/${id}`,
      }),
      providesTags: () => ['Player'],
    }),
    editPlayer: builder.mutation<IPlayerResponse, IPlayerFormParams & { id: number }>({
      query: ({ id, ...body }) => ({
        url: `${ApiRoutes.Players}/${id}`,
        body: objToFormData<IPlayerFormParams>(body),
        method: 'PUT',
      }),
      invalidatesTags: ['Player'],
    }),
    deletePlayer: builder.mutation<void, number>({
      query: (id) => ({
        url: `${ApiRoutes.Players}/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: [{ type: 'Players', id: 'LIST' }],
    }),
    createPlayer: builder.mutation<IUserExpertResponse, IExpertFormParams & { id: string }>({
      query: ({ id, ...body }) => ({
        url: `${ApiRoutes.Players}/${id}/expert`,
        body,
        method: 'POST',
      }),
      invalidatesTags: ['Player'],
    }),
  }),
});

export const {
  useGetPlayersListQuery,
  useGetPlayerByIdQuery,
  useEditPlayerMutation,
  useDeletePlayerMutation,
} = playersApi;
