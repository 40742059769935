import { Add } from '@mui/icons-material';
import { Button, Typography } from '@mui/material';
import React from 'react';

import { ArticleTableItem, CustomTable, DeleteArticleModal } from '@/components';

import { headCells } from './constants';
import { useMainArticlesPage } from './useMainArticlesPage';

const MainArticlesPage = () => {
  const { articles, goToCreatePage, ...tableProps } = useMainArticlesPage();
  return (
    <>
      <Button variant="contained">
        <Add />
        <Typography ml={2} fontWeight="bold" onClick={goToCreatePage}>
          Create Articles
        </Typography>
      </Button>
      <CustomTable headCells={headCells} deletingModal={DeleteArticleModal} {...tableProps}>
        {articles?.items.map((article) => (
          <ArticleTableItem {...article} key={article.id} />
        ))}
      </CustomTable>
    </>
  );
};

export default MainArticlesPage;
