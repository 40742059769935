import { useGetSeasonsQuery } from '@/apis/seasons';
import { usePagination } from '@/hooks';

export const useMainSeasonsPage = () => {
  const paginationParams = usePagination();
  const { page, rowsPerPage: limit } = paginationParams;
  const { data: seasons, isLoading } = useGetSeasonsQuery({ page: page + 1, limit });

  const count = (seasons?.meta.totalPages ?? 1) * limit;

  const noData = !seasons?.items.length;

  return { count, noData, isLoading, seasons, ...paginationParams };
};
