import { Delete, Edit } from '@mui/icons-material';
import { IconButton, TableCell, TableRow } from '@mui/material';
import React from 'react';

import { useTableItem } from '@/hooks';
import { openDeleteBookmakerReviewModal } from '@/redux/bookmaker-reviews/slice';

import type { IBookmakerReviewResponse } from '@/types';

const BookmakerReviewTableItem = (review: IBookmakerReviewResponse) => {
  const { id, text, bookmaker } = review;
  const { onEdit, onDelete } = useTableItem(openDeleteBookmakerReviewModal);
  return (
    <TableRow>
      <TableCell component="th" scope="row">
        {id}
      </TableCell>
      <TableCell component="th" scope="row">
        {bookmaker.name}
      </TableCell>
      <TableCell component="th" scope="row">
        {text}
      </TableCell>
      <TableCell width={100}>
        <IconButton onClick={onEdit(id)}>
          <Edit />
        </IconButton>
        <IconButton onClick={onDelete(review)}>
          <Delete />
        </IconButton>
      </TableCell>
    </TableRow>
  );
};

export default BookmakerReviewTableItem;
