import { array, object } from 'yup';
import '@/constants/yupSettings';

import { text } from '@/constants';

import type { ITranslationsArticleSectionResponse } from '@/types';

export const articleSectionFormSchema = object({
  translations: array()
    .of(
      object().shape({
        name: text,
        language: object().shape({ name: text }),
      }),
    )
    .unique<ITranslationsArticleSectionResponse>(
      (s) => s.language.name,
      'language must be uniq in each section',
    ),
}).required();
