export enum BonusType {
  FREEBET = 'freebet',
  PERCENT_OF_DEPOSIT = 'percent_of_deposit',
}

export interface IBookmakerBonusResponse {
  slug: string;
  id: string;
  title: string;
  text: string;
  imageUrl: string;
  bookmakerId: string;
  updatedAt: string;
  createdDate: string;
  endDate: string;
  link: string;
  seoDescription: string;
  type: BonusType;
}
