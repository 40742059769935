import { useCallback } from 'react';

import { useLazyGetLeaguesQuery } from '@/apis/leagues';
import { useGetSportsListQuery } from '@/apis/sports';

export const useNewsForm = () => {
  const { data: sports } = useGetSportsListQuery({ page: 1, limit: 100 });
  const [getLeagues] = useLazyGetLeaguesQuery();

  const getOptions = useCallback(async (name = '') => {
    const { data } = await getLeagues({ page: 1, limit: 10, name });
    return data?.items ?? [];
  }, []);

  return { sports, getOptions };
};
