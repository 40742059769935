import { createSlice } from '@reduxjs/toolkit';

import type { IArticleSectionsState } from './types';

const initialState: IArticleSectionsState = {
  isDeleteArticleSectionModalOpen: false,
  selectedArticleSectionForModal: null,
};

export const articlesSectionsSlice = createSlice({
  name: 'articlesSectionsSlice',
  initialState,
  reducers: {
    openDeleteArticleSectionModal(state: IArticleSectionsState, { payload: team }) {
      state.isDeleteArticleSectionModalOpen = true;
      state.selectedArticleSectionForModal = team;
    },
    closeDeleteArticleSectionModal(state: IArticleSectionsState) {
      state.isDeleteArticleSectionModalOpen = false;
      state.selectedArticleSectionForModal = null;
    },
  },
});

export const { openDeleteArticleSectionModal, closeDeleteArticleSectionModal } =
  articlesSectionsSlice.actions;

export default articlesSectionsSlice.reducer;
