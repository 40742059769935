import { useMemo } from 'react';

import { useGetSportsListQuery } from '@/apis/sports';

const useGetSportsForFilters = () => {
  const { data } = useGetSportsListQuery({ limit: 20, page: 1 });
  const sports = useMemo(
    () => (data ? data?.items.map(({ name }) => ({ value: name, label: name })) : []),
    [data],
  );
  return [{ label: 'Football', value: 'Football' }, ...sports];
};

export default useGetSportsForFilters;
