import { Edit } from '@mui/icons-material';
import { TableCell, TableRow, IconButton, Box, Typography, Link } from '@mui/material';
import React from 'react';

import { useTableItem } from '@/hooks';
import { IEventsResponse } from '@/types';

import { dateOptions } from './constants';

const EventTableItem: React.FC<IEventsResponse> = (event) => {
  const {
    id,
    name,
    homeTeam,
    awayTeam,
    sport,
    league,
    startAt,
    externalId,
    fsExternalId,
    status,
    homeScore,
    awayScore,
  } = event;
  const { onEdit } = useTableItem();
  return (
    <TableRow>
      <TableCell>{id}</TableCell>
      <TableCell>{externalId}</TableCell>
      <TableCell>{fsExternalId}</TableCell>
      <TableCell>{name}</TableCell>
      <TableCell>{status}</TableCell>
      <TableCell>
        <Typography variant="h6">
          <Link href={`teams/edit/${homeTeam?.id}`}>{homeTeam?.name}</Link>
        </Typography>
      </TableCell>
      <TableCell>{homeTeam?.fsExternalId}</TableCell>
      <TableCell>{homeScore?.current}</TableCell>
      <TableCell>
        <Box width={30} height={30} component="img" src={homeTeam?.logo} />
      </TableCell>
      <TableCell>
        <Box width={30} height={30} component="img" src={awayTeam?.logo} />
      </TableCell>
      <TableCell>{awayScore?.current}</TableCell>
      <TableCell>{awayTeam?.fsExternalId}</TableCell>
      <TableCell>
        <Typography variant="h6">
          <Link href={`teams/edit/${awayTeam?.id}`}> {awayTeam?.name}</Link>
        </Typography>
      </TableCell>
      <TableCell>{sport.name}</TableCell>
      <TableCell>
        <Link href={`leagues/edit/${league?.slug}`}>{league?.name}</Link>
      </TableCell>
      <TableCell> {new Date(startAt).toLocaleDateString(undefined, dateOptions)}</TableCell>
      <TableCell>
        <IconButton onClick={onEdit(id)}>
          <Edit />
        </IconButton>
      </TableCell>
    </TableRow>
  );
};

export default EventTableItem;
