import { string, number as yupNumber, mixed } from 'yup';

import { passwordRegexp } from './regexp';

export const email = string().email().trim().required();

export const name = string().trim().required('Full name is a required field');

export const text = string().trim().required();

export const number = yupNumber().required();

export const password = string()
  .trim()
  .required()
  .matches(
    passwordRegexp,
    'Password must contain at least 8 characters, at least one number and both lower and uppercase letters',
  );

export const fileExt = (allowedFileTypes: string[], allowUrl = false) =>
  mixed().test('fileType', 'This type of file is not allowed', (value: File | string) => {
    const type = (value as File)?.type ?? '';

    const regexList = allowedFileTypes.map(
      (x) => new RegExp(x.replace('.', '\\.').replace('*', '.*')),
    );

    return regexList.some((regex) => regex.test(type)) || (allowUrl && !!(value as string)?.length);
  });
