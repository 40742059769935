export enum EventStatusEnum {
  CANCELED = 'canceled',
  DELAYED = 'delayed',
  FINISHED = 'finished',
  IN_PROGRESS = 'inprogress',
  INTERRUPTED = 'interrunpted',
  NOT_STARTED = 'notstarted',
  POST_PONED = 'postponed',
  SUSPENDET = 'suspendet',
  WILL_CONTINUE = 'willcontinue',
  FT = 'ft',
  FRO = 'FRO',
  NONE = '-',
}
