import { getCountriesForFilters } from '@/constants';
import { FilterTypes } from '@/types';

import type { FilterValues } from '@/types';

export const headCells = [
  { title: 'Id', width: 100 },
  { title: 'External Id', width: 100 },
  { title: 'Fs External Id', width: 110 },
  { title: 'Name', width: 200 },
  { title: 'Gender', width: 50 },
  { title: 'Rating', width: 150 },
  { title: 'Sport', width: 150 },
  { title: 'League', width: 150 },
  { title: 'Country', width: 150 },
  { title: 'Logo', width: 50 },
  { title: '', width: 100 },
];

const genderValues = [{ value: 'F', label: 'Female' }, { value: 'M', label: 'Male' }];

export const getFilteringFields = (sports: FilterValues[]) => [
  { name: 'id', type: FilterTypes.Text },
  { name: 'externalId', type: FilterTypes.Text },
  { name: 'fsExternalId', type: FilterTypes.Text },
  { name: 'name', type: FilterTypes.Text },
  { name: 'gender', type: FilterTypes.Select, values: genderValues },
  { name: 'rating', type: FilterTypes.Text },
  { name: 'sport', type: FilterTypes.Select, values: sports },
  { name: 'leagueName', type: FilterTypes.Text },
  { name: 'country', type: FilterTypes.Select, values: getCountriesForFilters() },
  null,
  null,
];
