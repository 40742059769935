import React from 'react';

import { ArticleForm } from '@/components';

import { useCreateArticlePage } from './useCreateArticlePage';

const CreateArticlePage = () => {
  const props = useCreateArticlePage();
  return <ArticleForm {...props} />;
};

export default CreateArticlePage;
