import React from 'react';

import { DeleteConfirmationDialog } from '@/components';

import { useDeleteArticleModal } from './useDeleteArticleModal';

const DeleteArticleModal: React.FC = () => {
  const { isOpen, article, closeModal, confirmDeleting } = useDeleteArticleModal();
  return (
    <DeleteConfirmationDialog
      open={isOpen}
      onClose={closeModal}
      onConfirm={confirmDeleting}
      text={`Are you sure you want to delete ${article?.title ?? ''} section?`}
    />
  );
};

export default DeleteArticleModal;
