export const defaultInitialValues = {
  name: '',
  seoForecastPage: '',
  seoText: '',
  slug: '',
  source: '',
  externalId: '',
  order: 0,
  isActive: false,
};
