import { combineReducers, configureStore } from '@reduxjs/toolkit';

import {
  bookmakersApi,
  authApi,
  usersApi,
  leaguesApi,
  bookmakerBonusesApi,
  bookmakerReviewsApi,
  websiteSettingsApi,
  sportsApi,
  teamsApi,
  playersApi,
  articleSectionApi,
  articleApi,
  languagesApi,
  newsApi,
  challengesApi,
  imagesApi,
  eventsApi,
  integrationsApi,
  seasonsApi,
  sectionsApi,
  venuesApi,
  managersApi,
  logsApi,
  cronsApi,
  contentApi,
} from '@/apis';
import { articlesReducer } from '@/redux/articles';
import { articlesSectionsReducer } from '@/redux/articles-sections';
import { bookmakerBonusesReducer } from '@/redux/bookmaker-bonuses';
import { bookmakerReviewsReducer } from '@/redux/bookmaker-reviews';
import { bookmakersReducer } from '@/redux/bookmakers';
import { leaguesReducer } from '@/redux/leagues';
import { logsReducer } from '@/redux/logs';
import { newsReducer } from '@/redux/news';
import { playersReducer } from '@/redux/players';
import { sportsReducer } from '@/redux/sports';
import { teamsReducer } from '@/redux/teams';
import { usersReducer } from '@/redux/users';

const rootReducer = combineReducers({
  [managersApi.reducerPath]: managersApi.reducer,
  [integrationsApi.reducerPath]: integrationsApi.reducer,
  [languagesApi.reducerPath]: languagesApi.reducer,
  [bookmakersApi.reducerPath]: bookmakersApi.reducer,
  [authApi.reducerPath]: authApi.reducer,
  [usersApi.reducerPath]: usersApi.reducer,
  [leaguesApi.reducerPath]: leaguesApi.reducer,
  [bookmakerBonusesApi.reducerPath]: bookmakerBonusesApi.reducer,
  [bookmakerReviewsApi.reducerPath]: bookmakerReviewsApi.reducer,
  [websiteSettingsApi.reducerPath]: websiteSettingsApi.reducer,
  [sportsApi.reducerPath]: sportsApi.reducer,
  [teamsApi.reducerPath]: teamsApi.reducer,
  [playersApi.reducerPath]: playersApi.reducer,
  [articleSectionApi.reducerPath]: articleSectionApi.reducer,
  [articleApi.reducerPath]: articleApi.reducer,
  [newsApi.reducerPath]: newsApi.reducer,
  [challengesApi.reducerPath]: challengesApi.reducer,
  [imagesApi.reducerPath]: imagesApi.reducer,
  [eventsApi.reducerPath]: eventsApi.reducer,
  [seasonsApi.reducerPath]: seasonsApi.reducer,
  [sectionsApi.reducerPath]: sectionsApi.reducer,
  [venuesApi.reducerPath]: venuesApi.reducer,
  [logsApi.reducerPath]: logsApi.reducer,
  [cronsApi.reducerPath]: cronsApi.reducer,
  [contentApi.reducerPath]: contentApi.reducer,
  users: usersReducer,
  bookmakers: bookmakersReducer,
  leagues: leaguesReducer,
  bookmakerBonuses: bookmakerBonusesReducer,
  bookmakerReviews: bookmakerReviewsReducer,
  teams: teamsReducer,
  players: playersReducer,
  sports: sportsReducer,
  articlesSections: articlesSectionsReducer,
  articles: articlesReducer,
  news: newsReducer,
  logs: logsReducer,
});

const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({}).concat([
      integrationsApi.middleware,
      bookmakersApi.middleware,
      authApi.middleware,
      bookmakerBonusesApi.middleware,
      bookmakerReviewsApi.middleware,
      usersApi.middleware,
      leaguesApi.middleware,
      websiteSettingsApi.middleware,
      sportsApi.middleware,
      teamsApi.middleware,
      articleSectionApi.middleware,
      articleApi.middleware,
      languagesApi.middleware,
      newsApi.middleware,
      challengesApi.middleware,
      imagesApi.middleware,
      eventsApi.middleware,
      seasonsApi.middleware,
      sectionsApi.middleware,
      venuesApi.middleware,
      managersApi.middleware,
      logsApi.middleware,
      cronsApi.middleware,
      contentApi.middleware,
    ]),
});

export type RootState = ReturnType<typeof rootReducer>;

export default store;
