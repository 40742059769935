import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/dist/query/react';

import { ApiRoutes } from '@/constants';
import { setAccessTokenToHeaders } from '@/helpers';
import {ExecuteCronParams} from "@/types";
import { envUtil } from '@/utils';

import type {ICronResponse, IRequestParams, IResponse} from '@/types';

const { api } = envUtil.getEnv();

export const cronsApi = createApi({
  reducerPath: 'cronsApi',
  baseQuery: fetchBaseQuery({
    baseUrl: api,
    prepareHeaders: (headers) => setAccessTokenToHeaders(headers),
  }),
  tagTypes: ['Crons', 'Cron'],
  endpoints: (builder) => ({
    getCrons: builder.query<IResponse<ICronResponse>, IRequestParams>({
      query: (params) => ({
        url: ApiRoutes.Crons,
        params,
      }),
      providesTags: (result) =>
        result
          ? [
            ...result.items.map(({ id }) => ({
              type: 'Crons' as const,
              id,
            })),
            { type: 'Crons', id: 'LIST' },
          ]
          : [{ type: 'Crons', id: 'LIST' }],
      keepUnusedDataFor: 1,
    }),
    getCronById: builder.query<ICronResponse, number>({
      query: (id) => ({
        url: `${ApiRoutes.Crons}/${id}`,
      }),
      providesTags: () => ['Cron'],
    }),
    executeCron:  builder.mutation<{ success: true, }, ExecuteCronParams>({
      query: ({ id, ...data}) => ({
        url: `${ApiRoutes.Crons}/${id}/execute`,
        method: 'POST',
        body: data,
      }),
    }),
  }),
});

export const {
  useGetCronsQuery,
  useGetCronByIdQuery,
  useExecuteCronMutation,
} = cronsApi;
