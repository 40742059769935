export const FILTER_TYPES = {
  INPUT: 'input',
  DATE: 'date',
}

export const SPORTS = [{
  id: 1,
  name: 'Football',
}, {
  id: 2,
  name: 'Tennis',
}, {
    id: 3,
    name: 'Basketball',
}, {
  id: 4,
  name: 'Ice Hockey',
}]
