import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

import { ApiRoutes } from '@/constants';
import { objToFormData, setAccessTokenToHeaders } from '@/helpers';
import { envUtil } from '@/utils';

import type {
  IBookmakerBonusFormParams,
  IBookmakerBonusResponse,
  IRequestParams,
  IResponse,
} from '@/types';

const { api } = envUtil.getEnv();

export const bookmakerBonusesApi = createApi({
  reducerPath: 'bookmakerBonusesApi',
  baseQuery: fetchBaseQuery({
    baseUrl: api,
    prepareHeaders: (headers) => setAccessTokenToHeaders(headers),
  }),
  tagTypes: ['Bookmaker Bonuses', 'Bookmaker Bonus'],
  endpoints: (builder) => ({
    getBookmakerBonusesList: builder.query<IResponse<IBookmakerBonusResponse>, IRequestParams>({
      query: (params) => ({
        url: ApiRoutes.BookmakerBonuses,
        params,
      }),
      providesTags: (result) =>
        result
          ? [
              ...result.items.map(({ id }) => ({
                type: 'Bookmaker Bonuses' as const,
                id,
              })),
              { type: 'Bookmaker Bonuses', id: 'LIST' },
            ]
          : [{ type: 'Bookmaker Bonuses', id: 'LIST' }],
      keepUnusedDataFor: 1,
    }),
    createBookmakerBonus: builder.mutation<IBookmakerBonusResponse, IBookmakerBonusFormParams>({
      query: (body) => ({
        url: ApiRoutes.BookmakerBonuses,
        body: objToFormData<IBookmakerBonusFormParams>(body),
        method: 'POST',
      }),
    }),
    getBookmakerBonusById: builder.query<IBookmakerBonusResponse, string>({
      query: (id) => ({
        url: `${ApiRoutes.BookmakerBonuses}/${id}`,
      }),
      providesTags: () => ['Bookmaker Bonus'],
    }),
    editBookmakerBonus: builder.mutation<
      IBookmakerBonusResponse,
      IBookmakerBonusFormParams & { id: string }
    >({
      query: ({ id, ...body }) => ({
        url: `${ApiRoutes.BookmakerBonuses}/${id}`,
        body: objToFormData<IBookmakerBonusFormParams>(body),
        method: 'PUT',
      }),
      invalidatesTags: ['Bookmaker Bonus'],
    }),
    deleteBookmakerBonus: builder.mutation<void, string>({
      query: (id) => ({
        url: `${ApiRoutes.BookmakerBonuses}/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: [{ type: 'Bookmaker Bonuses', id: 'LIST' }],
    }),
  }),
});

export const {
  useGetBookmakerBonusesListQuery,
  useCreateBookmakerBonusMutation,
  useGetBookmakerBonusByIdQuery,
  useEditBookmakerBonusMutation,
  useDeleteBookmakerBonusMutation,
} = bookmakerBonusesApi;
