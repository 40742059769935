import { createSelector } from '@reduxjs/toolkit';

import type { IBookmakerBonusesState } from './types';
import type { RootState } from '@/redux/store';

export const bookmakersStateSelector = (state: RootState) => state.bookmakerBonuses;

export const selectedBookmakerBonusForModalSelector = createSelector(
  bookmakersStateSelector,
  (state: IBookmakerBonusesState) => state.selectedBookmakerBonusForModal,
);

export const isDeleteBookmakerBonusModalOpenSelector = createSelector(
  bookmakersStateSelector,
  (state: IBookmakerBonusesState) => state.isDeleteBookmakerBonusModalOpen,
);
