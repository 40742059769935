import { createSelector } from '@reduxjs/toolkit';

import type { IBookmakerReviewsState } from './types';
import type { RootState } from '@/redux/store';

export const bookmakersStateSelector = (state: RootState) => state.bookmakerReviews;

export const selectedBookmakerReviewForModalSelector = createSelector(
  bookmakersStateSelector,
  (state: IBookmakerReviewsState) => state.selectedBookmakerReviewForModal,
);

export const isDeleteBookmakerReviewModalOpenSelector = createSelector(
  bookmakersStateSelector,
  (state: IBookmakerReviewsState) => state.isDeleteBookmakerReviewModalOpen,
);
