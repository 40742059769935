import MenuIcon from '@mui/icons-material/Menu';
import {
  AppBar,
  Box,
  CssBaseline,
  Drawer,
  Typography,
  Toolbar,
  IconButton,
  Avatar,
  Menu,
  MenuItem,
} from '@mui/material';
import React, { FC } from 'react';

import { BreadcrumbsComponent, Sidebar } from '@/components';
import {theme} from "@/constants";
import { stringAvatar } from '@/utils';

import { styles } from './styles';
import { useMainLayout } from './useMainLayout';

import type { ILayoutProps } from '@/types';

const MainLayout: FC<ILayoutProps> = ({ children, title, breadCrumds }) => {
  const {
    user,
    mobileOpen,
    anchorElUser,
    onLogoutClick,
    handleDrawerToggle,
    handleOpenUserMenu,
    handleCloseUserMenu,
  } = useMainLayout();
  return (
    <Box sx={styles.container}>
      <CssBaseline />
      <AppBar position="fixed" sx={styles.appBar}>
        <Toolbar sx={styles.toolbar}>
          <Box sx={styles.title}>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              edge="start"
              onClick={handleDrawerToggle}
              sx={styles.openDrawer}
            >
              <MenuIcon color="primary" />
            </IconButton>
            <Typography variant="h4">{title}</Typography>
          </Box>
          <IconButton onClick={handleOpenUserMenu} size="large">
            {user?.avatar ? (
              <Avatar src={user?.avatar} alt="User Avatar" />
            ) : (
              <Avatar {...stringAvatar(`${user?.firstName} ${user?.lastName}`)} />
            )}
          </IconButton>
          <Menu
            sx={styles.menu}
            anchorEl={anchorElUser}
            anchorOrigin={styles.menuAnchorOrigin}
            keepMounted
            transformOrigin={styles.menuTransformOrigin}
            open={Boolean(anchorElUser)}
            onClose={handleCloseUserMenu}
          >
            <MenuItem onClick={onLogoutClick}>
              <Typography textAlign="center">Logout</Typography>
            </MenuItem>
          </Menu>
        </Toolbar>
      </AppBar>
      <Box component="nav" sx={styles.nav} aria-label="mailbox folders">
        <Drawer
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          sx={styles.drawerMobile}
          ModalProps={{
            keepMounted: true,
          }}
          PaperProps={{ sx: { backgroundColor: theme.palette.primary.dark, } }}
        >
          <Sidebar />
        </Drawer>
        <Drawer
          variant="permanent"
          sx={styles.drawerDesktop}
          PaperProps={{ sx: { backgroundColor: theme.palette.primary.dark, } }}
          open
        >
          <Sidebar />
        </Drawer>
      </Box>
      <Box component="main" sx={styles.main}>
        <Toolbar />
        <BreadcrumbsComponent items={breadCrumds} />
        <Box mt={2}>{children}</Box>
      </Box>
    </Box>
  );
};

export default MainLayout;
