import { object } from 'yup';

import { text, number, fileExt } from '@/constants';

export const bookmakerFormSchema = object({
  name: text,
  link: text,
  currency: text,
  bonusLink: text,
  usersTrust: number,
  rating: number,
  paymentMethods: number,
  withdrawFunds: number,
  odds: number,
  bettingLimits: number,
  bettingCalculationSpeed: number,
  bonusesIndicator: number,
  support: number,
  companyReliability: number,
  textGetBonus: number,
  feedback_count: number,
  icon: fileExt(['image/png', 'image/jpg', 'image/jpeg'], true),
}).required();
