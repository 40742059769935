import React from 'react';

import { SportForm } from '@/components';

import { useCreateSportPage } from './useCreateSportPage';

const CreateSportPage = () => {
  const props = useCreateSportPage();
  return <SportForm {...props} />;
};

export default CreateSportPage;
