import { FormControl, TextField, Autocomplete, CircularProgress, Typography } from '@mui/material';
import React, { useEffect, useMemo, useState } from 'react';
import { debounce } from 'throttle-debounce';

import type { IAutoCompleteInputProps } from './types';
import type { AutocompleteValue } from '@mui/material';

function AutoCompleteInput<
  Option = string,
  FormValues extends Record<string, unknown> = Record<string, unknown>,
  Multiple extends boolean | undefined = false,
  DisableClearable extends boolean | undefined = false,
  FreeSolo extends boolean | undefined = false,
  ChipComponent extends React.ElementType = 'div',
>({
  field: { name, value, onBlur },
  form: { touched, errors, setFieldValue },
  getOptions,
  debounceTime = 300,
  textFieldProps,
  ...props
}: IAutoCompleteInputProps<
  Option,
  FormValues,
  Multiple,
  DisableClearable,
  FreeSolo,
  ChipComponent
>) {
  const hasError = Boolean(touched[name] && errors[name]);

  const [isOpen, setOpen] = useState(false);
  const [options, setOptions] = useState<Option[]>([]);
  const [inputValue, setInputValue] = useState('');
  const [isLoading, setLoading] = useState(false);

  const onChange = (
    _: unknown,
    newValue: AutocompleteValue<Option, Multiple, true | DisableClearable, FreeSolo>,
  ) => {
    setFieldValue(name, newValue);
    onBlur({ target: { name, value: newValue } });
  };

  const onInputChange = async (_: unknown, newInputValue: string) => {
    setInputValue(newInputValue);
  };

  const getOptionsDebounced = useMemo<debounce<() => void>>(
    () =>
      debounce(debounceTime, async () => {
        setLoading(true);
        const newOptions = await getOptions(inputValue);
        setOptions(newOptions);
        setLoading(false);
      }),
    [debounceTime, getOptions, inputValue],
  );

  useEffect(() => {
    if (!isOpen) {
      return;
    }
    getOptionsDebounced();

    return () => {
      getOptionsDebounced.cancel();
    };
  }, [getOptionsDebounced, inputValue, isOpen]);
  useEffect(() => {
    if (!isOpen) {
      onBlur({ target: { name, value } });
    }
  }, [isOpen, name, onBlur, value]);

  return (
    <FormControl error={hasError} fullWidth>
      <Typography variant="h6" mb={1}>
        {props.placeholder}
      </Typography>
      <Autocomplete
        size="small"
        disablePortal
        disableClearable
        options={options}
        filterOptions={() => options}
        value={value}
        onChange={onChange}
        onInputChange={onInputChange}
        inputValue={inputValue}
        onOpen={() => {
          setOpen(true);
        }}
        onClose={() => {
          setOpen(false);
        }}
        loading={isLoading}
        {...(props as any)}
        renderInput={(params) => (
          <TextField
            {...textFieldProps}
            {...params}
            inputProps={{ ...textFieldProps?.inputProps, ...params?.inputProps }}
            InputProps={{
              ...textFieldProps?.InputProps,
              ...params?.InputProps,
              endAdornment: (
                <>
                  {isLoading ? <CircularProgress color="inherit" size={20} /> : null}
                  {params.InputProps.endAdornment}
                </>
              ),
            }}
            error={hasError}
            helperText={(hasError ? errors[name] : ' ') as string}
          />
        )}
      />
    </FormControl>
  );
}

export default AutoCompleteInput;
