import { Checkbox, FormControl, FormControlLabel, FormHelperText } from '@mui/material';
import React from 'react';

import type { ICheckboxInputProps } from './types';

const CheckboxInput = ({
  form: { touched, errors },
  field: { name, value, onChange, onBlur },
  checkboxProps,
  ...props
}: ICheckboxInputProps) => {
  const hasError = Boolean(touched[name] && errors[name]);

  return (
    <FormControl error={hasError}>
      <FormControlLabel
        {...props}
        control={
          <Checkbox
            name={name}
            checked={Boolean(value)}
            onChange={onChange}
            onBlur={onBlur}
            {...checkboxProps}
          />
        }
      />
      {hasError && <FormHelperText>{errors[name]}</FormHelperText>}
    </FormControl>
  );
};

export default CheckboxInput;
