export const defaultInitialValues = {
  statusTitle: '',
  about: '',
  social: {
    twitter: '',
    instagram: '',
    facebook: '',
  },
  competence: [],
};
