import {useCallback, useEffect} from 'react';
import {useNavigate} from "react-router-dom";

import { useCreateArticleMutation } from '@/apis/article';
import {Pathes} from "@/constants";
import { useToastMessageRequest } from '@/hooks';

import type { IArticleFormParams } from '@/types';

export const useCreateArticlePage = () => {
  const [createArticle, { isLoading, isSuccess, ...requestParams }] = useCreateArticleMutation();

  useToastMessageRequest({ isSuccess, ...requestParams });

  const navigate = useNavigate();

  useEffect(() => {
    if (isSuccess) {
      navigate(Pathes.Articles);
    }
  }, [isSuccess]);

  const onSubmit = useCallback((params: IArticleFormParams) => {
    createArticle(params);
  }, []);

  return { isLoading, onSubmit };
};
