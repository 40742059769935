export const headCells = [
  { title: 'Id' },
  { title: 'Title' },
  { title: 'Image' },
  { title: 'Type' },
  { title: '' },
];
export const filteringFields = [
  {
    active: true,
    key: 'id',
  },
  {
    active: true,
    key: 'name',
  },
  {
    active: true,
    key: 'icon',
  },
  {
    active: true,
    key: 'bonus',
  },
  {
    active: false,
    key: 'buttons',
  },
];
