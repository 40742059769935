import {
  Button,
  Grid,
  Box,
  MenuItem,
  Typography,
  Chip,
  ListItemText,
  Checkbox,
} from '@mui/material';
import { Field, Form, Formik } from 'formik';
import React from 'react';

import { RichTextEditorInput, SelectInput, TextInput } from '@/components';

import { defaultInitialValues } from './constants';
import { styles } from './styles';
import { useUserExpertForm } from './useUserExpertForm';

import type { IExpertFormParams, IFormProps } from '@/types';

const UserExpertForm = ({
  initialValues = defaultInitialValues,
  onSubmit,
  isLoading,
}: IFormProps<IExpertFormParams>) => {
  const { sports, mapSportById } = useUserExpertForm();

  return (
    <Formik enableReinitialize initialValues={initialValues} onSubmit={onSubmit}>
      {({ values, dirty }) => (
        <Form>
          <Grid container spacing={2} sx={styles.container}>
            <Grid item xs>
              <Field
                type="text"
                name="about"
                autoComplete="about"
                fullWidth
                component={RichTextEditorInput}
                placeholder="About"
                multiline
                rows={3}
              />
            </Grid>
          </Grid>
          <Grid container spacing={2} sx={styles.container}>
            <Grid item xs>
              <Field
                type="text"
                name="statusTitle"
                autoComplete="statusTitle"
                fullWidth
                component={TextInput}
                placeholder="Status title"
              />
            </Grid>
            <Grid item xs>
              <Field
                type="text"
                name="social.facebook"
                autoComplete="social.facebook"
                fullWidth
                component={TextInput}
                placeholder="Facebook"
              />
            </Grid>
          </Grid>
          <Grid container spacing={2} sx={styles.container}>
            <Grid item xs>
              <Field
                type="text"
                name="social.instagram"
                autoComplete="social.instagram"
                fullWidth
                component={TextInput}
                placeholder="Instagram"
              />
            </Grid>
            <Grid item md={6}>
              <Field
                type="text"
                name="social.twitter"
                autoComplete="social.twitter"
                fullWidth
                component={TextInput}
                placeholder="Twitter"
              />
            </Grid>
          </Grid>
          <Grid container spacing={2} sx={styles.container}>
            <Grid item md={12}>
              <Field
                name="competence"
                autoComplete="competence"
                fullWidth
                multiple
                component={SelectInput}
                placeholder="Competence"
                renderValue={(selected: string[]) => (
                  <Box sx={styles.selectedItem}>
                    {selected.map((id) => (
                      <Chip
                        key={id}
                        label={mapSportById[id as keyof typeof mapSportById]}
                        sx={styles.chip}
                      />
                    ))}
                  </Box>
                )}
              >
                <MenuItem value="" disabled>
                  <Typography>Competence</Typography>
                </MenuItem>
                {sports?.items.map(({ id, name }) => (
                  <MenuItem key={id} value={id}>
                    <Checkbox checked={values.competence.includes(id)} />
                    <ListItemText primary={name} />
                  </MenuItem>
                ))}
              </Field>
            </Grid>
          </Grid>
          <Box sx={styles.btnWrapper}>
            <Button
              variant="contained"
              type="submit"
              disabled={!dirty || isLoading}
              sx={styles.btn}
            >
              Save
            </Button>
          </Box>
        </Form>
      )}
    </Formik>
  );
};

export default UserExpertForm;
