import React from 'react';

import { ApiIntegrationForm, ContentForm, LeagueForm } from '@/components';
import { IntegrationEntityType } from '@/types';

import { useEditLeaguePage } from './useEditLeaguePage';

const EditLeaguePage = () => {
  const { league, ...props } = useEditLeaguePage();
  return (
    <>
      <LeagueForm {...props} />
      <ApiIntegrationForm id={league?.id} entityType={IntegrationEntityType.League} />
      <ContentForm id={league?.id} type="league" />
    </>
  );
};

export default EditLeaguePage;
