import { Add } from '@mui/icons-material';
import { Button, Typography } from '@mui/material';
import React from 'react';

import { ArticleSectionTableItem, CustomTable, DeleteArticleSectionModal } from '@/components';

import { headCells, filteringFields } from './constants';
import { useMainArticlesSectionsPage } from './useMainArticlesSectionsPage';

const MainArticlesSectionsPage = () => {
  const { articleSections, goToCreatePage, ...tableProps } = useMainArticlesSectionsPage();
  return (
    <>
      <Button variant="contained">
        <Add />
        <Typography ml={2} fontWeight="bold" onClick={goToCreatePage}>
          Create Articles Section
        </Typography>
      </Button>
      <CustomTable
        headCells={headCells}
        filteringFields={filteringFields}
        deletingModal={DeleteArticleSectionModal}
        {...tableProps}
      >
        {articleSections?.items.map((articleSection) => (
          <ArticleSectionTableItem {...articleSection} key={articleSection.id} />
        ))}
      </CustomTable>
    </>
  );
};

export default MainArticlesSectionsPage;
