import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { useDeleteSportMutation } from '@/apis/sports';
import { useToastMessageRequest } from '@/hooks';
import { sportsStateSelectors } from '@/redux/sports';
import { closeDeleteSportModal } from '@/redux/sports/slice';

export const useDeleteSportModal = () => {
  const dispatch = useDispatch();
  const isOpen = useSelector(sportsStateSelectors.isDeleteSportModalOpenSelector);
  const sport = useSelector(sportsStateSelectors.selectedSportForModalSelector);
  const [deleteSport, { isSuccess, ...requestParams }] = useDeleteSportMutation();

  useToastMessageRequest({ isSuccess, ...requestParams });

  const closeModal = () => {
    dispatch(closeDeleteSportModal());
  };

  const confirmDeleting = () => {
    deleteSport(sport?.id ?? 0);
  };

  useEffect(() => {
    if (isSuccess) {
      closeModal();
    }
  }, [isSuccess]);

  return { isOpen, sport, closeModal, confirmDeleting };
};
