import React from 'react';

import { CustomTable, SeasonTableItem } from '@/components';

import { headCells } from './constants';
import { useMainSeasonsPage } from './useMainSeasonsPage';

const MainSeasonsPage = () => {
  const { seasons, ...tableProps } = useMainSeasonsPage();
  return (
    <>
      <CustomTable headCells={headCells} hideFilters {...tableProps}>
        {seasons?.items.map((season) => (
          <SeasonTableItem {...season} key={season.id} />
        ))}
      </CustomTable>
    </>
  );
};

export default MainSeasonsPage;
