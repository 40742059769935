import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/dist/query/react';

import { ApiRoutes } from '@/constants';
import { setAccessTokenToHeaders } from '@/helpers';
import { envUtil } from '@/utils';

import type { INewsFormParams, INewsResponse, IRequestParams, IResponse } from '@/types';

const { api } = envUtil.getEnv();

export const newsApi = createApi({
  reducerPath: 'newsApi',
  baseQuery: fetchBaseQuery({
    baseUrl: api,
    prepareHeaders: (headers) => setAccessTokenToHeaders(headers),
  }),
  tagTypes: ['NewsList', 'News'],
  endpoints: (builder) => ({
    getNews: builder.query<IResponse<INewsResponse>, IRequestParams>({
      query: (params) => ({
        url: ApiRoutes.News,
        params,
      }),
      providesTags: (result) =>
        result
          ? [
              ...result.items.map(({ id }) => ({
                type: 'NewsList' as const,
                id,
              })),
              { type: 'NewsList', id: 'LIST' },
            ]
          : [{ type: 'NewsList', id: 'LIST' }],
      keepUnusedDataFor: 1,
    }),
    getNewsById: builder.query<INewsResponse, string>({
      query: (id) => ({
        url: `${ApiRoutes.News}/get/${id}`,
      }),
      providesTags: () => ['News'],
    }),
    createNews: builder.mutation<
      INewsResponse,
      Omit<INewsFormParams, 'league'> & { leagueId: number }
    >({
      query: (body) => ({
        url: ApiRoutes.News,
        body,
        method: 'POST',
      }),
    }),
    editNews: builder.mutation<
      INewsResponse,
      Omit<INewsFormParams, 'league'> & { id: string; leagueId: number }
    >({
      query: ({ id, ...body }) => ({
        url: `${ApiRoutes.News}/${id}`,
        body,
        method: 'PUT',
      }),
      invalidatesTags: ['News'],
    }),
    deleteNews: builder.mutation<void, number>({
      query: (id) => ({
        url: `${ApiRoutes.News}/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: [{ type: 'NewsList', id: 'LIST' }],
    }),
  }),
});

export const {
  useGetNewsQuery,
  useCreateNewsMutation,
  useGetNewsByIdQuery,
  useEditNewsMutation,
  useDeleteNewsMutation,
} = newsApi;
