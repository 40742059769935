import { createSelector } from '@reduxjs/toolkit';

import type { ILeaguesState } from './types';
import type { RootState } from '@/redux/store';

export const leaguesStateSelector = (state: RootState) => state.leagues;

export const selectedLeagueForModalSelector = createSelector(
  leaguesStateSelector,
  (state: ILeaguesState) => state.selectedLeagueForModal,
);

export const isDeleteLeagueModalOpenSelector = createSelector(
  leaguesStateSelector,
  (state: ILeaguesState) => state.isDeleteLeagueModalOpen,
);
