import { Delete, Edit } from '@mui/icons-material';
import { Box, IconButton, TableCell, TableRow } from '@mui/material';
import React from 'react';

import { useTableItem } from '@/hooks';
import { openDeleteLeagueModal } from '@/redux/leagues/slice';

import { styles } from './styles';

import type { ILeagueResponse } from '@/types';

const LeagueTableItem: React.FC<ILeagueResponse> = (league) => {
  const { rank, logo, id, name, sport, section, order, slug, nameFs } = league;
  const { onEdit, onDelete } = useTableItem(openDeleteLeagueModal);
  return (
    <TableRow>
      <TableCell component="th" scope="row" width={200}>
        {id}
      </TableCell>
      <TableCell component="th" scope="row" width={200}>
        {name}
      </TableCell>
      <TableCell component="th" scope="row" width={200}>
        {nameFs}
      </TableCell>
      <TableCell component="th" scope="row">
        <Box component="img" src={logo as string} sx={styles.icon} />
      </TableCell>
      <TableCell component="th" scope="row">
        {rank}
      </TableCell>
      <TableCell component="th" scope="row">
        {sport?.name ?? ''}
      </TableCell>
      <TableCell component="th" scope="row">
        {section?.name ?? ''}
      </TableCell>
      <TableCell component="th" scope="row">
        {order}
      </TableCell>
      <TableCell width={100}>
        <IconButton onClick={onEdit(slug)}>
          <Edit />
        </IconButton>
        <IconButton onClick={onDelete(league)}>
          <Delete />
        </IconButton>
      </TableCell>
    </TableRow>
  );
};

export default LeagueTableItem;
