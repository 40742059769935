import { Add } from '@mui/icons-material';
import { Button, Typography } from '@mui/material';
import React from 'react';

import { BookmakerTableItem, CustomTable, DeleteBookmakerModal } from '@/components';

import { filteringFields, headCells } from './constants';
import { useMainBookmakersPage } from './useMainBookmakersPage';

const MainBookmakersPage = () => {
  const { bookmakers, goToCreateBookmakerPage, ...tableProps } = useMainBookmakersPage();
  return (
    <>
      <Button variant="contained">
        <Add />
        <Typography ml={2} fontWeight="bold" onClick={goToCreateBookmakerPage}>
          Create Bookmaker
        </Typography>
      </Button>
      <CustomTable
        headCells={headCells}
        filteringFields={filteringFields}
        deletingModal={DeleteBookmakerModal}
        {...tableProps}
      >
        {bookmakers?.items.map((user) => (
          <BookmakerTableItem {...user} key={user.id} />
        ))}
      </CustomTable>
    </>
  );
};

export default MainBookmakersPage;
