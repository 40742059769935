import { getCountriesForFilters } from '@/constants';
import { FilterTypes, FilterValues } from '@/types';

export const headCells = [
  { title: 'Id', width: 200 },
  { title: 'Name', width: 300 },
  { title: 'Name Fs', width: 300 },
  { title: 'Logo', width: 100 },
  { title: 'Rank', width: 200 },
  { title: 'Sport', width: 250 },
  { title: 'Country', width: 250 },
  { title: 'Order', width: 200 },
  { title: '', width: 150 },
];

export const getFilteringFields = (sports: FilterValues[]) => [
  { name: 'id', type: FilterTypes.Text },
  { name: 'name', type: FilterTypes.Text },
  { name: 'nameFs', type: FilterTypes.Text },
  null,
  { name: 'rank', type: FilterTypes.Text },
  { name: 'sport', type: FilterTypes.Select, values: sports },
  { name: 'country', type: FilterTypes.Select, values: getCountriesForFilters() },
  { name: 'order', type: FilterTypes.Text },
];
