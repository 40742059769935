import { Button, DialogActions, Grid, Typography } from '@mui/material';
import { Field, Form, Formik } from 'formik';
import React from 'react';

import { CheckboxInput, RichTextEditorInput, TextInput } from '@/components';

import { defaultInitialValues } from './constants';
import { styles } from './styles';

import type { IFormProps, ISportFormParams } from '@/types';

const SportForm: React.FC<IFormProps<ISportFormParams>> = ({
  initialValues = defaultInitialValues,
  onSubmit,
  isLoading,
}) => {
  return (
    <Formik enableReinitialize initialValues={initialValues} onSubmit={onSubmit}>
      {({ dirty }) => (
        <Form>
          <Grid container spacing={2} sx={styles.container}>
            <Grid item xs>
              <Field
                type="text"
                name="name"
                autoComplete="name"
                fullWidth
                component={TextInput}
                placeholder="Name"
              />
            </Grid>
            <Grid item xs>
              <Field
                type="number"
                name="order"
                autoComplete="order"
                fullWidth
                component={TextInput}
                placeholder="Order"
                InputProps={{ inputProps: { min: 0 } }}
              />
            </Grid>
          </Grid>
          <Grid container spacing={2} sx={styles.container}>
            <Grid item xs>
              <Field
                type="text"
                name="externalId"
                autoComplete="externalId"
                fullWidth
                component={TextInput}
                placeholder="External Id"
              />
            </Grid>
            <Grid item xs>
              <Field
                type="text"
                name="source"
                autoComplete="source"
                fullWidth
                component={TextInput}
                placeholder="Source"
              />
            </Grid>
          </Grid>
          <Grid container spacing={2} sx={styles.container}>
            <Grid item xs>
              <Field
                type="text"
                name="slug"
                autoComplete="slug"
                fullWidth
                component={TextInput}
                placeholder="Slug"
              />
            </Grid>
            <Grid item xs></Grid>
          </Grid>
          <Grid container spacing={2} sx={styles.container}>
            <Grid item xs={12}>
              <Field
                name="seoText"
                autoComplete="seoText"
                fullWidth
                component={RichTextEditorInput}
                placeholder="SEO text"
              />
            </Grid>
            <Grid item xs={12}>
              <Field
                name="seoForecastPage"
                autoComplete="seoForecastPage"
                fullWidth
                component={RichTextEditorInput}
                placeholder="SEO text for the prediction page"
              />
            </Grid>
          </Grid>
          <Grid container spacing={2} sx={styles.container}>
            <Grid item xs>
              <Field
                name="isActive"
                component={CheckboxInput}
                label={<Typography variant="h6">Status</Typography>}
              />
            </Grid>
          </Grid>
          <DialogActions>
            <Button
              variant="contained"
              type="submit"
              disabled={isLoading || !dirty}
              sx={styles.btn}
            >
              Save
            </Button>
          </DialogActions>
        </Form>
      )}
    </Formik>
  );
};

export default SportForm;
