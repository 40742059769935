import { useNavigate } from 'react-router-dom';

import { useGetSportsListQuery } from '@/apis/sports';
import { usePagination, useTableFilters } from '@/hooks';

import { filteringFields } from './constants';
export const useMainSportsPage = () => {
  const navigate = useNavigate();
  const paginationParams = usePagination();
  const { page, rowsPerPage: limit } = paginationParams;
  const [filters, onFiltersChange] = useTableFilters(filteringFields);

  const { data: sports, isLoading } = useGetSportsListQuery({
    limit,
    page: page + 1,
    ...filters,
  });

  const count = (sports?.meta.totalPages ?? 1) * limit;

  const noData = !sports?.items.length;

  const goToCreatePage = () => {
    navigate('create');
  };

  return {
    isLoading,
    count,
    noData,
    sports,
    onFiltersChange,
    goToCreatePage,
    ...paginationParams,
  };
};
