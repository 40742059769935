import React from 'react';

import { BookmakerReviewForm } from '@/components';

import { useEditBookmakerReviewsPage } from './useEditBookmakerReviewsPage';

const EditBookmakerReviewsPage = () => {
  const props = useEditBookmakerReviewsPage();
  return <BookmakerReviewForm {...props} />;
};

export default EditBookmakerReviewsPage;
