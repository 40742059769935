import { useCallback, useMemo } from 'react';
import { useParams } from 'react-router-dom';

import {
  useEditBookmakerReviewMutation,
  useGetBookmakerReviewByIdQuery,
} from '@/apis/bookmaeker-reviews';
import { useToastMessageRequest } from '@/hooks';

import type { IBookmakerReviewFormParams } from '@/types';

export const useEditBookmakerReviewsPage = () => {
  const params = useParams();
  const id = params.id as string;
  const { data: review } = useGetBookmakerReviewByIdQuery(id);
  const [editReview, { isLoading, ...editBonusParams }] = useEditBookmakerReviewMutation();

  useToastMessageRequest(editBonusParams);

  const initialValues = useMemo(
    () => ({
      text: review?.text ?? '',
      bookmakerId: review?.bookmaker?.id ?? '',
    }),
    [review],
  );

  const onSubmit = useCallback(
    (params: IBookmakerReviewFormParams) => {
      editReview({ id, ...params });
    },
    [id],
  );
  return { isLoading, initialValues, onSubmit };
};
