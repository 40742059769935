import { useCallback, useMemo } from 'react';
import { useParams } from 'react-router-dom';

import { useEditTeamMutation, useGetTeamByIdQuery } from '@/apis/teams';
import { useToastMessageRequest } from '@/hooks';

import type { ITeamFormParams } from '@/types';

export const useEditTeamPage = () => {
  const params = useParams();
  const id = params.id as string;
  const { data: team } = useGetTeamByIdQuery(id);

  const [editTeam, { isLoading, ...editParams }] = useEditTeamMutation();

  useToastMessageRequest(editParams);

  const initialValues = useMemo(
    () => ({
      name: team?.name ?? '',
      logo: team?.logo ?? '',
      description: team?.description ?? '',
      rating: team?.rating ?? 0,
      fsExternalId: team?.fsExternalId ?? ''
    }),
    [team],
  );

  const onSubmit = useCallback(
    (params: ITeamFormParams) => {
      editTeam({ id, ...params });
    },
    [id],
  );
  return { team, initialValues, isLoading, onSubmit };
};
