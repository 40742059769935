import { createSlice } from '@reduxjs/toolkit';

import type { IBookmakerReviewsState } from './types';

const initialState: IBookmakerReviewsState = {
  isDeleteBookmakerReviewModalOpen: false,
  selectedBookmakerReviewForModal: null,
};

export const bookmakerReviewsSlice = createSlice({
  name: 'bookmakerReviews',
  initialState,
  reducers: {
    openDeleteBookmakerReviewModal(state: IBookmakerReviewsState, { payload: review }) {
      state.isDeleteBookmakerReviewModalOpen = true;
      state.selectedBookmakerReviewForModal = review;
    },
    closeDeleteBookmakerReviewModal(state: IBookmakerReviewsState) {
      state.isDeleteBookmakerReviewModalOpen = false;
      state.selectedBookmakerReviewForModal = null;
    },
  },
});

export const { openDeleteBookmakerReviewModal, closeDeleteBookmakerReviewModal } =
  bookmakerReviewsSlice.actions;

export default bookmakerReviewsSlice.reducer;
