import { useEffect } from 'react';

import { useLazyGetLanguagesQuery } from '@/apis/languages';

import type { FieldArrayRenderProps } from 'formik';

export const useArticleSectionForm = (withTranslation: boolean) => {
  const [getLanguages, { data: languages }] = useLazyGetLanguagesQuery();

  useEffect(() => {
    if (withTranslation && !languages) {
      getLanguages({ limit: 10, page: 1 });
    }
  }, [withTranslation]);

  const addNewTranslation = (arrayHelpers: FieldArrayRenderProps) => () => {
    arrayHelpers.push({
      name: '',
      language: { name: '' },
    });
  };

  const removeTranslation = (arrayHelpers: FieldArrayRenderProps, idx: number) => () => {
    arrayHelpers.remove(idx);
  };

  return { languages, addNewTranslation, removeTranslation };
};
