import { Checkbox, Box, FormControlLabel, Typography } from '@mui/material';
import React from 'react';

import { CustomTable, EventTableItem } from '@/components';

import { headCells } from './constants';
import { styles } from './styles';
import { useMainEventsPage } from './useMainEventsPage';

const MainEventsPage = () => {
  const {
    isLeagueFsSyncOn,
    isShowSyncFails,
    onToggleIsLeagueFsSyncOn,
    onToggleIsShowSyncFails,
    events,
    ...tableProps
  } = useMainEventsPage();
  return (
    <>
      <Box>
        <Box>
          <FormControlLabel
            control={<Checkbox checked={isLeagueFsSyncOn} onChange={onToggleIsLeagueFsSyncOn} />}
            label={<Typography variant="h6">isLeagueFsSyncOn</Typography>}
          />
        </Box>
        <Box>
          <FormControlLabel
            control={<Checkbox checked={isShowSyncFails} onChange={onToggleIsShowSyncFails} />}
            label={<Typography variant="h6">isShowSyncFails</Typography>}
          />
        </Box>
      </Box>
      <CustomTable headCells={headCells} {...tableProps} sx={styles.table}>
        {events?.items.map((event) => (
          <EventTableItem {...event} key={event.id} />
        ))}
      </CustomTable>
    </>
  );
};

export default MainEventsPage;
