import { Delete, Add } from '@mui/icons-material';
import { Button, DialogActions, Grid, MenuItem, Typography } from '@mui/material';
import { Field, FieldArray, Form, Formik, getIn } from 'formik';
import React from 'react';

import { SelectInput, TextInput } from '@/components';

import { defaultInitialValues } from './constants';
import { articleSectionFormSchema } from './schemas';
import { styles } from './styles';
import { useArticleSectionForm } from './useArticleSectionForm';

import type { IFormProps, IArticleSectionFormParams } from '@/types';

const ArticleSectionForm: React.FC<
  IFormProps<IArticleSectionFormParams> & { withTranslation?: boolean }
> = ({ initialValues = defaultInitialValues, onSubmit, isLoading, withTranslation = false }) => {
  const { languages, addNewTranslation, removeTranslation } =
    useArticleSectionForm(withTranslation);

  return (
    <Formik
      enableReinitialize
      validationSchema={articleSectionFormSchema}
      initialValues={initialValues}
      onSubmit={onSubmit}
    >
      {({ values, dirty, errors }) => {
        return (
          <Form>
            <Grid container spacing={2} sx={styles.container}>
              <Grid item xs>
                <Field
                  type="text"
                  name="name"
                  autoComplete="name"
                  fullWidth
                  component={TextInput}
                  placeholder="Name"
                />
              </Grid>
              <Grid item xs>
                <Field
                  type="number"
                  name="order"
                  autoComplete="order"
                  fullWidth
                  component={TextInput}
                  placeholder="Order"
                  InputProps={{ inputProps: { min: 0 } }}
                />
              </Grid>
            </Grid>
            {withTranslation && (
              <>
                <Typography variant="h4" mb={2}>
                  Translation
                </Typography>
                <FieldArray
                  name="translations"
                  render={(arrayHelpers) => (
                    <>
                      {values.translations &&
                        values?.translations.length > 0 &&
                        values.translations.map((translation, index) => {
                          const fieldName = `translations[${index}]`;
                          const error = getIn(errors, fieldName);
                          return (
                            <Grid container spacing={2} key={index}>
                              <Grid item xs>
                                <Field
                                  type="text"
                                  name={`translations.${index}.name`}
                                  autoComplete={`translations.${index}.name`}
                                  fullWidth
                                  component={TextInput}
                                  placeholder="Name"
                                  error={!!error?.name}
                                />
                              </Grid>
                              <Grid item xs>
                                <Field
                                  name={`translations.${index}.language.name`}
                                  autoComplete={`translations.${index}.language.name`}
                                  fullWidth
                                  component={SelectInput}
                                  placeholder="Language"
                                  error={error?.language?.name}
                                >
                                  <MenuItem value="" disabled>
                                    <Typography>Language</Typography>
                                  </MenuItem>
                                  {languages?.items.map(({ name }) => (
                                    <MenuItem value={name} key={name}>
                                      <Typography>{name}</Typography>
                                    </MenuItem>
                                  ))}
                                </Field>
                              </Grid>
                              <Grid item sx={styles.btnContainer}>
                                <Button
                                  variant="contained"
                                  color="error"
                                  sx={styles.deleteBtn}
                                  onClick={removeTranslation(arrayHelpers, index)}
                                >
                                  <Delete />
                                </Button>
                              </Grid>
                            </Grid>
                          );
                        })}
                      <Button variant="contained" onClick={addNewTranslation(arrayHelpers)}>
                        <Add />
                      </Button>
                    </>
                  )}
                />
              </>
            )}
            <DialogActions>
              <Button
                variant="contained"
                type="submit"
                disabled={isLoading || !dirty}
                sx={styles.btn}
              >
                Save
              </Button>
            </DialogActions>
          </Form>
        );
      }}
    </Formik>
  );
};

export default ArticleSectionForm;
