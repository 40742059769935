export const styles = {
  container: {
    display: 'flex',
    flexDirection: {
      md: 'row',
      xs: 'column-reverse',
    },
  },
  btnWrapper: {
    width: '100%',
    display: 'flex',
    justifyContent: 'flex-end',
  },
  btn: {
    width: 120,
  },
  selectedItem: {
    display: 'flex',
    flexWrap: 'wrap',
    gap: 0.5,
  },
  chip: {
    height: 20,
  },
} as const;
