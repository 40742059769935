import { FilterTypes } from '@/types';

export const headCells = [
  { title: 'Id', width: 300 },
  { title: 'Name', width: 300 },
  { title: 'Name Fs', width: 300 },
  { title: '', width: 20 },
];

export const filteringFields = [
  { name: 'id', type: FilterTypes.Text },
  { name: 'name', type: FilterTypes.Text },
  { name: 'nameFs', type: FilterTypes.Text },
];
