import { FilterTypes, FilterValues } from '@/types';

export const headCells = [
  { title: 'Id', width: 200 },
  { title: 'Name', width: 200 },
  { title: 'Article section', width: 200 },
  { title: 'Sport', width: 200 },
  { title: '', width: 100 },
];

export const getFilteringFields = (sports: FilterValues[], articleSections: FilterValues[]) => [
  { name: 'id', type: FilterTypes.Text },
  { name: 'title', type: FilterTypes.Text },
  { name: 'articleSectionId', type: FilterTypes.Select, values: articleSections },
  { name: 'sport', type: FilterTypes.Select, values: sports },
  null,
];
