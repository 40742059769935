import { Pathes } from '@/constants';
import { MainLayout } from '@/layouts';
import { IRouterParams } from '@/router/types';
import {
  CreateArticlePage,
  CreateArticleSectionPage,
  CreateBookmakerBonusPage,
  CreateBookmakerPage,
  CreateBookmakerReviewsPage,
  CreateExpertsPage,
  CreateNewsPage,
  CreateSportPage,
  CreateUserPage,
  EditArticlePage,
  EditArticlesSectionsPage,
  EditBookmakerBonusPage,
  EditBookmakerPage,
  EditBookmakerReviewsPage,
  EditChallengePage,
  EditEventPage,
  EditLeaguePage,
  EditManagersPage,
  EditNewsPage,
  EditPlayersPage,
  EditSeasonPage,
  EditSectionsPage,
  EditSportPage,
  EditTeamPage,
  EditUserPage,
  EditVenuePage,
  MainArticlesPage,
  MainArticlesSectionsPage,
  MainBookmakerBonusesPage,
  MainBookmakerReviewsPage,
  MainBookmakersPage,
  MainChallengePage,
  MainEventsPage,
  MainLeaguesPage,
  MainManagersPage,
  MainNewsPage,
  MainPlayersPage,
  MainSeasonsPage,
  MainSectionsPage,
  MainSportsPage,
  MainTeamsPage,
  MainUserPage,
  MainVenuesPage,
  SiteSettingsPage,
  MainCronsPage,
  MainLogsPage,
  ViewLogPage,
  ViewCronPage,
} from '@/views';
import { PredictionsStat} from "@/views/predictions";

const getPathForViewingById = (path: string) => `${path}/view/:id`;
const getPathForEditingById = (path: string) => `${path}/edit/:id`;
const getPathForEditingBySlug = (path: string) => `${path}/edit/:slug`;
const getPathForCreating = (path: string) => `${path}/create`;

const routes: IRouterParams[] = [
  {
    component: MainUserPage,
    layout: MainLayout,
    path: Pathes.Users,
    title: 'Users',
    breadCrumds: [{ title: 'Users', path: Pathes.Users }],
  },
  {
    component: EditUserPage,
    layout: MainLayout,
    path: getPathForEditingById(Pathes.Users),
    title: 'Edit User',
    breadCrumds: [{ title: 'Users', path: Pathes.Users }, { title: 'Edit User' }],
  },
  {
    component: CreateUserPage,
    layout: MainLayout,
    path: getPathForCreating(Pathes.Users),
    title: 'Create User',
    breadCrumds: [{ title: 'Users', path: Pathes.Users }, { title: 'Create User' }],
  },
  {
    component: MainManagersPage,
    layout: MainLayout,
    path: Pathes.Managers,
    title: 'Managers',
    breadCrumds: [{ title: 'Managers', path: Pathes.Managers }],
  },
  {
    component: EditManagersPage,
    layout: MainLayout,
    path: getPathForEditingById(Pathes.Managers),
    title: 'Edit Manager',
    breadCrumds: [{ title: 'Managers', path: Pathes.Managers }, { title: 'Edit Manager' }],
  },
  {
    component: MainVenuesPage,
    layout: MainLayout,
    path: Pathes.Venues,
    title: 'Venues',
    breadCrumds: [{ title: 'Venues', path: Pathes.Venues }],
  },
  {
    component: EditVenuePage,
    layout: MainLayout,
    path: getPathForEditingById(Pathes.Venues),
    title: 'Edit Venue',
    breadCrumds: [{ title: 'Venues', path: Pathes.Venues }, { title: 'Edit Venue' }],
  },
  {
    component: MainSeasonsPage,
    layout: MainLayout,
    path: Pathes.Seasons,
    title: 'Seasons',
    breadCrumds: [{ title: 'Seasons', path: Pathes.Seasons }],
  },
  {
    component: EditSeasonPage,
    layout: MainLayout,
    path: getPathForEditingById(Pathes.Seasons),
    title: 'Edit Season',
    breadCrumds: [{ title: 'Seasons', path: Pathes.Seasons }, { title: 'Edit Season' }],
  },
  {
    component: MainSectionsPage,
    layout: MainLayout,
    path: Pathes.Sections,
    title: 'Sections',
    breadCrumds: [{ title: 'Sections', path: Pathes.Sections }],
  },
  {
    component: EditSectionsPage,
    layout: MainLayout,
    path: getPathForEditingById(Pathes.Sections),
    title: 'Edit Sections',
    breadCrumds: [{ title: 'Sections', path: Pathes.Sections }, { title: 'Edit Section' }],
  },
  {
    component: MainChallengePage,
    layout: MainLayout,
    path: Pathes.Challenges,
    title: 'Challenges',
    breadCrumds: [{ title: 'Challenges', path: Pathes.Challenges }],
  },
  {
    component: EditChallengePage,
    layout: MainLayout,
    path: getPathForEditingById(Pathes.Challenges),
    title: 'Edit Challenges',
    breadCrumds: [{ title: 'Challenges', path: Pathes.Challenges }, { title: 'Edit Challange' }],
  },
  {
    component: SiteSettingsPage,
    layout: MainLayout,
    path: Pathes.Settings,
    title: 'Site settings',
    breadCrumds: [{ title: 'Site settings', path: Pathes.Settings }],
  },
  {
    component: MainBookmakersPage,
    layout: MainLayout,
    path: Pathes.Bookmakers,
    title: 'Bookmakers',
    breadCrumds: [{ title: 'Bookmakers', path: Pathes.Bookmakers }],
  },
  {
    component: EditBookmakerPage,
    layout: MainLayout,
    path: getPathForEditingById(Pathes.Bookmakers),
    title: 'Bookmaker Edit',
    breadCrumds: [{ title: 'Bookmakers', path: Pathes.Bookmakers }, { title: 'Bookmaker Edit' }],
  },
  {
    component: CreateBookmakerPage,
    layout: MainLayout,
    path: getPathForCreating(Pathes.Bookmakers),
    title: 'Create bookmaker',
    breadCrumds: [{ title: 'Bookmakers', path: Pathes.Bookmakers }, { title: 'Create bookmaker' }],
  },
  {
    component: MainLeaguesPage,
    layout: MainLayout,
    path: Pathes.Leagues,
    title: 'Leagues',
    breadCrumds: [{ title: 'Leagues', path: Pathes.Leagues }],
  },
  {
    component: EditLeaguePage,
    layout: MainLayout,
    path: getPathForEditingBySlug(Pathes.Leagues),
    title: 'Leagues',
    breadCrumds: [{ title: 'Leagues', path: Pathes.Leagues }, { title: 'Edit League' }],
  },
  {
    component: MainBookmakerBonusesPage,
    layout: MainLayout,
    path: Pathes.BookmakerBonuses,
    title: 'Bookmaker Bonuses',
    breadCrumds: [{ title: 'Bookmaker Bonuses', path: Pathes.BookmakerBonuses }],
  },
  {
    component: CreateBookmakerBonusPage,
    layout: MainLayout,
    path: getPathForCreating(Pathes.BookmakerBonuses),
    title: 'Create Bookmaker Bonus',
    breadCrumds: [
      { title: 'Bookmaker Bonuses', path: Pathes.BookmakerBonuses },
      { title: 'Create Bookmaker Bonus' },
    ],
  },
  {
    component: EditBookmakerBonusPage,
    layout: MainLayout,
    path: getPathForEditingById(Pathes.BookmakerBonuses),
    title: 'Edit Bookmaker Bonus',
    breadCrumds: [
      { title: 'Bookmaker Bonuses', path: Pathes.BookmakerBonuses },
      { title: 'Edit Bookmaker Bonus' },
    ],
  },
  {
    component: MainBookmakerReviewsPage,
    layout: MainLayout,
    path: Pathes.BookmakerReviews,
    title: 'Bookmaker Reviews',
    breadCrumds: [{ title: 'Bookmaker Reviews', path: Pathes.BookmakerReviews }],
  },
  {
    component: CreateBookmakerReviewsPage,
    layout: MainLayout,
    path: getPathForCreating(Pathes.BookmakerReviews),
    title: 'Create Bookmaker Review',
    breadCrumds: [
      { title: 'Bookmaker Reviews', path: Pathes.BookmakerReviews },
      { title: 'Create Bookmaker Review' },
    ],
  },
  {
    component: EditBookmakerReviewsPage,
    layout: MainLayout,
    path: getPathForEditingById(Pathes.BookmakerReviews),
    title: 'Edit Bookmaker Review',
    breadCrumds: [
      { title: 'Bookmaker Reviews', path: Pathes.BookmakerReviews },
      { title: 'Edit Bookmaker Review' },
    ],
  },
  {
    component: CreateExpertsPage,
    layout: MainLayout,
    path: getPathForCreating(Pathes.Experts),
    title: 'Create Expert',
    breadCrumds: [
      { title: 'Bookmaker Reviews', path: Pathes.BookmakerReviews },
      { title: 'Create Expert' },
    ],
  },
  {
    component: MainTeamsPage,
    layout: MainLayout,
    path: Pathes.Teams,
    title: 'Teams',
    breadCrumds: [{ title: 'Teams', path: Pathes.Teams }],
  },
  {
    component: EditTeamPage,
    layout: MainLayout,
    path: getPathForEditingById(Pathes.Teams),
    title: 'Edit Team',
    breadCrumds: [{ title: 'Teams', path: Pathes.Teams }, { title: 'Edit Team' }],
  },
  {
    component: MainPlayersPage,
    layout: MainLayout,
    path: Pathes.Players,
    title: 'Players',
    breadCrumds: [{ title: 'Players', path: Pathes.Players }],
  },
  {
    component: EditPlayersPage,
    layout: MainLayout,
    path: getPathForEditingById(Pathes.Players),
    title: 'Edit Player',
    breadCrumds: [{ title: 'Players', path: Pathes.Players }, { title: 'Edit Player' }],
  },
  {
    component: MainSportsPage,
    layout: MainLayout,
    path: Pathes.Sports,
    title: 'Sports',
    breadCrumds: [{ title: 'Sports', path: Pathes.Sports }],
  },
  {
    component: EditSportPage,
    layout: MainLayout,
    path: getPathForEditingById(Pathes.Sports),
    title: 'Edit Sport',
    breadCrumds: [{ title: 'Sports', path: Pathes.Sports }, { title: 'Edit Sport' }],
  },
  {
    component: CreateSportPage,
    layout: MainLayout,
    path: getPathForCreating(Pathes.Sports),
    title: 'Create Sport',
    breadCrumds: [{ title: 'Sports', path: Pathes.Sports }, { title: 'Create Sport' }],
  },
  {
    component: MainArticlesSectionsPage,
    layout: MainLayout,
    path: Pathes.ArticlesSections,
    title: 'Articles Sections',
    breadCrumds: [{ title: 'Articles section', path: Pathes.ArticlesSections }],
  },
  {
    component: EditArticlesSectionsPage,
    layout: MainLayout,
    path: getPathForEditingById(Pathes.ArticlesSections),
    title: 'Edit Article Section',
    breadCrumds: [
      { title: 'Articles sections', path: Pathes.ArticlesSections },
      { title: 'Edit Article Section' },
    ],
  },
  {
    component: CreateArticleSectionPage,
    layout: MainLayout,
    path: getPathForCreating(Pathes.ArticlesSections),
    title: 'Create Articles Section',
    breadCrumds: [
      { title: 'Articles sections', path: Pathes.ArticlesSections },
      { title: 'Create Articles Section' },
    ],
  },
  {
    component: MainArticlesPage,
    layout: MainLayout,
    path: Pathes.Articles,
    title: 'Articles',
    breadCrumds: [{ title: 'Articles', path: Pathes.Articles }],
  },
  {
    component: CreateArticlePage,
    layout: MainLayout,
    path: getPathForCreating(Pathes.Articles),
    title: 'Create Article',
    breadCrumds: [{ title: 'Articles', path: Pathes.Articles }, { title: 'Create article' }],
  },
  {
    component: EditArticlePage,
    layout: MainLayout,
    path: getPathForEditingById(Pathes.Articles),
    title: 'Edit Article',
    breadCrumds: [{ title: 'Articles', path: Pathes.Articles }, { title: 'Edit Article' }],
  },
  {
    component: MainNewsPage,
    layout: MainLayout,
    path: Pathes.News,
    title: 'News',
    breadCrumds: [{ title: 'News', path: Pathes.News }],
  },
  {
    component: CreateNewsPage,
    layout: MainLayout,
    path: getPathForCreating(Pathes.News),
    title: 'Create News',
    breadCrumds: [{ title: 'News', path: Pathes.News }, { title: 'Create News' }],
  },
  {
    component: EditNewsPage,
    layout: MainLayout,
    path: getPathForEditingById(Pathes.News),
    title: 'Edit News',
    breadCrumds: [{ title: 'News', path: Pathes.News }, { title: 'Edit news' }],
  },
  {
    component: MainEventsPage,
    layout: MainLayout,
    path: Pathes.Events,
    title: 'Events',
    breadCrumds: [{ title: 'Events', path: Pathes.Events }],
  },
  {
    component: EditEventPage,
    layout: MainLayout,
    path: getPathForEditingById(Pathes.Events),
    title: 'Edit Event',
    breadCrumds: [{ title: 'Events', path: Pathes.Events }, { title: 'Edit Event' }],
  },
  {
    component: MainLogsPage,
    layout: MainLayout,
    path: Pathes.Logs,
    title: 'Logs',
    breadCrumds: [{ title: 'Logs', path: Pathes.Logs }],
  },
  {
    component: ViewLogPage,
    layout: MainLayout,
    path: getPathForViewingById(Pathes.Logs),
    title: 'View log',
    breadCrumds: [{ title: 'Logs', path: Pathes.Logs }, { title: 'View log' }],
  },
  {
    component: MainCronsPage,
    layout: MainLayout,
    path: Pathes.Crons,
    title: 'Crons',
    breadCrumds: [{ title: 'Crons', path: Pathes.Crons }],
  },
  {
    component:  ViewCronPage,
    layout: MainLayout,
    path: getPathForViewingById(Pathes.Crons),
    title: 'View cron',
    breadCrumds: [{ title: 'Crons', path: Pathes.Crons }, { title: 'View cron' }],
  },
  {
    component: PredictionsStat,
    layout: MainLayout,
    path: Pathes.Predictions,
    title: 'Prediction statistic',
    breadCrumds: [{ title: 'Predictions', path: Pathes.Predictions }, { title: 'Prediction statistic' }],
  },
];

export default routes;
