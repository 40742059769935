import { Box, Button, DialogActions, MenuItem, Typography } from '@mui/material';
import { Field, Form, Formik } from 'formik';
import React from 'react';

import { RichTextEditorInput, SelectInput } from '@/components';
import { contentFormSchema } from "@/components/forms/ContentForm/schemas";
import { useContentForm } from "@/components/forms/ContentForm/useContentForm";
import { IContentFormParams } from "@/types/forms/content";

import type { SelectChangeEvent } from '@mui/material';

const ContentForm: React.FC<IContentFormParams> = ({
  id,
  type,
}) => {

  const {
    createContentLoading,
    editContentLoading,
    languages,
    initialValues,
    setCurrentLanguageId,
    handleSubmit
  } = useContentForm({ id, type });

  return (
    <Box mt={7}>
      <Typography variant="h5">SEO content</Typography>
      <Formik
        enableReinitialize
        validationSchema={contentFormSchema}
        initialValues={initialValues}
        onSubmit={handleSubmit}
      >
        {({ handleChange, setFieldValue }) => (
          <Form>
            <Field
              name="languageId"
              autoComplete="languageId"
              fullWidth
              component={SelectInput}
              placeholder="Language"
              onChange={(e: SelectChangeEvent<{ languageId: number }>) => {
                const value = e.target.value as unknown as number;
                handleChange(e);
                setCurrentLanguageId(value);
                setFieldValue('content', '');
              }}
            >
              {languages?.items.map(({ name, id: languageId }) => (
                <MenuItem value={languageId} key={name}>
                  <Typography>{name}</Typography>
                </MenuItem>
              ))}
            </Field>
            <Field
              type="text"
              name="content"
              autoComplete="content"
              fullWidth
              component={RichTextEditorInput}
              placeholder="Content"
              multiline
              rows={3}
            />
            <DialogActions>
              <Button
                variant="contained"
                type="submit"
                disabled={createContentLoading || editContentLoading}
              >
                Save
              </Button>
            </DialogActions>
          </Form>
        )}
      </Formik>
    </Box>
  );
};

export default ContentForm;
