import { createSelector } from '@reduxjs/toolkit';

import type { IPlayersState } from './types';
import type { RootState } from '@/redux/store';

export const playersStateSelector = (state: RootState) => state.players;

export const selectedPlayerForModalSelector = createSelector(
  playersStateSelector,
  (state: IPlayersState) => state.selectedPlayerForModal,
);

export const isDeletePlayerModalOpenSelector = createSelector(
  playersStateSelector,
  (state: IPlayersState) => state.isDeletePlayerModalOpen,
);
