import React from 'react';
import PredictionTable from "@/components/table-items/PredictionsStatsTable/PredictionTable";
import {useTableDataPage} from "./useTableDataPage";
import {Field, Form, Formik} from "formik";
import {Button, CircularProgress, DialogActions, Grid, MenuItem, Typography} from "@mui/material";
import {styles} from "@/components/forms/ArticleForm/styles";
import {DateTimeInput, SelectInput, TextInput} from "@/components";

const PredictionsStat = () => {
  const props = useTableDataPage();
  if (!props?.experts) return <CircularProgress/>;
  return (
    <>
      <Formik
        enableReinitialize
        // validationSchema={articleFormSchema}
        initialValues={{
          totalBets: null, loseCount: null, winCount: null, spendForBets: null, profit: null, roi: null, odds: null, author: null
        }}
        /* eslint-disable-next-line @typescript-eslint/ban-ts-comment */
        // @ts-ignore
        onSubmit={props.onSubmit}
      >
        {() => (
          <Form>
            <Grid container spacing={2} sx={styles.container}>
              <Grid item xs md={1.5} >
                <Field
                  type="number"
                  name="totalBets"
                  component={TextInput}
                  placeholder="totalBets"
                />
              </Grid>
              <Grid item xs md={1.5} >
                <Field
                  type="number"
                  name="loseCount"
                  component={TextInput}
                  placeholder="loseCount"
                />
              </Grid>
              <Grid item xs md={1.5} >
                <Field
                  type="number"
                  name="winCount"
                  component={TextInput}
                  placeholder="winCount"
                />
              </Grid>
              <Grid item xs md={1.5} >
                <Field
                  type="number"
                  name="spendForBets"
                  component={TextInput}
                  placeholder="spendForBets"
                />
              </Grid>
              <Grid item xs md={1.5} >
                <Field
                  type="number"
                  name="profit"
                  component={TextInput}
                  placeholder="profit"
                />
              </Grid>
              <Grid item xs md={1.5} >
                <Field
                  type="number"
                  name="roi"
                  component={TextInput}
                  placeholder="roi"
                />
              </Grid>

              <Grid item xs md={1.5} >
                <Field
                  type="number"
                  name="odds"
                  component={TextInput}
                  placeholder="odds"
                />
              </Grid>
            </Grid>
            <Grid container spacing={4} sx={styles.container}>
              <Grid item xs md={1.5} >
                <Field name="author" placeholder="author" component={SelectInput} size="small">
                  {props?.experts.items.map((e: any) => (
                    <MenuItem value={e.id} key={e.id}>
                      <Typography>{e.firstName}</Typography>
                    </MenuItem>
                  ))}
                </Field>
              </Grid>
              <Grid item xs md={1.5} >
              <Field
                type="string"
                name="fromDate"
                placeholder="fromDate"
                component={DateTimeInput}
              />
              </Grid>
              <Grid item xs md={1.5} >
                <Field
                  type="string"
                  name="toDate"
                  placeholder="toDate"
                  component={DateTimeInput}
                />
              </Grid>
            </Grid>
            <DialogActions>
              <Button
                variant="contained"
                type="submit"
                sx={styles.btn}
              >
                Submit
              </Button>
            </DialogActions>
          </Form>
        )}
      </Formik>
      <PredictionTable {...props} />
    </>
  );
};

export default PredictionsStat;
