import React from 'react';

import { CustomTable } from "@/components";
import { DeleteLogModal } from '@/components/modals/DeleteLogModal';
import { CronTableItem } from "@/components/table-items";
import { useMainCronsPage } from "@/views/crons/MainCronsPage/useMainCronsPage";

import { headCells } from './constants';

const MainCronsPage: React.FC = () => {
    const { crons, ...tableProps } = useMainCronsPage();

    return (
      <CustomTable headCells={headCells} {...tableProps} deletingModal={DeleteLogModal}>
        {crons?.items.map((cron) => (
          <CronTableItem key={cron.id} {...cron} />
        ))}
      </CustomTable>
    );
};

export default MainCronsPage;
