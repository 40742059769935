import {useMemo} from "react";

import {useExecuteCronMutation} from "@/apis/crons";
import {FILTER_TYPES, SPORTS} from "@/components/forms/CronForm/constants";
import {ExecuteCronFormValues, ICronFilterField} from "@/types";
import {toIsoString} from "@/utils";

import useToastMessageRequest from "../../../hooks/useToastMessageRequest";


type UseCronForm = {
  id?: number,
  config?: ICronFilterField[],
  withSport?: boolean,
}

export const useCronForm = ({ id, config, withSport }: UseCronForm) => {
  const generatedInitialValues = useMemo(() => {
    const initialValues: { [key: string]: string | number;} = {};
    config?.forEach((configItem) => {
      initialValues[configItem.name] = configItem.type === FILTER_TYPES.DATE ? toIsoString(new Date()) : '';
    });
    if(withSport) { initialValues.sportId = SPORTS[0].id; }
    return initialValues;
  }, [config]);

  const [executeCron, {  isLoading, ...params }] = useExecuteCronMutation();


  useToastMessageRequest(params)

  const handleSubmit = (values: ExecuteCronFormValues) => {
    if(id) {
      executeCron({
        id,
        ...values,
      })
    }
  }
  return {
    handleSubmit,
    isLoading,
    generatedInitialValues
  }
}
